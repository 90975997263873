(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bA.ag === region.bU.ag)
	{
		return 'on line ' + region.bA.ag;
	}
	return 'on lines ' + region.bA.ag + ' through ' + region.bU.ag;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl._,
		impl.ea,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		E: func(record.E),
		bB: record.bB,
		bp: record.bp
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.E;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bB;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bp) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl._,
		impl.ea,
		function(sendToApp, initialModel) {
			var view = impl.K;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl._,
		impl.ea,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bs && impl.bs(sendToApp)
			var view = impl.K;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.db);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ej) && (_VirtualDom_doc.title = title = doc.ej);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dR;
	var onUrlRequest = impl.dS;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bs: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cw === next.cw
							&& curr.b5 === next.b5
							&& curr.cs.a === next.cs.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dC: function(flags)
		{
			return A3(impl.dC, flags, _Browser_getUrl(), key);
		},
		K: impl.K,
		_: impl._,
		ea: impl.ea
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dv: 'hidden', dd: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dv: 'mozHidden', dd: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dv: 'msHidden', dd: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dv: 'webkitHidden', dd: 'webkitvisibilitychange' }
		: { dv: 'hidden', dd: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cJ: _Browser_getScene(),
		c$: {
			aY: _Browser_window.pageXOffset,
			aZ: _Browser_window.pageYOffset,
			ab: _Browser_doc.documentElement.clientWidth,
			T: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ab: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		T: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cJ: {
				ab: node.scrollWidth,
				T: node.scrollHeight
			},
			c$: {
				aY: node.scrollLeft,
				aZ: node.scrollTop,
				ab: node.clientWidth,
				T: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cJ: _Browser_getScene(),
			c$: {
				aY: x,
				aZ: y,
				ab: _Browser_doc.documentElement.clientWidth,
				T: _Browser_doc.documentElement.clientHeight
			},
			dl: {
				aY: x + rect.left,
				aZ: y + rect.top,
				ab: rect.width,
				T: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.dN) { flags += 'm'; }
	if (options.dc) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dn.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dn.b, xhr)); });
		$elm$core$Maybe$isJust(request.er) && _Http_track(router, xhr, request.er.a);

		try {
			xhr.open(request.dL, request.et, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.et));
		}

		_Http_configureRequest(xhr, request);

		request.db.a && xhr.setRequestHeader('Content-Type', request.db.a);
		xhr.send(request.db.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.du; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.eh.a || 0;
	xhr.responseType = request.dn.d;
	xhr.withCredentials = request.c5;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		et: xhr.responseURL,
		d7: xhr.status,
		d8: xhr.statusText,
		du: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			d4: event.loaded,
			cN: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			d0: event.loaded,
			cN: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Types$UrlChanged = function (a) {
	return {$: 2, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.f),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.f);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.g) : builder.g;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.f) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.f);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{g: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, f: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {b_: fragment, b5: host, cq: path, cs: port_, cw: protocol, cx: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Page$Route$Home = {$: 0};
var $author$project$Types$NotConnected = 0;
var $author$project$Types$ReceiveNow = function (a) {
	return {$: 8, a: a};
};
var $author$project$Types$TimeZoneDetected = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Components$UI$Menu$init = {ac: false, t: false, C: true, D: true, G: false, H: false, z: false};
var $author$project$Page$Map$Types$LatLng = F2(
	function (lat, lng) {
		return {cd: lat, cf: lng};
	});
var $author$project$Components$UI$Calendar$Closed = 0;
var $author$project$Components$UI$Calendar$init = function (mSysId) {
	return {dw: _List_Nil, cg: mSysId, cQ: 0};
};
var $author$project$Page$GlobalMap$Init = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Page$GlobalMap$initTask = function (mSysId) {
	if (mSysId.$ === 1) {
		return $elm$core$Platform$Cmd$none;
	} else {
		var sysId = mSysId.a;
		return A2(
			$elm$core$Task$perform,
			function (_v1) {
				return $author$project$Page$GlobalMap$Init(sysId);
			},
			$elm$core$Process$sleep(1000));
	}
};
var $elm$core$String$toFloat = _String_toFloat;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Page$GlobalMap$mstr2float = F2(
	function (d, v) {
		return A2(
			$elm$core$Maybe$withDefault,
			d,
			$elm$core$String$toFloat(
				A2($elm$core$Maybe$withDefault, 'x', v)));
	});
var $author$project$Page$GlobalMap$init = F4(
	function (mSysId, mlat, mlng, mday) {
		return _Utils_Tuple2(
			{
				aA: $elm$core$Maybe$Nothing,
				L: $author$project$Components$UI$Calendar$init(mSysId),
				M: A2(
					$author$project$Page$Map$Types$LatLng,
					A2($author$project$Page$GlobalMap$mstr2float, 48.5013798, mlat),
					A2($author$project$Page$GlobalMap$mstr2float, 34.6234255, mlng)),
				bf: _List_Nil,
				an: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Page$GlobalMap$initTask(mSysId)
					])));
	});
var $author$project$Page$Home$init = _Utils_Tuple2(
	{bq: '', ar: false},
	$elm$core$Platform$Cmd$none);
var $author$project$Page$LinkSys$init = _Utils_Tuple2(
	{bO: '', a3: false, v: 1, ap: false},
	$elm$core$Platform$Cmd$none);
var $author$project$Page$Login$Model = F6(
	function (username, password, passwordConfirm, menuModel, showPassword, eula) {
		return {aI: eula, j: menuModel, r: password, al: passwordConfirm, x: showPassword, A: username};
	});
var $author$project$Page$Login$init = _Utils_Tuple2(
	A6($author$project$Page$Login$Model, '', '', '', $author$project$Components$UI$Menu$init, false, false),
	$elm$core$Platform$Cmd$none);
var $author$project$Page$System$Config$Types$BC_None = {$: 0};
var $author$project$Page$System$Config$Master$Types$MasterPage1 = 0;
var $author$project$Page$System$Config$Types$SS_Root = 0;
var $author$project$Page$System$Config$Master$Types$M_ECO_MID = 1;
var $author$project$Page$System$Config$Master$Types$M_TRACK_MID = 1;
var $author$project$Page$System$Config$Master$Types$initMasterData = {
	ch: 1,
	ci: _Utils_Tuple2(false, false),
	cj: 1,
	I: {c9: false, a0: false, a1: false, be: false, bi: true, aM: false}
};
var $author$project$Page$System$Config$init = function (sysId) {
	return _Utils_Tuple2(
		{bH: '', bI: '', dq: false, u: $author$project$Page$System$Config$Master$Types$initMasterData, ah: $author$project$Page$System$Config$Types$BC_None, bj: '', bk: '', bl: '', dQ: '', bq: '', aP: false, d5: false, aR: false, aS: false, X: 0, P: $elm$core$Maybe$Nothing, aq: false, ar: false, y: 0, aV: false, cO: '', ee: sysId, c_: '*111#'},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$System$Info$init = _Utils_Tuple2(
	{dq: false, dQ: '', ao: $elm$core$Maybe$Nothing, d5: false, aQ: false, bt: false, aU: false},
	$elm$core$Platform$Cmd$none);
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$API$System$getLogs = F2(
	function (sysId, offset) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string('system_logs')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(sysId)),
					_Utils_Tuple2(
					'skip',
					$elm$json$Json$Encode$int(offset)),
					_Utils_Tuple2(
					'count',
					$elm$json$Json$Encode$int(50))
				]));
	});
var $author$project$API$websocketOut = _Platform_outgoingPort('websocketOut', $elm$core$Basics$identity);
var $author$project$Page$System$Logs$getLogs = F2(
	function (sysId, offset) {
		return $author$project$API$websocketOut(
			A2($author$project$API$System$getLogs, sysId, offset));
	});
var $author$project$Page$System$Logs$init = function (sysId) {
	return _Utils_Tuple2(
		{bW: false, ec: sysId},
		function () {
			if (sysId.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				var s = sysId.a;
				return A2($author$project$Page$System$Logs$getLogs, s, 100000000000);
			}
		}());
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$AppState$initCmd = F2(
	function (timeCmd, nowCmd) {
		return _List_fromArray(
			[
				A2($elm$core$Task$perform, timeCmd, $elm$time$Time$here),
				A2($elm$core$Task$perform, nowCmd, $elm$time$Time$now)
			]);
	});
var $ChristophP$elm_i18next$I18Next$Curly = {$: 0};
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $ChristophP$elm_i18next$I18Next$t = F2(
	function (_v0, key) {
		var translations = _v0;
		return A2(
			$elm$core$Maybe$withDefault,
			key,
			A2($elm$core$Dict$get, key, translations));
	});
var $ChristophP$elm_i18next$I18Next$delimsToTuple = function (delims) {
	switch (delims.$) {
		case 0:
			return _Utils_Tuple2('{{', '}}');
		case 1:
			return _Utils_Tuple2('__', '__');
		default:
			var tuple = delims.a;
			return tuple;
	}
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $ChristophP$elm_i18next$I18Next$replacePlaceholders = F3(
	function (replacements, delims, str) {
		var _v0 = $ChristophP$elm_i18next$I18Next$delimsToTuple(delims);
		var start = _v0.a;
		var end = _v0.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, acc) {
					var key = _v1.a;
					var value = _v1.b;
					return A3(
						$elm$core$String$replace,
						_Utils_ap(
							start,
							_Utils_ap(key, end)),
						value,
						acc);
				}),
			str,
			replacements);
	});
var $ChristophP$elm_i18next$I18Next$tr = F4(
	function (_v0, delims, key, replacements) {
		var translations = _v0;
		var _v1 = A2($elm$core$Dict$get, key, translations);
		if (!_v1.$) {
			var str = _v1.a;
			return A3($ChristophP$elm_i18next$I18Next$replacePlaceholders, replacements, delims, str);
		} else {
			return key;
		}
	});
var $elm$core$String$toUpper = _String_toUpper;
var $ChristophP$elm_i18next$I18Next$Translations = $elm$core$Basics$identity;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $ChristophP$elm_i18next$I18Next$flattenTranslationsHelp = F3(
	function (initialValue, namespace, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (key, val, acc) {
					var newNamespace = function (currentKey) {
						return $elm$core$String$isEmpty(namespace) ? currentKey : (namespace + ('.' + currentKey));
					};
					if (val.$ === 1) {
						var str = val.a;
						return A3(
							$elm$core$Dict$insert,
							newNamespace(key),
							str,
							acc);
					} else {
						var children = val.a;
						return A3(
							$ChristophP$elm_i18next$I18Next$flattenTranslationsHelp,
							acc,
							newNamespace(key),
							children);
					}
				}),
			initialValue,
			dict);
	});
var $ChristophP$elm_i18next$I18Next$flattenTranslations = A2($ChristophP$elm_i18next$I18Next$flattenTranslationsHelp, $elm$core$Dict$empty, '');
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $ChristophP$elm_i18next$I18Next$fromTree = A2(
	$elm$core$Basics$composeR,
	$elm$core$Dict$fromList,
	A2($elm$core$Basics$composeR, $ChristophP$elm_i18next$I18Next$flattenTranslations, $elm$core$Basics$identity));
var $ChristophP$elm_i18next$I18Next$Branch = function (a) {
	return {$: 0, a: a};
};
var $ChristophP$elm_i18next$I18Next$object = A2($elm$core$Basics$composeR, $elm$core$Dict$fromList, $ChristophP$elm_i18next$I18Next$Branch);
var $ChristophP$elm_i18next$I18Next$Leaf = function (a) {
	return {$: 1, a: a};
};
var $ChristophP$elm_i18next$I18Next$string = $ChristophP$elm_i18next$I18Next$Leaf;
var $author$project$I18N$En$translations = $ChristophP$elm_i18next$I18Next$fromTree(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'Список Фениксов',
			$ChristophP$elm_i18next$I18Next$string('Yours Fenixes')),
			_Utils_Tuple2(
			'Да',
			$ChristophP$elm_i18next$I18Next$string('Yes')),
			_Utils_Tuple2(
			'Нет',
			$ChristophP$elm_i18next$I18Next$string('No')),
			_Utils_Tuple2(
			'Режим',
			$ChristophP$elm_i18next$I18Next$string('Mode')),
			_Utils_Tuple2(
			'Поиск',
			$ChristophP$elm_i18next$I18Next$string('Search')),
			_Utils_Tuple2(
			'Ожидание',
			$ChristophP$elm_i18next$I18Next$string('Standby')),
			_Utils_Tuple2(
			'Выключен',
			$ChristophP$elm_i18next$I18Next$string('Power Off')),
			_Utils_Tuple2(
			'themes',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'dark',
						$ChristophP$elm_i18next$I18Next$string('Dark')),
						_Utils_Tuple2(
						'light',
						$ChristophP$elm_i18next$I18Next$string('Light'))
					]))),
			_Utils_Tuple2(
			'scales',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'normal',
						$ChristophP$elm_i18next$I18Next$string('Normal')),
						_Utils_Tuple2(
						'small',
						$ChristophP$elm_i18next$I18Next$string('Small')),
						_Utils_Tuple2(
						'big',
						$ChristophP$elm_i18next$I18Next$string('Big')),
						_Utils_Tuple2(
						'bigger',
						$ChristophP$elm_i18next$I18Next$string('Bigger')),
						_Utils_Tuple2(
						'biggest',
						$ChristophP$elm_i18next$I18Next$string('Biggest'))
					]))),
			_Utils_Tuple2(
			'menu',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Системные опции',
						$ChristophP$elm_i18next$I18Next$string('System options')),
						_Utils_Tuple2(
						'Аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Account')),
						_Utils_Tuple2(
						'Язык',
						$ChristophP$elm_i18next$I18Next$string('Language')),
						_Utils_Tuple2(
						'Тема',
						$ChristophP$elm_i18next$I18Next$string('Theme')),
						_Utils_Tuple2(
						'Размер',
						$ChristophP$elm_i18next$I18Next$string('Size')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Settings')),
						_Utils_Tuple2(
						'Выйти',
						$ChristophP$elm_i18next$I18Next$string('Logout')),
						_Utils_Tuple2(
						'Выйти?',
						$ChristophP$elm_i18next$I18Next$string('Logout?')),
						_Utils_Tuple2(
						'Вы действительно хотите выйти?',
						$ChristophP$elm_i18next$I18Next$string('Do you really want to get out?')),
						_Utils_Tuple2(
						'Список Фениксов',
						$ChristophP$elm_i18next$I18Next$string('List of Fenixes')),
						_Utils_Tuple2(
						'Карта',
						$ChristophP$elm_i18next$I18Next$string('Map')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Control')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Settings')),
						_Utils_Tuple2(
						'События',
						$ChristophP$elm_i18next$I18Next$string('Events')),
						_Utils_Tuple2(
						'Иконка и название Феникса',
						$ChristophP$elm_i18next$I18Next$string('Fenix icon and name')),
						_Utils_Tuple2(
						'Основные настройки',
						$ChristophP$elm_i18next$I18Next$string('Basic settings')),
						_Utils_Tuple2(
						'Расширенные настройки',
						$ChristophP$elm_i18next$I18Next$string('Advanced settings')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Battery service')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Details of the Fenix'))
					]))),
			_Utils_Tuple2(
			'login',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добро пожаловать',
						$ChristophP$elm_i18next$I18Next$string('Welcome')),
						_Utils_Tuple2(
						'Добро пожаловать!',
						$ChristophP$elm_i18next$I18Next$string('Welcome!')),
						_Utils_Tuple2(
						'Войдите, чтобы продолжить',
						$ChristophP$elm_i18next$I18Next$string('Log in to continue')),
						_Utils_Tuple2(
						'Введите Ваш логин',
						$ChristophP$elm_i18next$I18Next$string('Enter your username')),
						_Utils_Tuple2(
						'name_not_found',
						$ChristophP$elm_i18next$I18Next$string('The username was not found. Please check and try again.')),
						_Utils_Tuple2(
						'Введите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Enter your password')),
						_Utils_Tuple2(
						'Войти в систему',
						$ChristophP$elm_i18next$I18Next$string('Log in')),
						_Utils_Tuple2(
						'У вас нет аккаунта?',
						$ChristophP$elm_i18next$I18Next$string('Don\'t have an account?')),
						_Utils_Tuple2(
						'Зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('Register')),
						_Utils_Tuple2(
						'Создать аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Create an account')),
						_Utils_Tuple2(
						'Чтобы начать работать',
						$ChristophP$elm_i18next$I18Next$string('To get started')),
						_Utils_Tuple2(
						'Повторите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Repeat your password')),
						_Utils_Tuple2(
						'Я прочитал и принимаю условия',
						$ChristophP$elm_i18next$I18Next$string('I have read and accept the terms and conditions')),
						_Utils_Tuple2(
						'пользовательского соглашения',
						$ChristophP$elm_i18next$I18Next$string('user agreement')),
						_Utils_Tuple2(
						'Уже есть аккаунт?',
						$ChristophP$elm_i18next$I18Next$string('Already have an account?')),
						_Utils_Tuple2(
						'Введите имя пользователя',
						$ChristophP$elm_i18next$I18Next$string('Enter user name')),
						_Utils_Tuple2(
						'Укажите пароль',
						$ChristophP$elm_i18next$I18Next$string('Enter your password')),
						_Utils_Tuple2(
						'Укажите пароль повторно',
						$ChristophP$elm_i18next$I18Next$string('Enter the password again')),
						_Utils_Tuple2(
						'Пароль указан неверно',
						$ChristophP$elm_i18next$I18Next$string('The password is incorrect')),
						_Utils_Tuple2(
						'Вы должны принять условия',
						$ChristophP$elm_i18next$I18Next$string('You have to accept the conditions')),
						_Utils_Tuple2(
						'Чтобы пользоваться сервисом, вы должны ',
						$ChristophP$elm_i18next$I18Next$string('To use the service, you must ')),
						_Utils_Tuple2(
						'авторизоваться',
						$ChristophP$elm_i18next$I18Next$string('to be authorized')),
						_Utils_Tuple2(
						'зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('register')),
						_Utils_Tuple2(
						'Добавьте Феникс в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('Add your Phoenix to watchlist'))
					]))),
			_Utils_Tuple2(
			'list',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добавьте ещё один Феникс',
						$ChristophP$elm_i18next$I18Next$string('Add another Fenix')),
						_Utils_Tuple2(
						'в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('to the watch list')),
						_Utils_Tuple2(
						'Добавить',
						$ChristophP$elm_i18next$I18Next$string('Add')),
						_Utils_Tuple2(
						'Режим Поиск:',
						$ChristophP$elm_i18next$I18Next$string('Search mode:')),
						_Utils_Tuple2(
						'Режим Ожидание:',
						$ChristophP$elm_i18next$I18Next$string('Standby mode:')),
						_Utils_Tuple2(
						'На карте',
						$ChristophP$elm_i18next$I18Next$string('On the map')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Managing'))
					]))),
			_Utils_Tuple2(
			'master',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Далeе',
						$ChristophP$elm_i18next$I18Next$string('Next')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Back')),
						_Utils_Tuple2(
						'Подготовка SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Preparing the SIM card')),
						_Utils_Tuple2(
						'Установите SIM-карту в мобильный телефон.',
						$ChristophP$elm_i18next$I18Next$string('Install the SIM card in your mobile phone.')),
						_Utils_Tuple2(
						'Активируйте SIM-карту в соответствии с инструкциями GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('Activate the SIM card according to your GSM operator\'s instructions.')),
						_Utils_Tuple2(
						'Убедитесь в том, что PIN-код при включении телефона выключен.',
						$ChristophP$elm_i18next$I18Next$string('Ensure that the PIN code is switched off when the phone is switched on.')),
						_Utils_Tuple2(
						'В случае необходимости зарегистрируйте SIM-карту на сайте GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('If necessary, register your SIM card on the GSM operator\'s website.')),
						_Utils_Tuple2(
						'Выключите мобильный телефон и извлеките из него подготовленную SIM-карту.',
						$ChristophP$elm_i18next$I18Next$string('Switch off your mobile phone and remove the prepared SIM card.')),
						_Utils_Tuple2(
						'Установка подготовленной SIM-карты в Феникс',
						$ChristophP$elm_i18next$I18Next$string('Installing a prepared SIM card in the Fenix')),
						_Utils_Tuple2(
						'Выкрутите 4 винта и снимите крышку корпуса.',
						$ChristophP$elm_i18next$I18Next$string('Remove the 4 screws and enclosure cover.')),
						_Utils_Tuple2(
						'Убедитесь в том, что Феникс выключен – светодиодный индикатор не горит и не мигает.',
						$ChristophP$elm_i18next$I18Next$string('Make sure that the Phoenix is switched off - the LED is off and not flashing.')),
						_Utils_Tuple2(
						'Установите подготовленную SIM-карту в Феникс.',
						$ChristophP$elm_i18next$I18Next$string('Install the prepared SIM card in the Fenix.')),
						_Utils_Tuple2(
						'В случае необходимости произведите привязку экзекуторов.',
						$ChristophP$elm_i18next$I18Next$string('Bind the Executors if necessary.')),
						_Utils_Tuple2(
						'Привязать экзекутор',
						$ChristophP$elm_i18next$I18Next$string('Bind the Executor')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('Executor available')),
						_Utils_Tuple2(
						'Привязка экзекуторов и активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Binding the Executors and activating the Fenix')),
						_Utils_Tuple2(
						'Исходное состояние: Феникс – выключен.',
						$ChristophP$elm_i18next$I18Next$string('Initial status: Fenix - off.')),
						_Utils_Tuple2(
						'Обесточьте все привязываемые экзекуторы и подготовьте их к подаче питания.',
						$ChristophP$elm_i18next$I18Next$string('De-energise all tethered excecutors and prepare them for power supply.')),
						_Utils_Tuple2(
						'Нажмите и удерживайте 3 секунды кнопку Фениска – загорится светодиод.',
						$ChristophP$elm_i18next$I18Next$string('Press and hold the Fenix button for 3 seconds - the LED lights up.')),
						_Utils_Tuple2(
						'Как только светодиод загорится – подайте питание на все привязываемые экзекуторы – светодиод отработает серию частых вспышек и начнёт отрабатывать редкие одиночные вспышки.',
						$ChristophP$elm_i18next$I18Next$string('As soon as the LED lights up - apply power to all tethered exciters - the LED will flash a series of frequent flashes and begin to flash rare single flashes.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Фениска и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Close the lid of the Fenisk housing and tighten the 4 screws.')),
						_Utils_Tuple2(
						'Активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Fenix activation')),
						_Utils_Tuple2(
						'Нажмите кнопку Феникса – светодиодный индикатор подтвердит включение.',
						$ChristophP$elm_i18next$I18Next$string('Press the Fenix button - the LED will confirm activation.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Феникса и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Close the lid of the Fenix housing and tighten the 4 screws.')),
						_Utils_Tuple2(
						'Добавление Феникса в наблюдение',
						$ChristophP$elm_i18next$I18Next$string('Adding Fenix to surveillance')),
						_Utils_Tuple2(
						'Отправьте на телефонный номер SIM-карты Феникса SMS: link',
						$ChristophP$elm_i18next$I18Next$string('Send an SMS to the Fenix SIM card phone number: link')),
						_Utils_Tuple2(
						'В ответ придёт уникальный код – введите его в поле ниже:',
						$ChristophP$elm_i18next$I18Next$string('You will receive a unique code - enter it in the box below:')),
						_Utils_Tuple2(
						'Введите уникальный код из SMS',
						$ChristophP$elm_i18next$I18Next$string('Enter a unique code from the SMS')),
						_Utils_Tuple2(
						'Подтвердить',
						$ChristophP$elm_i18next$I18Next$string('Confirm')),
						_Utils_Tuple2(
						'Мастер добавления Феникса',
						$ChristophP$elm_i18next$I18Next$string('Fenix Addition Wizard')),
						_Utils_Tuple2(
						'Свериться с',
						$ChristophP$elm_i18next$I18Next$string('Check with')),
						_Utils_Tuple2(
						'индикатором',
						$ChristophP$elm_i18next$I18Next$string('indicator'))
					]))),
			_Utils_Tuple2(
			'config',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Основные настройки феникса',
						$ChristophP$elm_i18next$I18Next$string('Basic Fenix settings')),
						_Utils_Tuple2(
						'Период выхода на связь',
						$ChristophP$elm_i18next$I18Next$string('Period of communication')),
						_Utils_Tuple2(
						'Редко',
						$ChristophP$elm_i18next$I18Next$string('Rarely')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь один раз в сутки.',
						$ChristophP$elm_i18next$I18Next$string('Fenix will be in contact once a day.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 15 лет.',
						$ChristophP$elm_i18next$I18Next$string('Expected battery life - 15 years.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Optimal')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Fenix will be in contact every six hours.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 6 лет.',
						$ChristophP$elm_i18next$I18Next$string('Expected battery life - 6 years.')),
						_Utils_Tuple2(
						'Часто',
						$ChristophP$elm_i18next$I18Next$string('Often')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 2 часа.',
						$ChristophP$elm_i18next$I18Next$string('Fenix will be in touch every 2 hours.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 2 года.',
						$ChristophP$elm_i18next$I18Next$string('Expected battery life - 2 years.')),
						_Utils_Tuple2(
						'Время работы в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('Working time in Search mode')),
						_Utils_Tuple2(
						'Продолжительно',
						$ChristophP$elm_i18next$I18Next$string('Continuously')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 12 часов.',
						$ChristophP$elm_i18next$I18Next$string('Maximum operating time in Search mode - 12 hours.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 15 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Battery capacity is sufficient for 15 Search mode activations.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Optimal')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Maximum operating time in Search mode - 6 hours.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 30 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Battery capacity is sufficient for 30 Search mode activations.')),
						_Utils_Tuple2(
						'Минимально',
						$ChristophP$elm_i18next$I18Next$string('Minimum')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 1 час.',
						$ChristophP$elm_i18next$I18Next$string('Maximum operating time in Search mode - 1 hour.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 100 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Battery capacity enough for 100 Search mode activations.')),
						_Utils_Tuple2(
						'Информирование',
						$ChristophP$elm_i18next$I18Next$string('Inform')),
						_Utils_Tuple2(
						'Когда происходят определенные события, Феникс может отправлять SMS на заданный номер',
						$ChristophP$elm_i18next$I18Next$string('When certain events occur, Fenix can send an SMS to a given number')),
						_Utils_Tuple2(
						'Укажите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Please give your phone number')),
						_Utils_Tuple2(
						'Выберите события',
						$ChristophP$elm_i18next$I18Next$string('Select events')),
						_Utils_Tuple2(
						'Критический остаток средств',
						$ChristophP$elm_i18next$I18Next$string('Critical balance of funds')),
						_Utils_Tuple2(
						'Низкий уровень заряда батареи',
						$ChristophP$elm_i18next$I18Next$string('Low battery level')),
						_Utils_Tuple2(
						'Изменение режима (Поиск <-> Ожидание)',
						$ChristophP$elm_i18next$I18Next$string('Changing mode ( Search) <-> Standby)')),
						_Utils_Tuple2(
						'Начало движения (в режиме Поиск)',
						$ChristophP$elm_i18next$I18Next$string('Start driving (in Search mode)')),
						_Utils_Tuple2(
						'Включение и выключение Феникса',
						$ChristophP$elm_i18next$I18Next$string('Switching Fenix on and off')),
						_Utils_Tuple2(
						'Вскрытие корпуса',
						$ChristophP$elm_i18next$I18Next$string('Opening the enclosure')),
						_Utils_Tuple2(
						'Контроль баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Checking the SIM card balance')),
						_Utils_Tuple2(
						'USSD-запрос баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('USSD request for SIM card balance')),
						_Utils_Tuple2(
						'Безопасность',
						$ChristophP$elm_i18next$I18Next$string('Security')),
						_Utils_Tuple2(
						'Чтобы никто посторонний не смог получить управление Вашим Фениксом, вы можете привязать управление к конкретному телефону и установить свой код доступа.',
						$ChristophP$elm_i18next$I18Next$string('To prevent anyone else from gaining control of your Fenix, you can link the control to a specific phone and protect SMS commands.')),
						_Utils_Tuple2(
						'По умолчанию управление возможно с любого телефона.',
						$ChristophP$elm_i18next$I18Next$string('By default, control is possible from any phone.')),
						_Utils_Tuple2(
						'Управление возможно только с телефона:',
						$ChristophP$elm_i18next$I18Next$string('Control is only possible from the phone:')),
						_Utils_Tuple2(
						'SMS-коды управления имеют вид:',
						$ChristophP$elm_i18next$I18Next$string('The SMS-commands are as follows:')),
						_Utils_Tuple2(
						'Привязать к телефону',
						$ChristophP$elm_i18next$I18Next$string('Tie it to the phone')),
						_Utils_Tuple2(
						'Установить пароль доступа',
						$ChristophP$elm_i18next$I18Next$string('Protect SMS-commands')),
						_Utils_Tuple2(
						'Вводите только латинские буквы и цифры',
						$ChristophP$elm_i18next$I18Next$string('Enter only Latin letters and numbers')),
						_Utils_Tuple2(
						'Далее',
						$ChristophP$elm_i18next$I18Next$string('Next')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Back')),
						_Utils_Tuple2(
						'Применить',
						$ChristophP$elm_i18next$I18Next$string('Apply')),
						_Utils_Tuple2(
						'Поздравляем!',
						$ChristophP$elm_i18next$I18Next$string('Congratulations!')),
						_Utils_Tuple2(
						'Основные настройки применены',
						$ChristophP$elm_i18next$I18Next$string('Basic settings applied')),
						_Utils_Tuple2(
						'Перейти к Фениксу',
						$ChristophP$elm_i18next$I18Next$string('Go to the Fenix')),
						_Utils_Tuple2(
						'Удалить Феникс',
						$ChristophP$elm_i18next$I18Next$string('Delete the Fenix')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('The Executor is available')),
						_Utils_Tuple2(
						'Не указан',
						$ChristophP$elm_i18next$I18Next$string('Not indicated')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM-card')),
						_Utils_Tuple2(
						'IMEI',
						$ChristophP$elm_i18next$I18Next$string('IMEI')),
						_Utils_Tuple2(
						'Версия ПО',
						$ChristophP$elm_i18next$I18Next$string('Software version')),
						_Utils_Tuple2(
						'Модель',
						$ChristophP$elm_i18next$I18Next$string('Model')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Details of the Fenix')),
						_Utils_Tuple2(
						'Удалить Феникс?',
						$ChristophP$elm_i18next$I18Next$string('Delete Fenix?')),
						_Utils_Tuple2(
						'remove_fx',
						$ChristophP$elm_i18next$I18Next$string('Are you sure you want to delete Fenix “{{title}}”? This action cannot be undone.')),
						_Utils_Tuple2(
						'Да, удалить',
						$ChristophP$elm_i18next$I18Next$string('Yes, delete')),
						_Utils_Tuple2(
						'Нет',
						$ChristophP$elm_i18next$I18Next$string('Not')),
						_Utils_Tuple2(
						'Иконка и название',
						$ChristophP$elm_i18next$I18Next$string('Icon and name')),
						_Utils_Tuple2(
						'Название Феникса',
						$ChristophP$elm_i18next$I18Next$string('The title of the Fenix')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string(' Fenix icon')),
						_Utils_Tuple2(
						'Ведите новое либо измените старое название',
						$ChristophP$elm_i18next$I18Next$string('Lead a new name or change the old one')),
						_Utils_Tuple2(
						'Введите название',
						$ChristophP$elm_i18next$I18Next$string('Enter a name')),
						_Utils_Tuple2(
						'Отмена',
						$ChristophP$elm_i18next$I18Next$string('Cancel')),
						_Utils_Tuple2(
						'Сохранить',
						$ChristophP$elm_i18next$I18Next$string('Save')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string('Fenix icon')),
						_Utils_Tuple2(
						'Выберите подходящую иконку для вашего феникса',
						$ChristophP$elm_i18next$I18Next$string('Choose a suitable icon for your fenix')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Battery service')),
						_Utils_Tuple2(
						'Предполагаемое время',
						$ChristophP$elm_i18next$I18Next$string('Expected time')),
						_Utils_Tuple2(
						'работы батареи',
						$ChristophP$elm_i18next$I18Next$string('battery life')),
						_Utils_Tuple2(
						'Статистика работы',
						$ChristophP$elm_i18next$I18Next$string('Work statistics')),
						_Utils_Tuple2(
						'Начальная емкость батареи',
						$ChristophP$elm_i18next$I18Next$string('Initial battery capacity')),
						_Utils_Tuple2(
						'Начало эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Start of usage')),
						_Utils_Tuple2(
						'Общее время эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Total operating time')),
						_Utils_Tuple2(
						'Израсходовано энергии батареи',
						$ChristophP$elm_i18next$I18Next$string('Battery power consumed')),
						_Utils_Tuple2(
						'Замена батареи',
						$ChristophP$elm_i18next$I18Next$string('Battery replacement')),
						_Utils_Tuple2(
						'Изменить начальную емкость',
						$ChristophP$elm_i18next$I18Next$string('Change the initial capacity')),
						_Utils_Tuple2(
						'bat_replace_text',
						$ChristophP$elm_i18next$I18Next$string('The battery must be replaced by an expert. Use the original SAFT LSH 14, 5800mAh battery. If you install a different type of battery, specify the initial capacity (mAh).')),
						_Utils_Tuple2(
						'Укажите начальную емкость батареи (мАч)',
						$ChristophP$elm_i18next$I18Next$string('Specify the initial battery capacity (mAh)')),
						_Utils_Tuple2(
						'bat_ch_capacity',
						$ChristophP$elm_i18next$I18Next$string('If you have a different battery type installed, specify the initial capacity (mAh).')),
						_Utils_Tuple2(
						'Предупреждение!',
						$ChristophP$elm_i18next$I18Next$string('Warning!')),
						_Utils_Tuple2(
						'warning_custom',
						$ChristophP$elm_i18next$I18Next$string('If you change the settings carelessly, the Fenix may become completely inoperable. Only change settings if you are absolutely sure about what you are doing.')),
						_Utils_Tuple2(
						'error_custom',
						$ChristophP$elm_i18next$I18Next$string('Download error or data not yet received from Fenix.')),
						_Utils_Tuple2(
						'Отменить',
						$ChristophP$elm_i18next$I18Next$string('Cancel')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Show')),
						_Utils_Tuple2(
						'изменения',
						$ChristophP$elm_i18next$I18Next$string('changes')),
						_Utils_Tuple2(
						'Хорошо',
						$ChristophP$elm_i18next$I18Next$string('Good')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - ',
						$ChristophP$elm_i18next$I18Next$string('Maximum operating time in Search mode - ')),
						_Utils_Tuple2(
						'7 суток',
						$ChristophP$elm_i18next$I18Next$string('7 days')),
						_Utils_Tuple2(
						'24 часа',
						$ChristophP$elm_i18next$I18Next$string('24 hours')),
						_Utils_Tuple2(
						'6 часов',
						$ChristophP$elm_i18next$I18Next$string('6 hours')),
						_Utils_Tuple2(
						'Рекомендуется для охраны автомобиля на длительной стоянке.',
						$ChristophP$elm_i18next$I18Next$string('Recommended for guarding a car in a long parking lot.')),
						_Utils_Tuple2(
						'Рекомендуется для наиболее вероятного обнаружения угнанного автомобиля.',
						$ChristophP$elm_i18next$I18Next$string('Recommended for the most likely detection of a stolen vehicle.')),
						_Utils_Tuple2(
						'Рекомендуется для максимальной экономии энергии батареи питания.',
						$ChristophP$elm_i18next$I18Next$string('Recommended for maximum battery saving.')),
						_Utils_Tuple2(
						'Следующие параметры будут изменены:',
						$ChristophP$elm_i18next$I18Next$string('The following parameters will be changed:')),
						_Utils_Tuple2(
						'Номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Phone number')),
						_Utils_Tuple2(
						'Введите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Set phone number')),
						_Utils_Tuple2(
						'Определено автоматически',
						$ChristophP$elm_i18next$I18Next$string('Detected automatically')),
						_Utils_Tuple2(
						'Установлен пользователем',
						$ChristophP$elm_i18next$I18Next$string('Installed by user'))
					]))),
			_Utils_Tuple2(
			'control',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Текущий режим',
						$ChristophP$elm_i18next$I18Next$string('Current mode')),
						_Utils_Tuple2(
						'Включить режим Поиск',
						$ChristophP$elm_i18next$I18Next$string('Activate Search mode')),
						_Utils_Tuple2(
						'Связь',
						$ChristophP$elm_i18next$I18Next$string('Connection')),
						_Utils_Tuple2(
						'Последний сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Last session of communication:')),
						_Utils_Tuple2(
						'Следующий сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Next session of communication:')),
						_Utils_Tuple2(
						'Положение',
						$ChristophP$elm_i18next$I18Next$string('Position')),
						_Utils_Tuple2(
						'Положение определено:',
						$ChristophP$elm_i18next$I18Next$string('The position is defined:')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Show')),
						_Utils_Tuple2(
						'Обновить',
						$ChristophP$elm_i18next$I18Next$string('Update')),
						_Utils_Tuple2(
						'Предполагаемое время работы батареи',
						$ChristophP$elm_i18next$I18Next$string('Estimated battery life')),
						_Utils_Tuple2(
						'Режим',
						$ChristophP$elm_i18next$I18Next$string('Mode')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM card')),
						_Utils_Tuple2(
						'Баланс:',
						$ChristophP$elm_i18next$I18Next$string('Balance:')),
						_Utils_Tuple2(
						'Пополнить счет',
						$ChristophP$elm_i18next$I18Next$string('Top up the account')),
						_Utils_Tuple2(
						'Заблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Lock the engine')),
						_Utils_Tuple2(
						'Данные о состоянии еще не получены',
						$ChristophP$elm_i18next$I18Next$string('Status data not yet available')),
						_Utils_Tuple2(
						'идет определение...',
						$ChristophP$elm_i18next$I18Next$string('is the definition...')),
						_Utils_Tuple2(
						'Феникс выключен.',
						$ChristophP$elm_i18next$I18Next$string('Fenix is off.')),
						_Utils_Tuple2(
						'Идет определение местоположения...',
						$ChristophP$elm_i18next$I18Next$string('The location is in progress...')),
						_Utils_Tuple2(
						'Поиск',
						$ChristophP$elm_i18next$I18Next$string('SEARCH')),
						_Utils_Tuple2(
						'Ожидание',
						$ChristophP$elm_i18next$I18Next$string('WAITING')),
						_Utils_Tuple2(
						'Номер телефона был скопирован',
						$ChristophP$elm_i18next$I18Next$string('The phone number has been copied')),
						_Utils_Tuple2(
						'Переход в режим',
						$ChristophP$elm_i18next$I18Next$string('Switching to mode')),
						_Utils_Tuple2(
						'Продлить режим',
						$ChristophP$elm_i18next$I18Next$string('Extend the mode')),
						_Utils_Tuple2(
						'Продлить работу в режиме',
						$ChristophP$elm_i18next$I18Next$string('Extend operation in mode')),
						_Utils_Tuple2(
						'Укажите на какое время вы хотите продлить работу',
						$ChristophP$elm_i18next$I18Next$string('Indicate for how long you wish to extend the work')),
						_Utils_Tuple2(
						'в режиме',
						$ChristophP$elm_i18next$I18Next$string('on')),
						_Utils_Tuple2(
						'На 4 часа',
						$ChristophP$elm_i18next$I18Next$string('For four hours')),
						_Utils_Tuple2(
						'На сутки',
						$ChristophP$elm_i18next$I18Next$string('For 24 hours')),
						_Utils_Tuple2(
						'Навсегда',
						$ChristophP$elm_i18next$I18Next$string('Forever')),
						_Utils_Tuple2(
						'На ч',
						$ChristophP$elm_i18next$I18Next$string('На {{h}} ч')),
						_Utils_Tuple2(
						'Включить режим',
						$ChristophP$elm_i18next$I18Next$string('Activate mode')),
						_Utils_Tuple2(
						'Смена Режима',
						$ChristophP$elm_i18next$I18Next$string('Mode change')),
						_Utils_Tuple2(
						'change_state_dialog',
						$ChristophP$elm_i18next$I18Next$string('{{date}}, at the next communication session, Phoenix will be transferred to the mode {{state}}')),
						_Utils_Tuple2(
						'Информация будет доступна после выхода Феникса на связь.',
						$ChristophP$elm_i18next$I18Next$string('Information will be available once Fenix has been contacted.')),
						_Utils_Tuple2(
						'неизвестно',
						$ChristophP$elm_i18next$I18Next$string('unknown')),
						_Utils_Tuple2(
						'Положение неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Position unknown')),
						_Utils_Tuple2(
						'Разблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Unlock the engine')),
						_Utils_Tuple2(
						'wait_state',
						$ChristophP$elm_i18next$I18Next$string('{{datetime}}, at the next communication session,')),
						_Utils_Tuple2(
						'будет определено текущее местоположение',
						$ChristophP$elm_i18next$I18Next$string('the current location will be determined')),
						_Utils_Tuple2(
						'будет продлена работа Феникса в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('the Fenix will be extended in Search mode')),
						_Utils_Tuple2(
						'будет запущена отложенная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('the delayed engine blocking will be started')),
						_Utils_Tuple2(
						'будет запущена интеллектуальная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('the intelligent engine blocking will be triggered')),
						_Utils_Tuple2(
						'двигатель будет разблокирован',
						$ChristophP$elm_i18next$I18Next$string('the engine will be unlocked')),
						_Utils_Tuple2(
						'Феникс будет выключен',
						$ChristophP$elm_i18next$I18Next$string('Fenix will be deactivated')),
						_Utils_Tuple2(
						'блокировка будет сброшена',
						$ChristophP$elm_i18next$I18Next$string('the lock will be reset')),
						_Utils_Tuple2(
						'Феникс будет переведён в режим',
						$ChristophP$elm_i18next$I18Next$string('The Fenix will be put into')),
						_Utils_Tuple2(
						'Внимание',
						$ChristophP$elm_i18next$I18Next$string('Attention')),
						_Utils_Tuple2(
						'Блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('Engine lock')),
						_Utils_Tuple2(
						'Интеллектуальная блокировка',
						$ChristophP$elm_i18next$I18Next$string('Intelligent locking')),
						_Utils_Tuple2(
						'block_smart_text',
						$ChristophP$elm_i18next$I18Next$string('the smart engine lock will be triggered. The Fenix will allow the vehicle to leave the parking area unhindered, determine its coordinates and lock the engine the first time the vehicle comes to a stop.')),
						_Utils_Tuple2(
						'block_smart_comment',
						$ChristophP$elm_i18next$I18Next$string('Recommended in cases where the vehicle may be in an underground garage or in a special «parking lot» where detection may not be possible. If the engine is blocked, the vehicle will not be able to leave the parking area on its own, which will alert the hijackers and lead to the removal of the \'fault\' and detection of the Fenix.')),
						_Utils_Tuple2(
						'block_lazy_text',
						$ChristophP$elm_i18next$I18Next$string('a delayed engine lockout will be initiated. If the vehicle is in motion - Phoenix will lock the engine when stopped, if the vehicle is stationary - Phoenix will lock the engine immediately.')),
						_Utils_Tuple2(
						'block_lazy_comment',
						$ChristophP$elm_i18next$I18Next$string('Recommended in cases where the vehicle has definitely not made it to the «deadhead» or where the vehicle is in direct line of sight.')),
						_Utils_Tuple2(
						'Заблокировать',
						$ChristophP$elm_i18next$I18Next$string('Block')),
						_Utils_Tuple2(
						'Вы сможете нажать эту кнопку после того как Феникc исполнит команды которые ждут выполнения',
						$ChristophP$elm_i18next$I18Next$string('You will be able to press this button after the Fenix has executed the commands that are waiting to be executed')),
						_Utils_Tuple2(
						'Для включения - откройте крышку Феникса и нажмите кнопку.',
						$ChristophP$elm_i18next$I18Next$string('+++Для включения - откройте крышку Феникса и нажмите кнопку.')),
						_Utils_Tuple2(
						'Это может занять до 15 минут.',
						$ChristophP$elm_i18next$I18Next$string('+++Это может занять до 15 минут.'))
					]))),
			_Utils_Tuple2(
			'params',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'accel.count',
						$ChristophP$elm_i18next$I18Next$string('Accelerometer pulse duration, ms')),
						_Utils_Tuple2(
						'accel.deb',
						$ChristophP$elm_i18next$I18Next$string('Software debouncing, number of repetitions (2-6)')),
						_Utils_Tuple2(
						'accel.lvl',
						$ChristophP$elm_i18next$I18Next$string('Accelerometer sensitivity, mg')),
						_Utils_Tuple2(
						'accel.time',
						$ChristophP$elm_i18next$I18Next$string('Accelerometer settling time, seconds (10-300)')),
						_Utils_Tuple2(
						'adc.fix.du',
						$ChristophP$elm_i18next$I18Next$string('Backup voltage change threshold, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.dub',
						$ChristophP$elm_i18next$I18Next$string('Main supply voltage change threshold, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.umax',
						$ChristophP$elm_i18next$I18Next$string('Maximum permissible supply threshold, /29.528, V (350-1200)')),
						_Utils_Tuple2(
						'adc.in.1',
						$ChristophP$elm_i18next$I18Next$string('Logic input voltage  input 1, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.in.2',
						$ChristophP$elm_i18next$I18Next$string('Logic threshold voltage input 2, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.photo',
						$ChristophP$elm_i18next$I18Next$string('Photo sensor sensitivity, (100-2000; 2000-max)')),
						_Utils_Tuple2(
						'adc.photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Photo guard activation time, sec (1-32000)')),
						_Utils_Tuple2(
						'photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Photo guard activation time, sec (1-32000)')),
						_Utils_Tuple2(
						'adc.u.off',
						$ChristophP$elm_i18next$I18Next$string('Main power-off threshold, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'adc.u.on',
						$ChristophP$elm_i18next$I18Next$string('Main power on threshold, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'akkum.block.vbat',
						$ChristophP$elm_i18next$I18Next$string('-- add description ---')),
						_Utils_Tuple2(
						'akkum.block.vdd',
						$ChristophP$elm_i18next$I18Next$string('-- add description ---')),
						_Utils_Tuple2(
						'akkum.charge.0',
						$ChristophP$elm_i18next$I18Next$string('Full battery discharge indication, /310.29, V (1050-1150)')),
						_Utils_Tuple2(
						'akkum.charge.30',
						$ChristophP$elm_i18next$I18Next$string('30% battery indication, /310.29, V (1100-1200)')),
						_Utils_Tuple2(
						'akkum.charge.60',
						$ChristophP$elm_i18next$I18Next$string('60% battery indication, /310.29, V (1150-1250)')),
						_Utils_Tuple2(
						'akkum.charge.temp',
						$ChristophP$elm_i18next$I18Next$string('Maximum battery temperature, /?, degrees (?-?)')),
						_Utils_Tuple2(
						'akkum.i.0',
						$ChristophP$elm_i18next$I18Next$string('?Initial battery charging current, х 20.408 mA (1-10)')),
						_Utils_Tuple2(
						'akkum.i.1',
						$ChristophP$elm_i18next$I18Next$string('?Maximum battery charging current, х 20.408 mA (10-100)')),
						_Utils_Tuple2(
						'akkum.i.charge',
						$ChristophP$elm_i18next$I18Next$string('-- add description ---')),
						_Utils_Tuple2(
						'akkum.u.0',
						$ChristophP$elm_i18next$I18Next$string('?Minimum voltage for firmware update, /310.29, V (1100-1300)')),
						_Utils_Tuple2(
						'sleep',
						$ChristophP$elm_i18next$I18Next$string('Sleep time in Standby and Search modes, min')),
						_Utils_Tuple2(
						'adc.vi.fix',
						$ChristophP$elm_i18next$I18Next$string('Correction of standby power supply readout.')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('The receptionist\'s phone number.')),
						_Utils_Tuple2(
						'alarm.balance',
						$ChristophP$elm_i18next$I18Next$string('SMS about the critical balance')),
						_Utils_Tuple2(
						'alarm.case',
						$ChristophP$elm_i18next$I18Next$string('SMS about opening the enclosure')),
						_Utils_Tuple2(
						'alarm.delay',
						$ChristophP$elm_i18next$I18Next$string('SMS to activate standby mode')),
						_Utils_Tuple2(
						'alarm.low',
						$ChristophP$elm_i18next$I18Next$string('SMS about low battery capacity')),
						_Utils_Tuple2(
						'alarm.error',
						$ChristophP$elm_i18next$I18Next$string('SMS about not being able to locate')),
						_Utils_Tuple2(
						'alarm.gps',
						$ChristophP$elm_i18next$I18Next$string('SMS about coordinate changes')),
						_Utils_Tuple2(
						'alarm.mode',
						$ChristophP$elm_i18next$I18Next$string('SMS about mode change')),
						_Utils_Tuple2(
						'alarm.on',
						$ChristophP$elm_i18next$I18Next$string('SMS about turning on the Fenix')),
						_Utils_Tuple2(
						'alarm.off',
						$ChristophP$elm_i18next$I18Next$string('SMS about shutting down Fenix')),
						_Utils_Tuple2(
						'alarm.stealth',
						$ChristophP$elm_i18next$I18Next$string('SMS about Stealth mode activation')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('The receptionist\'s telephone number')),
						_Utils_Tuple2(
						'alarm1',
						$ChristophP$elm_i18next$I18Next$string('First phone number to send an SMS')),
						_Utils_Tuple2(
						'alarm2',
						$ChristophP$elm_i18next$I18Next$string('Second phone number to send an SMS')),
						_Utils_Tuple2(
						'alarm3',
						$ChristophP$elm_i18next$I18Next$string('Third phone number to send an SMS')),
						_Utils_Tuple2(
						'alarm4',
						$ChristophP$elm_i18next$I18Next$string('Fourth phone number to send an SMS')),
						_Utils_Tuple2(
						'auto.sleep',
						$ChristophP$elm_i18next$I18Next$string('Automatic standby, min.')),
						_Utils_Tuple2(
						'balance.skip',
						$ChristophP$elm_i18next$I18Next$string('Missing figures in the operator\'s balance report')),
						_Utils_Tuple2(
						'balance.ussd',
						$ChristophP$elm_i18next$I18Next$string('USSD card balance enquiry')),
						_Utils_Tuple2(
						'config.send',
						$ChristophP$elm_i18next$I18Next$string('Period of communication sessions in Configurator mode, min')),
						_Utils_Tuple2(
						'delay',
						$ChristophP$elm_i18next$I18Next$string('GSM module shutdown delay, in communication cycles')),
						_Utils_Tuple2(
						'distance',
						$ChristophP$elm_i18next$I18Next$string('Maximum permissible coordinate deviation, m')),
						_Utils_Tuple2(
						'gps.angle',
						$ChristophP$elm_i18next$I18Next$string('Minimum detectable angle of rotation, degrees')),
						_Utils_Tuple2(
						'gps.aoff',
						$ChristophP$elm_i18next$I18Next$string('Auto GPS off to save battery, min')),
						_Utils_Tuple2(
						'gps.delta',
						$ChristophP$elm_i18next$I18Next$string('Forced registration of coordinates when moving, m')),
						_Utils_Tuple2(
						'gps.fail',
						$ChristophP$elm_i18next$I18Next$string('GPS restart delay in case of satellite loss, min')),
						_Utils_Tuple2(
						'gps.flush.move',
						$ChristophP$elm_i18next$I18Next$string('Period of sending data to the server when travelling, sec')),
						_Utils_Tuple2(
						'gps.flush.stop',
						$ChristophP$elm_i18next$I18Next$string('Period of sending data to the server when parked, sec')),
						_Utils_Tuple2(
						'gps.flush',
						$ChristophP$elm_i18next$I18Next$string('Data sending period to server, sec')),
						_Utils_Tuple2(
						'gps.tf.move',
						$ChristophP$elm_i18next$I18Next$string('Forced registration of coordinates when moving, sec')),
						_Utils_Tuple2(
						'gps.tf.stop',
						$ChristophP$elm_i18next$I18Next$string('Period of coordinate recording at standstill, sec')),
						_Utils_Tuple2(
						'gps.tf',
						$ChristophP$elm_i18next$I18Next$string('Coordinate recording period, sec')),
						_Utils_Tuple2(
						'gps.tf.stop.acc',
						$ChristophP$elm_i18next$I18Next$string('Position recording period when parked, sec')),
						_Utils_Tuple2(
						'gps.valid.delay',
						$ChristophP$elm_i18next$I18Next$string('Data from GPS is not taken first after fix, but a specified number is skipped')),
						_Utils_Tuple2(
						'gps.vignacc',
						$ChristophP$elm_i18next$I18Next$string('Speed of forced motion detection (accelerometer is ignored) ? 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.vstart',
						$ChristophP$elm_i18next$I18Next$string('Start recording speed ? 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.vstop',
						$ChristophP$elm_i18next$I18Next$string('Speed of registering an object stop ? 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.data.1',
						$ChristophP$elm_i18next$I18Next$string('Assignment of an additional GPS data field')),
						_Utils_Tuple2(
						'gps.wait',
						$ChristophP$elm_i18next$I18Next$string('Satellite detection waiting time, min')),
						_Utils_Tuple2(
						'gsm.apn',
						$ChristophP$elm_i18next$I18Next$string('Programmable Internet Access Point - APN')),
						_Utils_Tuple2(
						'gsm.balance.period',
						$ChristophP$elm_i18next$I18Next$string('Balance control period, h')),
						_Utils_Tuple2(
						'gsm.flags',
						$ChristophP$elm_i18next$I18Next$string('Working in roaming, including national roaming')),
						_Utils_Tuple2(
						'gsm.lagtime',
						$ChristophP$elm_i18next$I18Next$string('GSM module check period for freezing, min')),
						_Utils_Tuple2(
						'gsm.predelay',
						$ChristophP$elm_i18next$I18Next$string('Time to check GSM repeaters and SMS waiting time, min')),
						_Utils_Tuple2(
						'gsm.user',
						$ChristophP$elm_i18next$I18Next$string('User name for GPRS access')),
						_Utils_Tuple2(
						'gsm.pwd',
						$ChristophP$elm_i18next$I18Next$string('Password for GPRS access')),
						_Utils_Tuple2(
						'limit',
						$ChristophP$elm_i18next$I18Next$string('Minimum allowable SIM card balance')),
						_Utils_Tuple2(
						'link.delay',
						$ChristophP$elm_i18next$I18Next$string('Button hold time for tethering, sec')),
						_Utils_Tuple2(
						'mode',
						$ChristophP$elm_i18next$I18Next$string('Mode of operation')),
						_Utils_Tuple2(
						'photo',
						$ChristophP$elm_i18next$I18Next$string('Photo sensor')),
						_Utils_Tuple2(
						'secur.code',
						$ChristophP$elm_i18next$I18Next$string('Security code for SMS commands')),
						_Utils_Tuple2(
						'sms.confirm',
						$ChristophP$elm_i18next$I18Next$string('Notification of a mode change command.')),
						_Utils_Tuple2(
						'timezone',
						$ChristophP$elm_i18next$I18Next$string('Difference between the current time zone and the world time zone, h')),
						_Utils_Tuple2(
						'vin.low',
						$ChristophP$elm_i18next$I18Next$string('Voltage for low battery message, V')),
						_Utils_Tuple2(
						'vin.off',
						$ChristophP$elm_i18next$I18Next$string('Fenix automatic shutdown voltage, V'))
					]))),
			_Utils_Tuple2(
			'map',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Скрыть трек',
						$ChristophP$elm_i18next$I18Next$string('Сховати трек')),
						_Utils_Tuple2(
						'Закрыть',
						$ChristophP$elm_i18next$I18Next$string('Закрити')),
						_Utils_Tuple2(
						'Последнее положение определено ',
						$ChristophP$elm_i18next$I18Next$string('Останнє становище визначено '))
					]))),
			_Utils_Tuple2(
			'logs',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Не загружено. Нажимте кнопку Обновить.',
						$ChristophP$elm_i18next$I18Next$string('Не завантажене. Натисніть кнопку Оновити.')),
						_Utils_Tuple2(
						'Феникс выключен',
						$ChristophP$elm_i18next$I18Next$string('Фенікс вимкнено')),
						_Utils_Tuple2(
						'Корпус Феникса взят под охрану',
						$ChristophP$elm_i18next$I18Next$string('Корпус Фенiкса взято під охорону')),
						_Utils_Tuple2(
						'Феникс FX-400 включён. Режим: Поиск',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX-400 увімкнено. Режим: Пошук')),
						_Utils_Tuple2(
						'Феникс FX400 включён. Режим: ПОИСК',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX400 увімкнено. Режим: ПОШУК')),
						_Utils_Tuple2(
						'Феникс FX400 включён. Режим: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX400 увімкнено. Режим: ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Режим изменен на: Ожидание',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: Очікування')),
						_Utils_Tuple2(
						'Автоматический переход в режим SLEEP',
						$ChristophP$elm_i18next$I18Next$string('Автоматичний перехід у режим ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Нет сигнала GPS. Невозможно определить местоположение',
						$ChristophP$elm_i18next$I18Next$string('Немає сигналу GPS. Неможливо визначити місцезнаходження')),
						_Utils_Tuple2(
						'Трекинг остановлен',
						$ChristophP$elm_i18next$I18Next$string('Трекінг зупинено')),
						_Utils_Tuple2(
						'Режим изменен на: Поиск',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: ПОШУК')),
						_Utils_Tuple2(
						'Получена команда на смену режима на Поиск через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Отримано команду на зміну режиму на ПОШУК через WEB-додаток')),
						_Utils_Tuple2(
						'Получена команда на смену режима на Ожидание через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Отримано команду на зміну режиму на ОЧІКУВАННЯ через WEB-додаток')),
						_Utils_Tuple2(
						'Двигатель разблокирован',
						$ChristophP$elm_i18next$I18Next$string('Двигун розблоковано')),
						_Utils_Tuple2(
						'Автоматическая смена режима на Поиск',
						$ChristophP$elm_i18next$I18Next$string('Автоматична зміна режиму на ПОШУК')),
						_Utils_Tuple2(
						'Запущена отложенная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('Запущено відкладене блокування двигуна')),
						_Utils_Tuple2(
						'Двигатель заблокирован',
						$ChristophP$elm_i18next$I18Next$string('Двигун заблокований')),
						_Utils_Tuple2(
						'Трекинг активирован',
						$ChristophP$elm_i18next$I18Next$string('Трекінг активовано')),
						_Utils_Tuple2(
						'Внимание! Открыт корпус Феникса',
						$ChristophP$elm_i18next$I18Next$string('Увага! Відкритий корпус Фенікса')),
						_Utils_Tuple2(
						'Работа режима ПОИСК продлена навсегда',
						$ChristophP$elm_i18next$I18Next$string('Робота режиму ПОШУК продовжено назавжди')),
						_Utils_Tuple2(
						'Автоматический переход в режим: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('Автоматичний перехід у режим: ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Нет сигнала GPS',
						$ChristophP$elm_i18next$I18Next$string('Немає сигналу GPS')),
						_Utils_Tuple2(
						'Режим изменен на: ПОИСК',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: ПОШУК')),
						_Utils_Tuple2(
						'Cигнал GPS восстановлен.',
						$ChristophP$elm_i18next$I18Next$string('Сигнал GPS відновлено.')),
						_Utils_Tuple2(
						'Режим изменен на: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('')),
						_Utils_Tuple2(
						'Получена команда на смену режима на ОЖИДАНИЕ через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Received command to change the mode to STANDBY via the WEB application')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'dates',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						' год',
						$ChristophP$elm_i18next$I18Next$string(' year')),
						_Utils_Tuple2(
						' года',
						$ChristophP$elm_i18next$I18Next$string(' years')),
						_Utils_Tuple2(
						' лет',
						$ChristophP$elm_i18next$I18Next$string(' year')),
						_Utils_Tuple2(
						' месяц',
						$ChristophP$elm_i18next$I18Next$string(' month')),
						_Utils_Tuple2(
						' месяца',
						$ChristophP$elm_i18next$I18Next$string(' month')),
						_Utils_Tuple2(
						' месяцев',
						$ChristophP$elm_i18next$I18Next$string(' month')),
						_Utils_Tuple2(
						' день',
						$ChristophP$elm_i18next$I18Next$string(' day')),
						_Utils_Tuple2(
						' дня',
						$ChristophP$elm_i18next$I18Next$string(' days')),
						_Utils_Tuple2(
						' дней',
						$ChristophP$elm_i18next$I18Next$string(' days')),
						_Utils_Tuple2(
						' час',
						$ChristophP$elm_i18next$I18Next$string(' hour')),
						_Utils_Tuple2(
						' часа',
						$ChristophP$elm_i18next$I18Next$string(' hours')),
						_Utils_Tuple2(
						' часов',
						$ChristophP$elm_i18next$I18Next$string(' hours'))
					]))),
			_Utils_Tuple2(
			'status',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Авто в движении',
						$ChristophP$elm_i18next$I18Next$string('Car on moving')),
						_Utils_Tuple2(
						'Авто в спящем состоянии',
						$ChristophP$elm_i18next$I18Next$string('Car on sleeping')),
						_Utils_Tuple2(
						'Состояние еще неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Unknown state yet')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'errors',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Код неверный, уже использован или вышло время действия кода.',
						$ChristophP$elm_i18next$I18Next$string('The code is invalid, has already been used, or the code has expired.')),
						_Utils_Tuple2(
						'Неверное имя пользователя или пароль.',
						$ChristophP$elm_i18next$I18Next$string('The username or password you entered is incorrect.')),
						_Utils_Tuple2(
						'Ошибка! Система не существует или у вас недостаточно прав для просмотра.',
						$ChristophP$elm_i18next$I18Next$string('Error! The system does not exist or you do not have sufficient rights to view.'))
					])))
		]));
var $author$project$I18N$Ru$translations = $ChristophP$elm_i18next$I18Next$fromTree(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'Список Фениксов',
			$ChristophP$elm_i18next$I18Next$string('Список фениксов')),
			_Utils_Tuple2(
			'Да',
			$ChristophP$elm_i18next$I18Next$string('Да')),
			_Utils_Tuple2(
			'Нет',
			$ChristophP$elm_i18next$I18Next$string('Нет')),
			_Utils_Tuple2(
			'Режим',
			$ChristophP$elm_i18next$I18Next$string('Режим')),
			_Utils_Tuple2(
			'Поиск',
			$ChristophP$elm_i18next$I18Next$string('Поиск')),
			_Utils_Tuple2(
			'Ожидание',
			$ChristophP$elm_i18next$I18Next$string('Ожидание')),
			_Utils_Tuple2(
			'themes',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'dark',
						$ChristophP$elm_i18next$I18Next$string('Темная')),
						_Utils_Tuple2(
						'light',
						$ChristophP$elm_i18next$I18Next$string('Светлая'))
					]))),
			_Utils_Tuple2(
			'scales',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'normal',
						$ChristophP$elm_i18next$I18Next$string('Норма')),
						_Utils_Tuple2(
						'small',
						$ChristophP$elm_i18next$I18Next$string('Меньше')),
						_Utils_Tuple2(
						'big',
						$ChristophP$elm_i18next$I18Next$string('Больше')),
						_Utils_Tuple2(
						'bigger',
						$ChristophP$elm_i18next$I18Next$string('БОЛЬШЕ')),
						_Utils_Tuple2(
						'biggest',
						$ChristophP$elm_i18next$I18Next$string('ЕЩЕ БОЛЬШЕ!!!'))
					]))),
			_Utils_Tuple2(
			'menu',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Системные опции',
						$ChristophP$elm_i18next$I18Next$string('Системные опции')),
						_Utils_Tuple2(
						'Аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Аккаунт')),
						_Utils_Tuple2(
						'Язык',
						$ChristophP$elm_i18next$I18Next$string('Язык')),
						_Utils_Tuple2(
						'Тема',
						$ChristophP$elm_i18next$I18Next$string('Тема')),
						_Utils_Tuple2(
						'Размер',
						$ChristophP$elm_i18next$I18Next$string('Размер')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Настройки')),
						_Utils_Tuple2(
						'Выйти',
						$ChristophP$elm_i18next$I18Next$string('Выйти')),
						_Utils_Tuple2(
						'Выйти?',
						$ChristophP$elm_i18next$I18Next$string('Выйти?')),
						_Utils_Tuple2(
						'Вы действительно хотите выйти?',
						$ChristophP$elm_i18next$I18Next$string('Вы действительно хотите выйти?')),
						_Utils_Tuple2(
						'Список Фениксов',
						$ChristophP$elm_i18next$I18Next$string('Список Фениксов')),
						_Utils_Tuple2(
						'Карта',
						$ChristophP$elm_i18next$I18Next$string('Карта')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Управление')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Настройки')),
						_Utils_Tuple2(
						'События',
						$ChristophP$elm_i18next$I18Next$string('События')),
						_Utils_Tuple2(
						'Иконка и название Феникса',
						$ChristophP$elm_i18next$I18Next$string('Иконка и название “Феникса”')),
						_Utils_Tuple2(
						'Основные настройки',
						$ChristophP$elm_i18next$I18Next$string('Основные настройки')),
						_Utils_Tuple2(
						'Расширенные настройки',
						$ChristophP$elm_i18next$I18Next$string('Расширенные настройки')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Обслуживание батареи')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Детали о Фениксе'))
					]))),
			_Utils_Tuple2(
			'login',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добро пожаловать',
						$ChristophP$elm_i18next$I18Next$string('Добро пожаловать')),
						_Utils_Tuple2(
						'Добро пожаловать!',
						$ChristophP$elm_i18next$I18Next$string('Добро пожаловать!')),
						_Utils_Tuple2(
						'Войдите, чтобы продолжить',
						$ChristophP$elm_i18next$I18Next$string('Войдите, чтобы продолжить')),
						_Utils_Tuple2(
						'Введите Ваш логин',
						$ChristophP$elm_i18next$I18Next$string('Введите Ваш логин')),
						_Utils_Tuple2(
						'name_not_found',
						$ChristophP$elm_i18next$I18Next$string('Имя пользователя не найдено. Пожалуйста проверьте и попробуйте снова.')),
						_Utils_Tuple2(
						'Введите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Введите Ваш пароль')),
						_Utils_Tuple2(
						'Войти в систему',
						$ChristophP$elm_i18next$I18Next$string('Войти в систему')),
						_Utils_Tuple2(
						'У вас нет аккаунта?',
						$ChristophP$elm_i18next$I18Next$string('У вас нет аккаунта?')),
						_Utils_Tuple2(
						'Зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('Зарегистрироваться')),
						_Utils_Tuple2(
						'Создать аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Создать аккаунт')),
						_Utils_Tuple2(
						'Чтобы начать работать',
						$ChristophP$elm_i18next$I18Next$string('Чтобы начать работать')),
						_Utils_Tuple2(
						'Повторите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Повторите Ваш пароль')),
						_Utils_Tuple2(
						'Я прочитал и принимаю условия',
						$ChristophP$elm_i18next$I18Next$string('Я прочитал и принимаю условия')),
						_Utils_Tuple2(
						'пользовательского соглашения',
						$ChristophP$elm_i18next$I18Next$string('пользовательского соглашения')),
						_Utils_Tuple2(
						'Уже есть аккаунт?',
						$ChristophP$elm_i18next$I18Next$string('Уже есть аккаунт?')),
						_Utils_Tuple2(
						'Введите имя пользователя',
						$ChristophP$elm_i18next$I18Next$string('Введите имя пользователя')),
						_Utils_Tuple2(
						'Укажите пароль',
						$ChristophP$elm_i18next$I18Next$string('Укажите пароль')),
						_Utils_Tuple2(
						'Укажите пароль повторно',
						$ChristophP$elm_i18next$I18Next$string('Укажите пароль повторно')),
						_Utils_Tuple2(
						'Пароль указан неверно',
						$ChristophP$elm_i18next$I18Next$string('Пароль указан неверно')),
						_Utils_Tuple2(
						'Вы должны принять условия',
						$ChristophP$elm_i18next$I18Next$string('Вы должны принять условия')),
						_Utils_Tuple2(
						'Чтобы пользоваться сервисом, вы должны ',
						$ChristophP$elm_i18next$I18Next$string('Чтобы пользоваться сервисом, вы должны ')),
						_Utils_Tuple2(
						'авторизоваться',
						$ChristophP$elm_i18next$I18Next$string('авторизоваться')),
						_Utils_Tuple2(
						'зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('зарегистрироваться')),
						_Utils_Tuple2(
						'Добавьте Феникс в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('Добавьте Феникс в список наблюдения'))
					]))),
			_Utils_Tuple2(
			'list',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добавьте ещё один Феникс',
						$ChristophP$elm_i18next$I18Next$string('Добавьте ещё один Феникс')),
						_Utils_Tuple2(
						'в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('в список наблюдения')),
						_Utils_Tuple2(
						'Добавить',
						$ChristophP$elm_i18next$I18Next$string('Добавить')),
						_Utils_Tuple2(
						'Режим Поиск:',
						$ChristophP$elm_i18next$I18Next$string('Режим ПОИСК:')),
						_Utils_Tuple2(
						'Режим Ожидание:',
						$ChristophP$elm_i18next$I18Next$string('Режим ОЖИДАНИЕ:')),
						_Utils_Tuple2(
						'На карте',
						$ChristophP$elm_i18next$I18Next$string('На карте')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Управление'))
					]))),
			_Utils_Tuple2(
			'master',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Далeе',
						$ChristophP$elm_i18next$I18Next$string('Далeе')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Назад')),
						_Utils_Tuple2(
						'Подготовка SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Подготовка SIM-карты')),
						_Utils_Tuple2(
						'Установите SIM-карту в мобильный телефон.',
						$ChristophP$elm_i18next$I18Next$string('Установите SIM-карту в мобильный телефон.')),
						_Utils_Tuple2(
						'Активируйте SIM-карту в соответствии с инструкциями GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('Активируйте SIM-карту в соответствии с инструкциями GSM-оператора.')),
						_Utils_Tuple2(
						'Убедитесь в том, что PIN-код при включении телефона выключен.',
						$ChristophP$elm_i18next$I18Next$string('Убедитесь в том, что PIN-код при включении телефона выключен.')),
						_Utils_Tuple2(
						'В случае необходимости зарегистрируйте SIM-карту на сайте GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('В случае необходимости зарегистрируйте SIM-карту на сайте GSM-оператора.')),
						_Utils_Tuple2(
						'Выключите мобильный телефон и извлеките из него подготовленную SIM-карту.',
						$ChristophP$elm_i18next$I18Next$string('Выключите мобильный телефон и извлеките из него подготовленную SIM-карту.')),
						_Utils_Tuple2(
						'Установка подготовленной SIM-карты в Феникс',
						$ChristophP$elm_i18next$I18Next$string('Установка подготовленной SIM-карты в Феникс')),
						_Utils_Tuple2(
						'Выкрутите 4 винта и снимите крышку корпуса.',
						$ChristophP$elm_i18next$I18Next$string('Выкрутите 4 винта и снимите крышку корпуса.')),
						_Utils_Tuple2(
						'Убедитесь в том, что Феникс выключен – светодиодный индикатор не горит и не мигает.',
						$ChristophP$elm_i18next$I18Next$string('Убедитесь в том, что Феникс выключен – светодиодный индикатор не горит и не мигает.')),
						_Utils_Tuple2(
						'Установите подготовленную SIM-карту в Феникс.',
						$ChristophP$elm_i18next$I18Next$string('Установите подготовленную SIM-карту в Феникс.')),
						_Utils_Tuple2(
						'В случае необходимости произведите привязку экзекуторов.',
						$ChristophP$elm_i18next$I18Next$string('В случае необходимости произведите привязку экзекуторов.')),
						_Utils_Tuple2(
						'Привязать экзекутор',
						$ChristophP$elm_i18next$I18Next$string('Привязать экзекутор')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('Экзекутор в наличии')),
						_Utils_Tuple2(
						'Привязка экзекуторов и активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Привязка экзекуторов и активация Феникса')),
						_Utils_Tuple2(
						'Исходное состояние: Феникс – выключен.',
						$ChristophP$elm_i18next$I18Next$string('Исходное состояние: Феникс – выключен.')),
						_Utils_Tuple2(
						'Обесточьте все привязываемые экзекуторы и подготовьте их к подаче питания.',
						$ChristophP$elm_i18next$I18Next$string('Обесточьте все привязываемые экзекуторы и подготовьте их к подаче питания.')),
						_Utils_Tuple2(
						'Нажмите и удерживайте 3 секунды кнопку Фениска – загорится светодиод.',
						$ChristophP$elm_i18next$I18Next$string('Нажмите и удерживайте 3 секунды кнопку Фениска – загорится светодиод.')),
						_Utils_Tuple2(
						'Как только светодиод загорится – подайте питание на все привязываемые экзекуторы – светодиод отработает серию частых вспышек и начнёт отрабатывать редкие одиночные вспышки.',
						$ChristophP$elm_i18next$I18Next$string('Как только светодиод загорится – подайте питание на все привязываемые экзекуторы – светодиод отработает серию частых вспышек и начнёт отрабатывать редкие одиночные вспышки.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Фениска и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Закройте крышку корпуса Фениска и закрутите 4 винта.')),
						_Utils_Tuple2(
						'Активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Активация Феникса')),
						_Utils_Tuple2(
						'Нажмите кнопку Феникса – светодиодный индикатор подтвердит включение.',
						$ChristophP$elm_i18next$I18Next$string('Нажмите кнопку Феникса – светодиодный индикатор подтвердит включение.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Феникса и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Закройте крышку корпуса Феникса и закрутите 4 винта.')),
						_Utils_Tuple2(
						'Добавление Феникса в наблюдение',
						$ChristophP$elm_i18next$I18Next$string('Добавление Феникса в наблюдение')),
						_Utils_Tuple2(
						'Отправьте на телефонный номер SIM-карты Феникса SMS: link',
						$ChristophP$elm_i18next$I18Next$string('Отправьте на телефонный номер SIM-карты Феникса SMS: link')),
						_Utils_Tuple2(
						'В ответ придёт уникальный код – введите его в поле ниже:',
						$ChristophP$elm_i18next$I18Next$string('В ответ придёт уникальный код – введите его в поле ниже:')),
						_Utils_Tuple2(
						'Введите уникальный код из SMS',
						$ChristophP$elm_i18next$I18Next$string('Введите уникальный код из SMS')),
						_Utils_Tuple2(
						'Подтвердить',
						$ChristophP$elm_i18next$I18Next$string('Подтвердить')),
						_Utils_Tuple2(
						'Мастер добавления Феникса',
						$ChristophP$elm_i18next$I18Next$string('Мастер добавления Феникса')),
						_Utils_Tuple2(
						'Свериться с',
						$ChristophP$elm_i18next$I18Next$string('Свериться с')),
						_Utils_Tuple2(
						'индикатором',
						$ChristophP$elm_i18next$I18Next$string('индикатором'))
					]))),
			_Utils_Tuple2(
			'config',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Основные настройки феникса',
						$ChristophP$elm_i18next$I18Next$string('Основные настройки феникса')),
						_Utils_Tuple2(
						'Период выхода на связь',
						$ChristophP$elm_i18next$I18Next$string('Период выхода на связь')),
						_Utils_Tuple2(
						'Редко',
						$ChristophP$elm_i18next$I18Next$string('Редко')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь один раз в сутки.',
						$ChristophP$elm_i18next$I18Next$string('Феникс будет выходить на связь один раз в сутки.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 15 лет.',
						$ChristophP$elm_i18next$I18Next$string('Ожидаемый срок службы батареи - 15 лет.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Оптимально')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Феникс будет выходить на связь каждые 6 часов.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 6 лет.',
						$ChristophP$elm_i18next$I18Next$string('Ожидаемый срок службы батареи - 6 лет.')),
						_Utils_Tuple2(
						'Часто',
						$ChristophP$elm_i18next$I18Next$string('Часто')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 2 часа.',
						$ChristophP$elm_i18next$I18Next$string('Феникс будет выходить на связь каждые 2 часа.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 2 года.',
						$ChristophP$elm_i18next$I18Next$string('Ожидаемый срок службы батареи - 2 года.')),
						_Utils_Tuple2(
						'Время работы в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('Время работы в режиме ПОИСК')),
						_Utils_Tuple2(
						'Продолжительно',
						$ChristophP$elm_i18next$I18Next$string('Продолжительно')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 12 часов.',
						$ChristophP$elm_i18next$I18Next$string('Максимальное время работы в режиме ПОИСК - 12 часов.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 15 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ёмкости батареи хватит на 15 активаций режима ПОИСК.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Оптимально')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Максимальное время работы в режиме ПОИСК - 6 часов.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 30 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ёмкости батареи хватит на 30 активаций режима ПОИСК.')),
						_Utils_Tuple2(
						'Минимально',
						$ChristophP$elm_i18next$I18Next$string('Минимально')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 1 час.',
						$ChristophP$elm_i18next$I18Next$string('Максимальное время работы в режиме ПОИСК - 1 час.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 100 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ёмкости батареи хватит на 100 активаций режима ПОИСК.')),
						_Utils_Tuple2(
						'Информирование',
						$ChristophP$elm_i18next$I18Next$string('Информирование')),
						_Utils_Tuple2(
						'Когда происходят определенные события, Феникс может отправлять SMS на заданный номер',
						$ChristophP$elm_i18next$I18Next$string('Когда происходят определенные события, Феникс может отправлять SMS на заданный номер')),
						_Utils_Tuple2(
						'Укажите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Укажите номер телефона')),
						_Utils_Tuple2(
						'Выберите события',
						$ChristophP$elm_i18next$I18Next$string('Выберите события')),
						_Utils_Tuple2(
						'Критический остаток средств',
						$ChristophP$elm_i18next$I18Next$string('Критический остаток средств')),
						_Utils_Tuple2(
						'Низкий уровень заряда батареи',
						$ChristophP$elm_i18next$I18Next$string('Низкий уровень заряда батареи')),
						_Utils_Tuple2(
						'Изменение режима (Поиск <-> Ожидание)',
						$ChristophP$elm_i18next$I18Next$string('Изменение режима (ПОИСК <-> ОЖИДАНИЕ)')),
						_Utils_Tuple2(
						'Изменение местоположения (в режиме Поиск)',
						$ChristophP$elm_i18next$I18Next$string('Изменение местоположения (в режиме ПОИСК)')),
						_Utils_Tuple2(
						'Включение и выключение Феникса',
						$ChristophP$elm_i18next$I18Next$string('Включение и выключение Феникса')),
						_Utils_Tuple2(
						'Вскрытие корпуса',
						$ChristophP$elm_i18next$I18Next$string('Вскрытие корпуса')),
						_Utils_Tuple2(
						'Контроль баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Контроль баланса SIM-карты')),
						_Utils_Tuple2(
						'USSD-запрос баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('USSD-запрос баланса SIM-карты')),
						_Utils_Tuple2(
						'Безопасность',
						$ChristophP$elm_i18next$I18Next$string('Безопасность')),
						_Utils_Tuple2(
						'Чтобы никто посторонний не смог получить управление Вашим Фениксом, вы можете привязать управление к конкретному телефону и установить свой код доступа.',
						$ChristophP$elm_i18next$I18Next$string('Чтобы никто посторонний не смог получить управление Вашим Фениксом, вы можете привязать управление к конкретному телефону и защитить SMS-команды.')),
						_Utils_Tuple2(
						'По умолчанию управление возможно с любого телефона.',
						$ChristophP$elm_i18next$I18Next$string('По умолчанию управление возможно с любого телефона.')),
						_Utils_Tuple2(
						'Управление возможно только с телефона:',
						$ChristophP$elm_i18next$I18Next$string('Управление возможно только с телефона:')),
						_Utils_Tuple2(
						'SMS-коды управления имеют вид:',
						$ChristophP$elm_i18next$I18Next$string('SMS-команды имеют вид:')),
						_Utils_Tuple2(
						'Привязать к телефону',
						$ChristophP$elm_i18next$I18Next$string('Привязать к телефону')),
						_Utils_Tuple2(
						'Установить пароль доступа',
						$ChristophP$elm_i18next$I18Next$string('Защитить SMS-команды')),
						_Utils_Tuple2(
						'Вводите только латинские буквы и цифры',
						$ChristophP$elm_i18next$I18Next$string('Вводите только латинские буквы и цифры')),
						_Utils_Tuple2(
						'Далее',
						$ChristophP$elm_i18next$I18Next$string('Далее')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Назад')),
						_Utils_Tuple2(
						'Применить',
						$ChristophP$elm_i18next$I18Next$string('Применить')),
						_Utils_Tuple2(
						'Поздравляем!',
						$ChristophP$elm_i18next$I18Next$string('Поздравляем!')),
						_Utils_Tuple2(
						'Основные настройки применены',
						$ChristophP$elm_i18next$I18Next$string('Основные настройки применены')),
						_Utils_Tuple2(
						'Перейти к Фениксу',
						$ChristophP$elm_i18next$I18Next$string('Перейти к Фениксу')),
						_Utils_Tuple2(
						'Удалить Феникс',
						$ChristophP$elm_i18next$I18Next$string('Удалить Феникс')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('Экзекутор в наличии')),
						_Utils_Tuple2(
						'Не указан',
						$ChristophP$elm_i18next$I18Next$string('Не указан')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM-карта')),
						_Utils_Tuple2(
						'IMEI',
						$ChristophP$elm_i18next$I18Next$string('IMEI')),
						_Utils_Tuple2(
						'Версия ПО',
						$ChristophP$elm_i18next$I18Next$string('Версия ПО')),
						_Utils_Tuple2(
						'Модель',
						$ChristophP$elm_i18next$I18Next$string('Модель')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Детали о Фениксе')),
						_Utils_Tuple2(
						'Удалить Феникс?',
						$ChristophP$elm_i18next$I18Next$string('Удалить Феникс?')),
						_Utils_Tuple2(
						'remove_fx',
						$ChristophP$elm_i18next$I18Next$string('Вы уверены, что хотите удалить Феникс “{{title}}”? Это действие не может быть отменено.')),
						_Utils_Tuple2(
						'Да, удалить',
						$ChristophP$elm_i18next$I18Next$string('Да, удалить')),
						_Utils_Tuple2(
						'Нет',
						$ChristophP$elm_i18next$I18Next$string('Нет')),
						_Utils_Tuple2(
						'Иконка и название',
						$ChristophP$elm_i18next$I18Next$string('Иконка и название')),
						_Utils_Tuple2(
						'Название Феникса',
						$ChristophP$elm_i18next$I18Next$string('Название “Феникса”')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string('Иконка “Феникса”')),
						_Utils_Tuple2(
						'Ведите новое либо измените старое название',
						$ChristophP$elm_i18next$I18Next$string('Ведите новое либо измените старое название')),
						_Utils_Tuple2(
						'Введите название',
						$ChristophP$elm_i18next$I18Next$string('Введите название')),
						_Utils_Tuple2(
						'Отмена',
						$ChristophP$elm_i18next$I18Next$string('Отмена')),
						_Utils_Tuple2(
						'Сохранить',
						$ChristophP$elm_i18next$I18Next$string('Сохранить')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string('Иконка “Феникса”')),
						_Utils_Tuple2(
						'Выберите подходящую иконку для вашего феникса',
						$ChristophP$elm_i18next$I18Next$string('Выберите подходящую иконку для вашего феникса')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Обслуживание батареи')),
						_Utils_Tuple2(
						'Предполагаемое время',
						$ChristophP$elm_i18next$I18Next$string('Предполагаемое время')),
						_Utils_Tuple2(
						'работы батареи',
						$ChristophP$elm_i18next$I18Next$string('работы батареи')),
						_Utils_Tuple2(
						'Статистика работы',
						$ChristophP$elm_i18next$I18Next$string('Статистика работы')),
						_Utils_Tuple2(
						'Начальная емкость батареи',
						$ChristophP$elm_i18next$I18Next$string('Начальная емкость батареи')),
						_Utils_Tuple2(
						'Начало эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Начало эксплуатации')),
						_Utils_Tuple2(
						'Общее время эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Общее время эксплуатации')),
						_Utils_Tuple2(
						'Израсходовано энергии батареи',
						$ChristophP$elm_i18next$I18Next$string('Израсходовано энергии батареи')),
						_Utils_Tuple2(
						'Замена батареи',
						$ChristophP$elm_i18next$I18Next$string('Замена батареи')),
						_Utils_Tuple2(
						'Изменить начальную емкость',
						$ChristophP$elm_i18next$I18Next$string('Изменить начальную емкость')),
						_Utils_Tuple2(
						'bat_replace_text',
						$ChristophP$elm_i18next$I18Next$string('Замена батареи должна осуществляться специалистом. Используйте оригинальную батарею SAFT LSH 14, 5800мАч. Если у вы устанавливаете другой тип батареи, укажите ее начальную емкость (мАч).')),
						_Utils_Tuple2(
						'Укажите начальную емкость батареи (мАч)',
						$ChristophP$elm_i18next$I18Next$string('Укажите начальную емкость батареи (мАч)')),
						_Utils_Tuple2(
						'bat_ch_capacity',
						$ChristophP$elm_i18next$I18Next$string('Если у вас установлен другой тип батареи, укажите ее начальную емкость (мАч).')),
						_Utils_Tuple2(
						'Предупреждение!',
						$ChristophP$elm_i18next$I18Next$string('Предупреждение!')),
						_Utils_Tuple2(
						'warning_custom',
						$ChristophP$elm_i18next$I18Next$string('Необдуманное изменение настроек может привести к полной неработоспособности Феникса. Изменяйте параметры только если полностью уверены в своих действиях.')),
						_Utils_Tuple2(
						'error_custom',
						$ChristophP$elm_i18next$I18Next$string('Ошибка загрузки или данные от Феникса еще не получены.')),
						_Utils_Tuple2(
						'Отменить',
						$ChristophP$elm_i18next$I18Next$string('Отменить')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Показать')),
						_Utils_Tuple2(
						'изменения',
						$ChristophP$elm_i18next$I18Next$string('изменения')),
						_Utils_Tuple2(
						'Хорошо',
						$ChristophP$elm_i18next$I18Next$string('Хорошо')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - ',
						$ChristophP$elm_i18next$I18Next$string('Максимальное время работы в режиме ПОИСК - ')),
						_Utils_Tuple2(
						'7 суток',
						$ChristophP$elm_i18next$I18Next$string('7 суток')),
						_Utils_Tuple2(
						'24 часа',
						$ChristophP$elm_i18next$I18Next$string('24 часа')),
						_Utils_Tuple2(
						'6 часов',
						$ChristophP$elm_i18next$I18Next$string('6 часов')),
						_Utils_Tuple2(
						'Рекомендуется для охраны автомобиля на длительной стоянке.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендуется для охраны автомобиля на длительной стоянке.')),
						_Utils_Tuple2(
						'Рекомендуется для наиболее вероятного обнаружения угнанного автомобиля.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендуется для наиболее вероятного обнаружения угнанного автомобиля.')),
						_Utils_Tuple2(
						'Рекомендуется для максимальной экономии энергии батареи питания.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендуется для максимальной экономии энергии батареи питания.')),
						_Utils_Tuple2(
						'Следующие параметры будут изменены:',
						$ChristophP$elm_i18next$I18Next$string('Следующие параметры будут изменены:')),
						_Utils_Tuple2(
						'Номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Номер телефона')),
						_Utils_Tuple2(
						'Введите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Введите номер телефона')),
						_Utils_Tuple2(
						'Определено автоматически',
						$ChristophP$elm_i18next$I18Next$string('Определено автоматически')),
						_Utils_Tuple2(
						'Установлен пользователем',
						$ChristophP$elm_i18next$I18Next$string('Установлен пользователем'))
					]))),
			_Utils_Tuple2(
			'control',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Текущий режим',
						$ChristophP$elm_i18next$I18Next$string('Текущий режим')),
						_Utils_Tuple2(
						'Включить режим Поиск',
						$ChristophP$elm_i18next$I18Next$string('Включить режим ПОИСК')),
						_Utils_Tuple2(
						'Связь',
						$ChristophP$elm_i18next$I18Next$string('Связь')),
						_Utils_Tuple2(
						'Последний сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Последний сеанс связи:')),
						_Utils_Tuple2(
						'Следующий сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Следующий сеанс связи:')),
						_Utils_Tuple2(
						'Положение',
						$ChristophP$elm_i18next$I18Next$string('Положение')),
						_Utils_Tuple2(
						'Положение определено:',
						$ChristophP$elm_i18next$I18Next$string('Положение определено:')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Показать')),
						_Utils_Tuple2(
						'Обновить',
						$ChristophP$elm_i18next$I18Next$string('Обновить')),
						_Utils_Tuple2(
						'Предполагаемое время работы батареи',
						$ChristophP$elm_i18next$I18Next$string('Предполагаемое время работы батареи')),
						_Utils_Tuple2(
						'Режим',
						$ChristophP$elm_i18next$I18Next$string('Режим')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM-карта')),
						_Utils_Tuple2(
						'Баланс:',
						$ChristophP$elm_i18next$I18Next$string('Баланс:')),
						_Utils_Tuple2(
						'Пополнить счет',
						$ChristophP$elm_i18next$I18Next$string('Пополнить счет')),
						_Utils_Tuple2(
						'Заблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Заблокировать двигатель')),
						_Utils_Tuple2(
						'Данные о состоянии еще не получены',
						$ChristophP$elm_i18next$I18Next$string('Данные о состоянии еще не получены')),
						_Utils_Tuple2(
						'идет определение...',
						$ChristophP$elm_i18next$I18Next$string('идет определение...')),
						_Utils_Tuple2(
						'Феникс выключен.',
						$ChristophP$elm_i18next$I18Next$string('Феникс выключен.')),
						_Utils_Tuple2(
						'Идет определение местоположения...',
						$ChristophP$elm_i18next$I18Next$string('Идет определение местоположения...')),
						_Utils_Tuple2(
						'Поиск',
						$ChristophP$elm_i18next$I18Next$string('ПОИСК')),
						_Utils_Tuple2(
						'Ожидание',
						$ChristophP$elm_i18next$I18Next$string('ОЖИДАНИЕ')),
						_Utils_Tuple2(
						'Номер телефона был скопирован',
						$ChristophP$elm_i18next$I18Next$string('Номер телефона был скопирован')),
						_Utils_Tuple2(
						'Переход в режим',
						$ChristophP$elm_i18next$I18Next$string('Переход в режим')),
						_Utils_Tuple2(
						'Продлить режим',
						$ChristophP$elm_i18next$I18Next$string('Продлить режим')),
						_Utils_Tuple2(
						'Продлить работу в режиме',
						$ChristophP$elm_i18next$I18Next$string('Продлить работу в режиме')),
						_Utils_Tuple2(
						'Укажите на какое время вы хотите продлить работу',
						$ChristophP$elm_i18next$I18Next$string('Укажите на какое время вы хотите продлить работу')),
						_Utils_Tuple2(
						'в режиме',
						$ChristophP$elm_i18next$I18Next$string('в режиме')),
						_Utils_Tuple2(
						'На 4 часа',
						$ChristophP$elm_i18next$I18Next$string('На 4 часа')),
						_Utils_Tuple2(
						'На сутки',
						$ChristophP$elm_i18next$I18Next$string('На сутки')),
						_Utils_Tuple2(
						'Навсегда',
						$ChristophP$elm_i18next$I18Next$string('Навсегда')),
						_Utils_Tuple2(
						'На ч',
						$ChristophP$elm_i18next$I18Next$string('На {{h}} ч')),
						_Utils_Tuple2(
						'Включить режим',
						$ChristophP$elm_i18next$I18Next$string('Включить режим')),
						_Utils_Tuple2(
						'Смена Режима',
						$ChristophP$elm_i18next$I18Next$string('Смена Режима')),
						_Utils_Tuple2(
						'change_state_dialog',
						$ChristophP$elm_i18next$I18Next$string('{{date}}, при следующем сеансе связи, Феникс будет переведён в режим {{state}}')),
						_Utils_Tuple2(
						'Информация будет доступна после выхода Феникса на связь.',
						$ChristophP$elm_i18next$I18Next$string('Информация будет доступна после выхода Феникса на связь.')),
						_Utils_Tuple2(
						'неизвестно',
						$ChristophP$elm_i18next$I18Next$string('неизвестно')),
						_Utils_Tuple2(
						'Положение неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Положение неизвестно')),
						_Utils_Tuple2(
						'Разблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Разблокировать двигатель')),
						_Utils_Tuple2(
						'wait_state',
						$ChristophP$elm_i18next$I18Next$string('{{datetime}}, при следующем сеансе связи,')),
						_Utils_Tuple2(
						'будет определено текущее местоположение',
						$ChristophP$elm_i18next$I18Next$string('будет определено текущее местоположение')),
						_Utils_Tuple2(
						'будет продлена работа Феникса в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('будет продлена работа Феникса в режиме ПОИСК')),
						_Utils_Tuple2(
						'будет запущена отложенная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('будет запущена отложенная блокировка двигателя')),
						_Utils_Tuple2(
						'будет запущена интеллектуальная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('будет запущена интеллектуальная блокировка двигателя')),
						_Utils_Tuple2(
						'двигатель будет разблокирован',
						$ChristophP$elm_i18next$I18Next$string('двигатель будет разблокирован')),
						_Utils_Tuple2(
						'Феникс будет выключен',
						$ChristophP$elm_i18next$I18Next$string('Феникс будет выключен')),
						_Utils_Tuple2(
						'блокировка будет сброшена',
						$ChristophP$elm_i18next$I18Next$string('блокировка будет сброшена')),
						_Utils_Tuple2(
						'Феникс будет переведён в режим',
						$ChristophP$elm_i18next$I18Next$string('Феникс будет переведён в режим')),
						_Utils_Tuple2(
						'Внимание',
						$ChristophP$elm_i18next$I18Next$string('Внимание')),
						_Utils_Tuple2(
						'Блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('Блокировка двигателя')),
						_Utils_Tuple2(
						'Интеллектуальная блокировка',
						$ChristophP$elm_i18next$I18Next$string('Интеллектуальная блокировка')),
						_Utils_Tuple2(
						'block_smart_text',
						$ChristophP$elm_i18next$I18Next$string('будет запущена интеллектуальная блокировка двигателя. Феникс даст возможность автомобилю беспрепятственно покинуть место отстоя, определит его координаты и при первой же остановке автомобиля – заблокирует двигатель.')),
						_Utils_Tuple2(
						'block_smart_comment',
						$ChristophP$elm_i18next$I18Next$string('Рекомендуется в случаях, если автомобиль может находиться в подземном гараже или в специальном «отстойнике», где определение координат может быть невозможным. В случае блокировки двигателя автомобиль не сможет покинуть место отстоя своим ходом, что насторожит угонщиков и приведёт к устранению «неисправности» и обнаружению Феникса.')),
						_Utils_Tuple2(
						'block_lazy_text',
						$ChristophP$elm_i18next$I18Next$string('будет запущена отложенная блокировка двигателя. Если автомобиль находится в движении – Феникс заблокирует двигатель при его остановке, если автомобиль неподвижен – Феникс заблокирует двигатель немедленно.')),
						_Utils_Tuple2(
						'block_lazy_comment',
						$ChristophP$elm_i18next$I18Next$string('Рекомендуется в случаях, если автомобиль точно не успел доехать до «отстойника» или если автомобиль находится в прямой видимости.')),
						_Utils_Tuple2(
						'Заблокировать',
						$ChristophP$elm_i18next$I18Next$string('Заблокировать')),
						_Utils_Tuple2(
						'Вы сможете нажать эту кнопку после того как Феникc исполнит команды которые ждут выполнения',
						$ChristophP$elm_i18next$I18Next$string('Вы сможете нажать эту кнопку после того как Феникc выполнит команды, которые ждут выполнения.')),
						_Utils_Tuple2(
						'Для включения - откройте крышку Феникса и нажмите кнопку.',
						$ChristophP$elm_i18next$I18Next$string('Для включения - откройте крышку Феникса и нажмите кнопку.')),
						_Utils_Tuple2(
						'Это может занять до 15 минут.',
						$ChristophP$elm_i18next$I18Next$string('Это может занять до 15 минут.'))
					]))),
			_Utils_Tuple2(
			'params',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'accel.count',
						$ChristophP$elm_i18next$I18Next$string('Длительность импульса акселерометра, ms')),
						_Utils_Tuple2(
						'accel.deb',
						$ChristophP$elm_i18next$I18Next$string('Программный антидребезг, число повторов (2-6)')),
						_Utils_Tuple2(
						'accel.lvl',
						$ChristophP$elm_i18next$I18Next$string('Чувствительность акселерометра, mg')),
						_Utils_Tuple2(
						'accel.time',
						$ChristophP$elm_i18next$I18Next$string('Время успокоения акселерметра, секунды (10-300)')),
						_Utils_Tuple2(
						'adc.fix.du',
						$ChristophP$elm_i18next$I18Next$string('Порог изменения напряжения резервного питания, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.dub',
						$ChristophP$elm_i18next$I18Next$string('Порог изменения напряжения основного питания, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.umax',
						$ChristophP$elm_i18next$I18Next$string('Порог максимально допустимого питания, /29.528, V (350-1200)')),
						_Utils_Tuple2(
						'adc.in.1',
						$ChristophP$elm_i18next$I18Next$string('Логический порог напряжения входа 1, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.in.2',
						$ChristophP$elm_i18next$I18Next$string('Логический порог напряжения входа 2, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.photo',
						$ChristophP$elm_i18next$I18Next$string('Чувствительность фотодатчика, (100-2000; 2000-max)')),
						_Utils_Tuple2(
						'adc.photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Время включения фотоохраны, сек (1-32000)')),
						_Utils_Tuple2(
						'photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Время включения фотоохраны, сек (1-32000)')),
						_Utils_Tuple2(
						'adc.u.off',
						$ChristophP$elm_i18next$I18Next$string('Порог выключения основного питания, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'adc.u.on',
						$ChristophP$elm_i18next$I18Next$string('Порог включения основного питания, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'akkum.block.vbat',
						$ChristophP$elm_i18next$I18Next$string('-- добавить описание ---')),
						_Utils_Tuple2(
						'akkum.block.vdd',
						$ChristophP$elm_i18next$I18Next$string('-- добавить описание ---')),
						_Utils_Tuple2(
						'akkum.charge.0',
						$ChristophP$elm_i18next$I18Next$string('Индикация полного разряда батареи, /310.29, V (1050-1150)')),
						_Utils_Tuple2(
						'akkum.charge.30',
						$ChristophP$elm_i18next$I18Next$string('Индикация заряда батареи 30%, /310.29, V (1100-1200)')),
						_Utils_Tuple2(
						'akkum.charge.60',
						$ChristophP$elm_i18next$I18Next$string('Индикация заряда батареи 60%, /310.29, V (1150-1250)')),
						_Utils_Tuple2(
						'akkum.charge.temp',
						$ChristophP$elm_i18next$I18Next$string('Максимальная температура батареи, /?, градусов (?-?)')),
						_Utils_Tuple2(
						'akkum.i.0',
						$ChristophP$elm_i18next$I18Next$string('?Начальный ток заряда батареи, х 20.408 mA (1-10)')),
						_Utils_Tuple2(
						'akkum.i.1',
						$ChristophP$elm_i18next$I18Next$string('?Максимальный ток заряда батареи, х 20.408 mA (10-100)')),
						_Utils_Tuple2(
						'akkum.i.charge',
						$ChristophP$elm_i18next$I18Next$string('-- добавить описание ---')),
						_Utils_Tuple2(
						'akkum.u.0',
						$ChristophP$elm_i18next$I18Next$string('?Минимальное напряжение для обновления прошивки, /310.29, V (1100-1300)')),
						_Utils_Tuple2(
						'sleep',
						$ChristophP$elm_i18next$I18Next$string('Время сна в режимах ОЖИДАНИЕ и ПОИСК, мин')),
						_Utils_Tuple2(
						'adc.vi.fix',
						$ChristophP$elm_i18next$I18Next$string('Коррекция показаний резервного питания.')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('Телефон администратора.')),
						_Utils_Tuple2(
						'alarm.balance',
						$ChristophP$elm_i18next$I18Next$string('SMS о критическом балансе')),
						_Utils_Tuple2(
						'alarm.case',
						$ChristophP$elm_i18next$I18Next$string('SMS о вскрытии корпуса')),
						_Utils_Tuple2(
						'alarm.delay',
						$ChristophP$elm_i18next$I18Next$string('SMS о включении дежурного режима')),
						_Utils_Tuple2(
						'alarm.low',
						$ChristophP$elm_i18next$I18Next$string('SMS о низком уровне ёмкости батареи')),
						_Utils_Tuple2(
						'alarm.error',
						$ChristophP$elm_i18next$I18Next$string('SMS о невозможности определения координат')),
						_Utils_Tuple2(
						'alarm.gps',
						$ChristophP$elm_i18next$I18Next$string('SMS об изменении координат')),
						_Utils_Tuple2(
						'alarm.mode',
						$ChristophP$elm_i18next$I18Next$string('SMS о смене режима')),
						_Utils_Tuple2(
						'alarm.on',
						$ChristophP$elm_i18next$I18Next$string('SMS о включении Феникса')),
						_Utils_Tuple2(
						'alarm.off',
						$ChristophP$elm_i18next$I18Next$string('SMS о выключении Феникса')),
						_Utils_Tuple2(
						'alarm.stealth',
						$ChristophP$elm_i18next$I18Next$string('SMS об активации режима Stealth')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('Номер телефона администратора')),
						_Utils_Tuple2(
						'alarm1',
						$ChristophP$elm_i18next$I18Next$string('Первый номер телефона для отправки SMS')),
						_Utils_Tuple2(
						'alarm2',
						$ChristophP$elm_i18next$I18Next$string('Второй номер телефона для отправки SMS')),
						_Utils_Tuple2(
						'alarm3',
						$ChristophP$elm_i18next$I18Next$string('Третий номер телефона для отправки SMS')),
						_Utils_Tuple2(
						'alarm4',
						$ChristophP$elm_i18next$I18Next$string('Четвертый номер телефона для отправки SMS')),
						_Utils_Tuple2(
						'auto.sleep',
						$ChristophP$elm_i18next$I18Next$string('Автоматическое включение режима ОЖИДАНИЕ, мин')),
						_Utils_Tuple2(
						'balance.skip',
						$ChristophP$elm_i18next$I18Next$string('Пропуск цифр в отчёте оператора о балансе')),
						_Utils_Tuple2(
						'balance.ussd',
						$ChristophP$elm_i18next$I18Next$string('USSD-запрос остатка средств на карточке')),
						_Utils_Tuple2(
						'config.send',
						$ChristophP$elm_i18next$I18Next$string('Период сеансов связи в режиме Конфигуратор, мин')),
						_Utils_Tuple2(
						'delay',
						$ChristophP$elm_i18next$I18Next$string('Задержка выключения GSM-модуля, в циклах связи')),
						_Utils_Tuple2(
						'distance',
						$ChristophP$elm_i18next$I18Next$string('Максимально допустимое отклонение координат, м')),
						_Utils_Tuple2(
						'gps.angle',
						$ChristophP$elm_i18next$I18Next$string('Минимальный регистрируемый угол поворота, градусов')),
						_Utils_Tuple2(
						'gps.aoff',
						$ChristophP$elm_i18next$I18Next$string('Автовыключение GPS для экономии батареи, мин')),
						_Utils_Tuple2(
						'gps.delta',
						$ChristophP$elm_i18next$I18Next$string('Принудительная регистрация координат при перемещении, м')),
						_Utils_Tuple2(
						'gps.fail',
						$ChristophP$elm_i18next$I18Next$string('Задержка перезапуска GPS-модуля при пропадании спутников, мин')),
						_Utils_Tuple2(
						'gps.flush.move',
						$ChristophP$elm_i18next$I18Next$string('Период отправки данных на сервер при движении, сек')),
						_Utils_Tuple2(
						'gps.flush.stop',
						$ChristophP$elm_i18next$I18Next$string('Период отправки данных на сервер при стоянке, сек')),
						_Utils_Tuple2(
						'gps.flush',
						$ChristophP$elm_i18next$I18Next$string('Период отправки данных на сервер, сек')),
						_Utils_Tuple2(
						'gps.tf.move',
						$ChristophP$elm_i18next$I18Next$string('Принудительная регистрация координат при движении, сек')),
						_Utils_Tuple2(
						'gps.tf.stop',
						$ChristophP$elm_i18next$I18Next$string('Период регистрации координат при остановке, сек')),
						_Utils_Tuple2(
						'gps.tf',
						$ChristophP$elm_i18next$I18Next$string('Период регистрации координат, сек')),
						_Utils_Tuple2(
						'gps.tf.stop.acc',
						$ChristophP$elm_i18next$I18Next$string('Период регистрации координат при стоянке, сек')),
						_Utils_Tuple2(
						'gps.valid.delay',
						$ChristophP$elm_i18next$I18Next$string('Данные от GPS берутся не первые после fix, а пропускается указанное кол-во')),
						_Utils_Tuple2(
						'gps.vignacc',
						$ChristophP$elm_i18next$I18Next$string('Скорость принудительной регистрации движения (игнорируется акселерометр) × 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.vstart',
						$ChristophP$elm_i18next$I18Next$string('Скорость регистрации начала движения × 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.vstop',
						$ChristophP$elm_i18next$I18Next$string('Скорость регистрирации остановки объекта × 0,01852 км/ч')),
						_Utils_Tuple2(
						'gps.data.1',
						$ChristophP$elm_i18next$I18Next$string('Назначение дополнительного поля данных GPS')),
						_Utils_Tuple2(
						'gps.wait',
						$ChristophP$elm_i18next$I18Next$string('Время ожидания обнаружения спутников, мин')),
						_Utils_Tuple2(
						'gsm.apn',
						$ChristophP$elm_i18next$I18Next$string('Программируеная точка входа в Интернет - APN')),
						_Utils_Tuple2(
						'gsm.balance.period',
						$ChristophP$elm_i18next$I18Next$string('Период контроля баланса, ч')),
						_Utils_Tuple2(
						'gsm.flags',
						$ChristophP$elm_i18next$I18Next$string('Работа в роуминге, в том числе, в национальном')),
						_Utils_Tuple2(
						'gsm.lagtime',
						$ChristophP$elm_i18next$I18Next$string('Период проверки GSM-модуля на предмет зависания, мин')),
						_Utils_Tuple2(
						'gsm.predelay',
						$ChristophP$elm_i18next$I18Next$string('Время проверки GSM-ретрансляторов и ожидания SMS, мин')),
						_Utils_Tuple2(
						'gsm.user',
						$ChristophP$elm_i18next$I18Next$string('Имя пользователя для GPRS-доступа')),
						_Utils_Tuple2(
						'gsm.pwd',
						$ChristophP$elm_i18next$I18Next$string('Пароль для GPRS-доступа')),
						_Utils_Tuple2(
						'limit',
						$ChristophP$elm_i18next$I18Next$string('Минимально допустимый баланс SIM-карты')),
						_Utils_Tuple2(
						'link.delay',
						$ChristophP$elm_i18next$I18Next$string('Время удержания кнопки для привязки, сек')),
						_Utils_Tuple2(
						'mode',
						$ChristophP$elm_i18next$I18Next$string('Режим работы')),
						_Utils_Tuple2(
						'photo',
						$ChristophP$elm_i18next$I18Next$string('Фотодатчик')),
						_Utils_Tuple2(
						'secur.code',
						$ChristophP$elm_i18next$I18Next$string('Код безопасности для SMS-команд')),
						_Utils_Tuple2(
						'sms.confirm',
						$ChristophP$elm_i18next$I18Next$string('Оповещение о выполнении команды смены режима.')),
						_Utils_Tuple2(
						'timezone',
						$ChristophP$elm_i18next$I18Next$string('Разница между текущим и мировым часовыми поясами, ч')),
						_Utils_Tuple2(
						'vin.low',
						$ChristophP$elm_i18next$I18Next$string('Напряжение для сообщения о разряде батареи, V')),
						_Utils_Tuple2(
						'vin.off',
						$ChristophP$elm_i18next$I18Next$string('Напряжение автоматического выключения Феникса, V'))
					]))),
			_Utils_Tuple2(
			'map',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Скрыть трек',
						$ChristophP$elm_i18next$I18Next$string('Скрыть трек')),
						_Utils_Tuple2(
						'Закрыть',
						$ChristophP$elm_i18next$I18Next$string('Закрыть')),
						_Utils_Tuple2(
						'Последнее положение определено ',
						$ChristophP$elm_i18next$I18Next$string('Последнее положение определено '))
					]))),
			_Utils_Tuple2(
			'logs',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'dates',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						' год',
						$ChristophP$elm_i18next$I18Next$string(' год')),
						_Utils_Tuple2(
						' года',
						$ChristophP$elm_i18next$I18Next$string(' года')),
						_Utils_Tuple2(
						' лет',
						$ChristophP$elm_i18next$I18Next$string(' лет')),
						_Utils_Tuple2(
						' месяц',
						$ChristophP$elm_i18next$I18Next$string(' месяц')),
						_Utils_Tuple2(
						' месяца',
						$ChristophP$elm_i18next$I18Next$string(' месяца')),
						_Utils_Tuple2(
						' месяцев',
						$ChristophP$elm_i18next$I18Next$string(' месяцев')),
						_Utils_Tuple2(
						' день',
						$ChristophP$elm_i18next$I18Next$string(' день')),
						_Utils_Tuple2(
						' дня',
						$ChristophP$elm_i18next$I18Next$string(' дня')),
						_Utils_Tuple2(
						' дней',
						$ChristophP$elm_i18next$I18Next$string(' дней')),
						_Utils_Tuple2(
						' час',
						$ChristophP$elm_i18next$I18Next$string(' час')),
						_Utils_Tuple2(
						' часа',
						$ChristophP$elm_i18next$I18Next$string(' часа')),
						_Utils_Tuple2(
						' часов',
						$ChristophP$elm_i18next$I18Next$string(' часов'))
					]))),
			_Utils_Tuple2(
			'status',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Авто в движении',
						$ChristophP$elm_i18next$I18Next$string('Авто в движении')),
						_Utils_Tuple2(
						'Авто в спящем состоянии',
						$ChristophP$elm_i18next$I18Next$string('Авто в спящем состоянии')),
						_Utils_Tuple2(
						'Состояние еще неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Состояние еще неизвестно')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string('')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'errors',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Код неверный, уже использован или вышло время действия кода.',
						$ChristophP$elm_i18next$I18Next$string('Код неверный, уже использован или вышло время действия кода.')),
						_Utils_Tuple2(
						'Неверное имя пользователя или пароль.',
						$ChristophP$elm_i18next$I18Next$string('Неверное имя пользователя или пароль.')),
						_Utils_Tuple2(
						'Ошибка! Система не существует или у вас недостаточно прав для просмотра.',
						$ChristophP$elm_i18next$I18Next$string('Ошибка! Система не существует или у вас недостаточно прав для просмотра.'))
					])))
		]));
var $author$project$I18N$Ua$translations = $ChristophP$elm_i18next$I18Next$fromTree(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'Список Фениксов',
			$ChristophP$elm_i18next$I18Next$string('Перелік Фенiксiв')),
			_Utils_Tuple2(
			'Да',
			$ChristophP$elm_i18next$I18Next$string('Так')),
			_Utils_Tuple2(
			'Нет',
			$ChristophP$elm_i18next$I18Next$string('Нi')),
			_Utils_Tuple2(
			'Режим',
			$ChristophP$elm_i18next$I18Next$string('Режим')),
			_Utils_Tuple2(
			'Поиск',
			$ChristophP$elm_i18next$I18Next$string('Пошук')),
			_Utils_Tuple2(
			'Ожидание',
			$ChristophP$elm_i18next$I18Next$string('Очікування')),
			_Utils_Tuple2(
			'Выключен',
			$ChristophP$elm_i18next$I18Next$string('Вимкнуто')),
			_Utils_Tuple2(
			'Ошибка',
			$ChristophP$elm_i18next$I18Next$string('Помилка')),
			_Utils_Tuple2(
			'themes',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'dark',
						$ChristophP$elm_i18next$I18Next$string('Темна')),
						_Utils_Tuple2(
						'light',
						$ChristophP$elm_i18next$I18Next$string('Свiтла'))
					]))),
			_Utils_Tuple2(
			'scales',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'normal',
						$ChristophP$elm_i18next$I18Next$string('Звичайний')),
						_Utils_Tuple2(
						'small',
						$ChristophP$elm_i18next$I18Next$string('Маленький')),
						_Utils_Tuple2(
						'big',
						$ChristophP$elm_i18next$I18Next$string('Великий')),
						_Utils_Tuple2(
						'bigger',
						$ChristophP$elm_i18next$I18Next$string('Більший')),
						_Utils_Tuple2(
						'biggest',
						$ChristophP$elm_i18next$I18Next$string('Найбільший'))
					]))),
			_Utils_Tuple2(
			'menu',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Системные опции',
						$ChristophP$elm_i18next$I18Next$string('Системнi опцiї')),
						_Utils_Tuple2(
						'Аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Акаунт')),
						_Utils_Tuple2(
						'Язык',
						$ChristophP$elm_i18next$I18Next$string('Мова')),
						_Utils_Tuple2(
						'Тема',
						$ChristophP$elm_i18next$I18Next$string('Тема')),
						_Utils_Tuple2(
						'Размер',
						$ChristophP$elm_i18next$I18Next$string('Розмір')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Налаштування')),
						_Utils_Tuple2(
						'Выйти',
						$ChristophP$elm_i18next$I18Next$string('Вийти')),
						_Utils_Tuple2(
						'Выйти?',
						$ChristophP$elm_i18next$I18Next$string('Вийти?')),
						_Utils_Tuple2(
						'Вы действительно хотите выйти?',
						$ChristophP$elm_i18next$I18Next$string('Ви дiйсно бажаєте вийти?')),
						_Utils_Tuple2(
						'Список Фениксов',
						$ChristophP$elm_i18next$I18Next$string('Перелік Фениксів')),
						_Utils_Tuple2(
						'Карта',
						$ChristophP$elm_i18next$I18Next$string('Мапа')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Управління')),
						_Utils_Tuple2(
						'Настройки',
						$ChristophP$elm_i18next$I18Next$string('Налаштування')),
						_Utils_Tuple2(
						'События',
						$ChristophP$elm_i18next$I18Next$string('Події')),
						_Utils_Tuple2(
						'Иконка и название Феникса',
						$ChristophP$elm_i18next$I18Next$string('Іконка та назва Фенікса')),
						_Utils_Tuple2(
						'Основные настройки',
						$ChristophP$elm_i18next$I18Next$string('Основні налаштування')),
						_Utils_Tuple2(
						'Расширенные настройки',
						$ChristophP$elm_i18next$I18Next$string('Розширені налаштування')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Обслуговування батареї')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Детальніше про Фенікс'))
					]))),
			_Utils_Tuple2(
			'login',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добро пожаловать',
						$ChristophP$elm_i18next$I18Next$string('Ласкаво просимо!')),
						_Utils_Tuple2(
						'Добро пожаловать!',
						$ChristophP$elm_i18next$I18Next$string('Ласкаво просимо!')),
						_Utils_Tuple2(
						'Войдите, чтобы продолжить',
						$ChristophP$elm_i18next$I18Next$string('Увійдіть, щоб продовжити')),
						_Utils_Tuple2(
						'Введите Ваш логин',
						$ChristophP$elm_i18next$I18Next$string('Введіть Ваш логін')),
						_Utils_Tuple2(
						'name_not_found',
						$ChristophP$elm_i18next$I18Next$string('Ім\'я користувача не знайдено. Будь ласка перевірте та спробуйте ще раз.')),
						_Utils_Tuple2(
						'Введите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Введіть Ваш пароль.')),
						_Utils_Tuple2(
						'Войти в систему',
						$ChristophP$elm_i18next$I18Next$string('Увійти в систему.')),
						_Utils_Tuple2(
						'У вас нет аккаунта?',
						$ChristophP$elm_i18next$I18Next$string('У Вас немає акаунта?')),
						_Utils_Tuple2(
						'Зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('Зареєструватись')),
						_Utils_Tuple2(
						'Создать аккаунт',
						$ChristophP$elm_i18next$I18Next$string('Створити акаунт')),
						_Utils_Tuple2(
						'Чтобы начать работать',
						$ChristophP$elm_i18next$I18Next$string('Щоб почати роботу')),
						_Utils_Tuple2(
						'Повторите Ваш пароль',
						$ChristophP$elm_i18next$I18Next$string('Повторіть Ваш пароль')),
						_Utils_Tuple2(
						'Я прочитал и принимаю условия',
						$ChristophP$elm_i18next$I18Next$string('Я прочитав і приймаю умови користування')),
						_Utils_Tuple2(
						'пользовательского соглашения',
						$ChristophP$elm_i18next$I18Next$string('')),
						_Utils_Tuple2(
						'Уже есть аккаунт?',
						$ChristophP$elm_i18next$I18Next$string('Вже є акаунт?')),
						_Utils_Tuple2(
						'Введите имя пользователя',
						$ChristophP$elm_i18next$I18Next$string('Введіть ім`я користувача')),
						_Utils_Tuple2(
						'Укажите пароль',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть пароль')),
						_Utils_Tuple2(
						'Укажите пароль повторно',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть пароль повторно')),
						_Utils_Tuple2(
						'Пароль указан неверно',
						$ChristophP$elm_i18next$I18Next$string('Пароль вказаний невірно')),
						_Utils_Tuple2(
						'Вы должны принять условия',
						$ChristophP$elm_i18next$I18Next$string('Ви маєте прийняти умови користування')),
						_Utils_Tuple2(
						'Чтобы пользоваться сервисом, вы должны ',
						$ChristophP$elm_i18next$I18Next$string('Щоб користуватися сервісом, ви повинні ')),
						_Utils_Tuple2(
						'авторизоваться',
						$ChristophP$elm_i18next$I18Next$string('авторизуватись')),
						_Utils_Tuple2(
						'зарегистрироваться',
						$ChristophP$elm_i18next$I18Next$string('зареєструватися')),
						_Utils_Tuple2(
						'Добавьте Феникс в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('Додайте Феникс в список наглядів'))
					]))),
			_Utils_Tuple2(
			'list',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Добавьте ещё один Феникс',
						$ChristophP$elm_i18next$I18Next$string('Додайте ще один Фенікс')),
						_Utils_Tuple2(
						'в список наблюдения',
						$ChristophP$elm_i18next$I18Next$string('у перелік спостереження')),
						_Utils_Tuple2(
						'Добавить',
						$ChristophP$elm_i18next$I18Next$string('Додати')),
						_Utils_Tuple2(
						'Режим Поиск:',
						$ChristophP$elm_i18next$I18Next$string('Режим ПОШУК:')),
						_Utils_Tuple2(
						'Режим Ожидание:',
						$ChristophP$elm_i18next$I18Next$string('Режим ОЧІКУВАННЯ:')),
						_Utils_Tuple2(
						'На карте',
						$ChristophP$elm_i18next$I18Next$string('На мапі')),
						_Utils_Tuple2(
						'Управление',
						$ChristophP$elm_i18next$I18Next$string('Управління')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'master',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Далeе',
						$ChristophP$elm_i18next$I18Next$string('Далі')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Назад')),
						_Utils_Tuple2(
						'Подготовка SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Підготовка SIM-карти')),
						_Utils_Tuple2(
						'Установите SIM-карту в мобильный телефон.',
						$ChristophP$elm_i18next$I18Next$string('Встановіть SIM – карту в мобільний телефон.')),
						_Utils_Tuple2(
						'Активируйте SIM-карту в соответствии с инструкциями GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('Активуйте сім карту у відповідності до інструкцій  GSM оператора.')),
						_Utils_Tuple2(
						'Убедитесь в том, что PIN-код при включении телефона выключен.',
						$ChristophP$elm_i18next$I18Next$string('Переконайтесь , що РIN – код при увімкненні телефону вимкнений.')),
						_Utils_Tuple2(
						'В случае необходимости зарегистрируйте SIM-карту на сайте GSM-оператора.',
						$ChristophP$elm_i18next$I18Next$string('При необхідності, зареєструйте SIM-карту на сайті GSM-оператора.')),
						_Utils_Tuple2(
						'Выключите мобильный телефон и извлеките из него подготовленную SIM-карту.',
						$ChristophP$elm_i18next$I18Next$string('Вимкніть мобільний телефон і дістаньте з нього підготовлену  SIM-карту.')),
						_Utils_Tuple2(
						'Установка подготовленной SIM-карты в Феникс',
						$ChristophP$elm_i18next$I18Next$string('Встановлення підготовленої SIM-карти у Фенікс')),
						_Utils_Tuple2(
						'Выкрутите 4 винта и снимите крышку корпуса.',
						$ChristophP$elm_i18next$I18Next$string('Викрутіть 4 гвинти і зніміть кришку корпусу.')),
						_Utils_Tuple2(
						'Убедитесь в том, что Феникс выключен – светодиодный индикатор не горит и не мигает.',
						$ChristophP$elm_i18next$I18Next$string('Переконайтесь в тому, що Фенікс вимкнений – світлодіодний індикатор не світиться і не блискає.')),
						_Utils_Tuple2(
						'Установите подготовленную SIM-карту в Феникс.',
						$ChristophP$elm_i18next$I18Next$string('Встановіть підготовлену  SIM-карту у Фенікс.')),
						_Utils_Tuple2(
						'В случае необходимости произведите привязку экзекуторов.',
						$ChristophP$elm_i18next$I18Next$string('При необхідності проведіть прив’язку езекуторів.')),
						_Utils_Tuple2(
						'Привязать экзекутор',
						$ChristophP$elm_i18next$I18Next$string('Прив’язати екзекутор.')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('Екзекутор в наявності')),
						_Utils_Tuple2(
						'Привязка экзекуторов и активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Прив’язка екзекуторів і активація Фенікса')),
						_Utils_Tuple2(
						'Исходное состояние: Феникс – выключен.',
						$ChristophP$elm_i18next$I18Next$string('Початковий стан: Фенікс вимкнений.')),
						_Utils_Tuple2(
						'Обесточьте все привязываемые экзекуторы и подготовьте их к подаче питания.',
						$ChristophP$elm_i18next$I18Next$string('Знеструмте усі екзекутори, що будуть прив’язуватись і підготуйте їх до подачі напруги.')),
						_Utils_Tuple2(
						'Нажмите и удерживайте 3 секунды кнопку Фениска – загорится светодиод.',
						$ChristophP$elm_i18next$I18Next$string('Натисніть і утримуйте 3 секунди кнопку Фенікса  - засвітиться світлодіод.')),
						_Utils_Tuple2(
						'Как только светодиод загорится – подайте питание на все привязываемые экзекуторы – светодиод отработает серию частых вспышек и начнёт отрабатывать редкие одиночные вспышки.',
						$ChristophP$elm_i18next$I18Next$string('Як тільки світлодіод засвітиться – подайте струм на усі екзекутори, що прив’язуються до даного Фенікса – світлодіод відпрацює серію частих спалахів і почне відпрацьовувати рідкі поодиночні спалахи.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Фениска и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Закрийте кришку корпусу Фенікса і закрутіть 4 гвинти.')),
						_Utils_Tuple2(
						'Активация Феникса',
						$ChristophP$elm_i18next$I18Next$string('Активация Феникса')),
						_Utils_Tuple2(
						'Нажмите кнопку Феникса – светодиодный индикатор подтвердит включение.',
						$ChristophP$elm_i18next$I18Next$string('Натисніть кнопку Фенікса - світлодіодний індикатор підтвердить увімкнення.')),
						_Utils_Tuple2(
						'Закройте крышку корпуса Феникса и закрутите 4 винта.',
						$ChristophP$elm_i18next$I18Next$string('Закрийте кришку корпусу Фенікса і закрутіть 4 гвинти.')),
						_Utils_Tuple2(
						'Добавление Феникса в наблюдение',
						$ChristophP$elm_i18next$I18Next$string('Додавання Фенікса в спостереження')),
						_Utils_Tuple2(
						'Отправьте на телефонный номер SIM-карты Феникса SMS: link',
						$ChristophP$elm_i18next$I18Next$string('Відправте на телефонний номер SIM-карти Фенікса SMS: link')),
						_Utils_Tuple2(
						'В ответ придёт уникальный код – введите его в поле ниже:',
						$ChristophP$elm_i18next$I18Next$string('У відповідь прийде унікальний код – введіть його в поле нижче:')),
						_Utils_Tuple2(
						'Введите уникальный код из SMS',
						$ChristophP$elm_i18next$I18Next$string('Введіть унікальний код із  SMS')),
						_Utils_Tuple2(
						'Подтвердить',
						$ChristophP$elm_i18next$I18Next$string('Подтвердить')),
						_Utils_Tuple2(
						'Мастер добавления Феникса',
						$ChristophP$elm_i18next$I18Next$string('Додавання Фенікса в спостереження.')),
						_Utils_Tuple2(
						'Свериться с',
						$ChristophP$elm_i18next$I18Next$string('Звіритись з')),
						_Utils_Tuple2(
						'индикатором',
						$ChristophP$elm_i18next$I18Next$string('індикатором'))
					]))),
			_Utils_Tuple2(
			'config',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Основные настройки феникса',
						$ChristophP$elm_i18next$I18Next$string('Основні налаштування фенікса')),
						_Utils_Tuple2(
						'Период выхода на связь',
						$ChristophP$elm_i18next$I18Next$string('Період виходу на зв\'язок')),
						_Utils_Tuple2(
						'Редко',
						$ChristophP$elm_i18next$I18Next$string('Рідко')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь один раз в сутки.',
						$ChristophP$elm_i18next$I18Next$string('Фенікс буде виходити на зв\'язок один раз на добу.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 15 лет.',
						$ChristophP$elm_i18next$I18Next$string('Очікуваний час роботи батареї - 15 років.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Оптимально')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Фенікс буде виходити на зв\'язок кожні 6 годин.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 6 лет.',
						$ChristophP$elm_i18next$I18Next$string('Очікуваний час роботи батареї - 6 років.')),
						_Utils_Tuple2(
						'Часто',
						$ChristophP$elm_i18next$I18Next$string('Часто')),
						_Utils_Tuple2(
						'Феникс будет выходить на связь каждые 2 часа.',
						$ChristophP$elm_i18next$I18Next$string('Фенікс буде виходити на зв\'язок кожні 2 години.')),
						_Utils_Tuple2(
						'Ожидаемый срок службы батареи - 2 года.',
						$ChristophP$elm_i18next$I18Next$string('Очікуваний час роботи батареї - 2 роки.')),
						_Utils_Tuple2(
						'Время работы в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('Час роботи в режимі ПОШУК')),
						_Utils_Tuple2(
						'Продолжительно',
						$ChristophP$elm_i18next$I18Next$string('Тривало')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 12 часов.',
						$ChristophP$elm_i18next$I18Next$string('Максимальний час роботи в режимі ПОШУК - 12 годин.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 15 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ємкості батареї вистачить на 15 активацій режиму ПОШУК.')),
						_Utils_Tuple2(
						'Оптимально',
						$ChristophP$elm_i18next$I18Next$string('Оптимально')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 6 часов.',
						$ChristophP$elm_i18next$I18Next$string('Максимальний час роботи в режимі ПОШУК - 6 годин.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 30 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ємкості батареї вистачить на 30 активацій режиму ПОШУК.')),
						_Utils_Tuple2(
						'Минимально',
						$ChristophP$elm_i18next$I18Next$string('Мінімально')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - 1 час.',
						$ChristophP$elm_i18next$I18Next$string('Максимальний час роботи в режимі ПОШУК - 1 година.')),
						_Utils_Tuple2(
						'Ёмкости батареи хватит на 100 активаций режима Поиск.',
						$ChristophP$elm_i18next$I18Next$string('Ємкості батареї вистачить на 100 активацій режиму ПОШУК.')),
						_Utils_Tuple2(
						'Информирование',
						$ChristophP$elm_i18next$I18Next$string('Інформування')),
						_Utils_Tuple2(
						'Когда происходят определенные события, Феникс может отправлять SMS на заданный номер',
						$ChristophP$elm_i18next$I18Next$string('Коли виникають певні події, Фенікс може відправляти SMS на заданий номер')),
						_Utils_Tuple2(
						'Укажите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть номер телефону')),
						_Utils_Tuple2(
						'Выберите события',
						$ChristophP$elm_i18next$I18Next$string('Виберіть події')),
						_Utils_Tuple2(
						'Критический остаток средств',
						$ChristophP$elm_i18next$I18Next$string('Критичний залишок коштів')),
						_Utils_Tuple2(
						'Низкий уровень заряда батареи',
						$ChristophP$elm_i18next$I18Next$string('Низький рівень заряду батареї')),
						_Utils_Tuple2(
						'Изменение режима (Поиск <-> Ожидание)',
						$ChristophP$elm_i18next$I18Next$string('Зміна режиму (ПОШУК <-> ОЧІКУВАННЯ)')),
						_Utils_Tuple2(
						'Изменение местоположения (в режиме Поиск)',
						$ChristophP$elm_i18next$I18Next$string('Зміна місця положення (в режимі ПОШУК)')),
						_Utils_Tuple2(
						'Начало движения (в режиме Поиск)',
						$ChristophP$elm_i18next$I18Next$string('Початок руху (в режимі ПОШУК)')),
						_Utils_Tuple2(
						'Включение и выключение Феникса',
						$ChristophP$elm_i18next$I18Next$string('Увімкнення та вимкнення Феніксу')),
						_Utils_Tuple2(
						'Вскрытие корпуса',
						$ChristophP$elm_i18next$I18Next$string('Відкриття корпусу')),
						_Utils_Tuple2(
						'Контроль баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('Контроль баланса SIM-карти')),
						_Utils_Tuple2(
						'USSD-запрос баланса SIM-карты',
						$ChristophP$elm_i18next$I18Next$string('USSD-запит баланса SIM-карти')),
						_Utils_Tuple2(
						'Безопасность',
						$ChristophP$elm_i18next$I18Next$string('Безпека')),
						_Utils_Tuple2(
						'Чтобы никто посторонний не смог получить управление Вашим Фениксом, вы можете привязать управление к конкретному телефону и установить свой код доступа.',
						$ChristophP$elm_i18next$I18Next$string('Щоб ніхто посторонній не зміг отримати управління вашим Феніксом, ви можете прив\'язати управляння до конкретного телефону і захистити SMS-команди.')),
						_Utils_Tuple2(
						'По умолчанию управление возможно с любого телефона.',
						$ChristophP$elm_i18next$I18Next$string('За замовчуванням управління можливе з будь-якого телефонного номеру.')),
						_Utils_Tuple2(
						'Управление возможно только с телефона:',
						$ChristophP$elm_i18next$I18Next$string('Управляння можливе лише з телефону:')),
						_Utils_Tuple2(
						'SMS-коды управления имеют вид:',
						$ChristophP$elm_i18next$I18Next$string('SMS-команди мають вигляд:')),
						_Utils_Tuple2(
						'Привязать к телефону',
						$ChristophP$elm_i18next$I18Next$string('Прив\'язати до телефону')),
						_Utils_Tuple2(
						'Установить пароль доступа',
						$ChristophP$elm_i18next$I18Next$string('Захистити SMS-команди')),
						_Utils_Tuple2(
						'Вводите только латинские буквы и цифры',
						$ChristophP$elm_i18next$I18Next$string('Введіть лише латинські букви та цифри')),
						_Utils_Tuple2(
						'Далее',
						$ChristophP$elm_i18next$I18Next$string('Далі')),
						_Utils_Tuple2(
						'Назад',
						$ChristophP$elm_i18next$I18Next$string('Назад')),
						_Utils_Tuple2(
						'Применить',
						$ChristophP$elm_i18next$I18Next$string('Застосувати')),
						_Utils_Tuple2(
						'Поздравляем!',
						$ChristophP$elm_i18next$I18Next$string('Вітаємо!')),
						_Utils_Tuple2(
						'Основные настройки применены',
						$ChristophP$elm_i18next$I18Next$string('Основні налаштування застосовані')),
						_Utils_Tuple2(
						'Перейти к Фениксу',
						$ChristophP$elm_i18next$I18Next$string('Перейти до Феніксу')),
						_Utils_Tuple2(
						'Удалить Феникс',
						$ChristophP$elm_i18next$I18Next$string('Видалити Фенікс')),
						_Utils_Tuple2(
						'Экзекутор в наличии',
						$ChristophP$elm_i18next$I18Next$string('Екзекутор в наявності')),
						_Utils_Tuple2(
						'Не указан',
						$ChristophP$elm_i18next$I18Next$string('Не вказано')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM-карта')),
						_Utils_Tuple2(
						'IMEI',
						$ChristophP$elm_i18next$I18Next$string('IMEI')),
						_Utils_Tuple2(
						'Версия ПО',
						$ChristophP$elm_i18next$I18Next$string('Версія ПЗ')),
						_Utils_Tuple2(
						'Модель',
						$ChristophP$elm_i18next$I18Next$string('Модель')),
						_Utils_Tuple2(
						'Детали о Фениксе',
						$ChristophP$elm_i18next$I18Next$string('Детальніше про Фенікс')),
						_Utils_Tuple2(
						'Удалить Феникс?',
						$ChristophP$elm_i18next$I18Next$string('Видалити Фенікс?')),
						_Utils_Tuple2(
						'remove_fx',
						$ChristophP$elm_i18next$I18Next$string('Ви впевнені, що бажаєте видалити Фенікс “{{title}}”? Ця дія не може бути відмінена.')),
						_Utils_Tuple2(
						'Да, удалить',
						$ChristophP$elm_i18next$I18Next$string('Так, видалити')),
						_Utils_Tuple2(
						'Нет',
						$ChristophP$elm_i18next$I18Next$string('Ні')),
						_Utils_Tuple2(
						'Иконка и название',
						$ChristophP$elm_i18next$I18Next$string('Іконка і назва')),
						_Utils_Tuple2(
						'Название Феникса',
						$ChristophP$elm_i18next$I18Next$string('Назва “Фенікса”')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string('Іконка “Фенікса”')),
						_Utils_Tuple2(
						'Ведите новое либо измените старое название',
						$ChristophP$elm_i18next$I18Next$string('Введіть нове або змініть стару назву')),
						_Utils_Tuple2(
						'Введите название',
						$ChristophP$elm_i18next$I18Next$string('Введіть назву')),
						_Utils_Tuple2(
						'Отмена',
						$ChristophP$elm_i18next$I18Next$string('Відхилити')),
						_Utils_Tuple2(
						'Сохранить',
						$ChristophP$elm_i18next$I18Next$string('Зберегти')),
						_Utils_Tuple2(
						'Иконка Феникса',
						$ChristophP$elm_i18next$I18Next$string('Іконка “Фенікса”')),
						_Utils_Tuple2(
						'Выберите подходящую иконку для вашего феникса',
						$ChristophP$elm_i18next$I18Next$string('Виберіть відповідну іконку для вашого Фенікса')),
						_Utils_Tuple2(
						'Обслуживание батареи',
						$ChristophP$elm_i18next$I18Next$string('Обслуговування батареї')),
						_Utils_Tuple2(
						'Предполагаемое время',
						$ChristophP$elm_i18next$I18Next$string('Очікуємий час')),
						_Utils_Tuple2(
						'работы батареи',
						$ChristophP$elm_i18next$I18Next$string('роботи батареї')),
						_Utils_Tuple2(
						'Статистика работы',
						$ChristophP$elm_i18next$I18Next$string('Статистика работи')),
						_Utils_Tuple2(
						'Начальная емкость батареи',
						$ChristophP$elm_i18next$I18Next$string('Початкова ємкість батареї')),
						_Utils_Tuple2(
						'Начало эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Початок експлуатації')),
						_Utils_Tuple2(
						'Общее время эксплуатации',
						$ChristophP$elm_i18next$I18Next$string('Загальний час експлуатації')),
						_Utils_Tuple2(
						'Израсходовано энергии батареи',
						$ChristophP$elm_i18next$I18Next$string('Використано енергії батареї')),
						_Utils_Tuple2(
						'Замена батареи',
						$ChristophP$elm_i18next$I18Next$string('Заміна батареї')),
						_Utils_Tuple2(
						'Изменить начальную емкость',
						$ChristophP$elm_i18next$I18Next$string('Змінити початкову ємкість')),
						_Utils_Tuple2(
						'bat_replace_text',
						$ChristophP$elm_i18next$I18Next$string('Заміна батареї повинна виконуватись фахівцем. Використовуйте олригінальну батарею SAFT LSH 14, 5800мАг. Якщо ви встановлюєте інший тип батареї, вкажіть її початкову ємкість (мАг).')),
						_Utils_Tuple2(
						'Укажите начальную емкость батареи (мАч)',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть початкову ємкість батареї (мАг)')),
						_Utils_Tuple2(
						'bat_ch_capacity',
						$ChristophP$elm_i18next$I18Next$string('Якщо у Вас встановлений інший тип батареї, вкажіть її початкову ємкість (мАг).')),
						_Utils_Tuple2(
						'Предупреждение!',
						$ChristophP$elm_i18next$I18Next$string('Попередження!')),
						_Utils_Tuple2(
						'warning_custom',
						$ChristophP$elm_i18next$I18Next$string('Необдумані зміни в налаштуваннях можуть призвести до повної непрацездатності Фенікса. Змінюйте параметри, якщо ви повністю впевнені у своїх діях.')),
						_Utils_Tuple2(
						'error_custom',
						$ChristophP$elm_i18next$I18Next$string('Помилка завантаження або дані від Фенікса ще не отримані.')),
						_Utils_Tuple2(
						'Отменить',
						$ChristophP$elm_i18next$I18Next$string('Відмінити')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Показати')),
						_Utils_Tuple2(
						'изменения',
						$ChristophP$elm_i18next$I18Next$string('зміни')),
						_Utils_Tuple2(
						'Хорошо',
						$ChristophP$elm_i18next$I18Next$string('Прийняти')),
						_Utils_Tuple2(
						'Максимальное время работы в режиме Поиск - ',
						$ChristophP$elm_i18next$I18Next$string('Максимальний час роботи в режимі ПОШУК - ')),
						_Utils_Tuple2(
						'7 суток',
						$ChristophP$elm_i18next$I18Next$string('7 діб')),
						_Utils_Tuple2(
						'24 часа',
						$ChristophP$elm_i18next$I18Next$string('24 години')),
						_Utils_Tuple2(
						'6 часов',
						$ChristophP$elm_i18next$I18Next$string('6 годин')),
						_Utils_Tuple2(
						'Рекомендуется для охраны автомобиля на длительной стоянке.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендується для охорони автомобіля на тривалій стоянці.')),
						_Utils_Tuple2(
						'Рекомендуется для наиболее вероятного обнаружения угнанного автомобиля.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендується для найбільш ймовірного виявлення викраденого автомобіля.')),
						_Utils_Tuple2(
						'Рекомендуется для максимальной экономии энергии батареи питания.',
						$ChristophP$elm_i18next$I18Next$string('Рекомендується для максимальної економії енергії батареї живлення.')),
						_Utils_Tuple2(
						'Следующие параметры будут изменены:',
						$ChristophP$elm_i18next$I18Next$string('Наступні параметри будуть змінені:')),
						_Utils_Tuple2(
						'Номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Номер телефону')),
						_Utils_Tuple2(
						'Введите номер телефона',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть номер телефону')),
						_Utils_Tuple2(
						'Определено автоматически',
						$ChristophP$elm_i18next$I18Next$string('Визначено автоматично')),
						_Utils_Tuple2(
						'Установлен пользователем',
						$ChristophP$elm_i18next$I18Next$string('Встановлено користувачем'))
					]))),
			_Utils_Tuple2(
			'control',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Текущий режим',
						$ChristophP$elm_i18next$I18Next$string('Поточний режим')),
						_Utils_Tuple2(
						'Включить режим Поиск',
						$ChristophP$elm_i18next$I18Next$string('Увімкнути режим ПОШУК')),
						_Utils_Tuple2(
						'Связь',
						$ChristophP$elm_i18next$I18Next$string('Зв\'язок')),
						_Utils_Tuple2(
						'Последний сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Останній сеанс зв\'язку:')),
						_Utils_Tuple2(
						'Следующий сеанс связи:',
						$ChristophP$elm_i18next$I18Next$string('Наступний сеанс зв\'язку:')),
						_Utils_Tuple2(
						'Положение',
						$ChristophP$elm_i18next$I18Next$string('Місцезнаходження')),
						_Utils_Tuple2(
						'Положение определено:',
						$ChristophP$elm_i18next$I18Next$string('Місцезнаходження визначено:')),
						_Utils_Tuple2(
						'Показать',
						$ChristophP$elm_i18next$I18Next$string('Показати')),
						_Utils_Tuple2(
						'Обновить',
						$ChristophP$elm_i18next$I18Next$string('Обновити')),
						_Utils_Tuple2(
						'Предполагаемое время работы батареи',
						$ChristophP$elm_i18next$I18Next$string('Передбачуваний час роботи батареї')),
						_Utils_Tuple2(
						'Режим',
						$ChristophP$elm_i18next$I18Next$string('Режим')),
						_Utils_Tuple2(
						'SIM-карта',
						$ChristophP$elm_i18next$I18Next$string('SIM-карта')),
						_Utils_Tuple2(
						'Баланс:',
						$ChristophP$elm_i18next$I18Next$string('Баланс:')),
						_Utils_Tuple2(
						'Пополнить счет',
						$ChristophP$elm_i18next$I18Next$string('Поповнити рахунок')),
						_Utils_Tuple2(
						'Заблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Заблокувати двигун')),
						_Utils_Tuple2(
						'Данные о состоянии еще не получены',
						$ChristophP$elm_i18next$I18Next$string('Дані про стан ще не отримані')),
						_Utils_Tuple2(
						'идет определение...',
						$ChristophP$elm_i18next$I18Next$string('йде визначення...')),
						_Utils_Tuple2(
						'Феникс выключен.',
						$ChristophP$elm_i18next$I18Next$string('Фенікс вимкнено.')),
						_Utils_Tuple2(
						'Идет определение местоположения...',
						$ChristophP$elm_i18next$I18Next$string('Йде визначення місцезнаходження...')),
						_Utils_Tuple2(
						'Поиск',
						$ChristophP$elm_i18next$I18Next$string('ПОШУК')),
						_Utils_Tuple2(
						'Ожидание',
						$ChristophP$elm_i18next$I18Next$string('ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Номер телефона был скопирован',
						$ChristophP$elm_i18next$I18Next$string('Номер телефону було скопійовано')),
						_Utils_Tuple2(
						'Переход в режим',
						$ChristophP$elm_i18next$I18Next$string('Перехід в режим')),
						_Utils_Tuple2(
						'Продлить режим',
						$ChristophP$elm_i18next$I18Next$string('Продовжити режим')),
						_Utils_Tuple2(
						'Продлить работу в режиме',
						$ChristophP$elm_i18next$I18Next$string('Продовжити роботу в режимі')),
						_Utils_Tuple2(
						'Укажите на какое время вы хотите продлить работу',
						$ChristophP$elm_i18next$I18Next$string('Вкажіть на який час ви бажаєте продовжити роботу')),
						_Utils_Tuple2(
						'в режиме',
						$ChristophP$elm_i18next$I18Next$string('в режимі')),
						_Utils_Tuple2(
						'На 4 часа',
						$ChristophP$elm_i18next$I18Next$string('На 4 години')),
						_Utils_Tuple2(
						'На сутки',
						$ChristophP$elm_i18next$I18Next$string('На добу')),
						_Utils_Tuple2(
						'Навсегда',
						$ChristophP$elm_i18next$I18Next$string('Назавжди')),
						_Utils_Tuple2(
						'На ч',
						$ChristophP$elm_i18next$I18Next$string('На {{h}} ч')),
						_Utils_Tuple2(
						'Включить режим',
						$ChristophP$elm_i18next$I18Next$string('Увімкнути режим')),
						_Utils_Tuple2(
						'Смена Режима',
						$ChristophP$elm_i18next$I18Next$string('Зміна Режиму')),
						_Utils_Tuple2(
						'change_state_dialog',
						$ChristophP$elm_i18next$I18Next$string('{{date}}, при наступному сеансі зв\'язку, Фенікс буде переведено в режим {{state}}')),
						_Utils_Tuple2(
						'Информация будет доступна после выхода Феникса на связь.',
						$ChristophP$elm_i18next$I18Next$string('Інформація буде доступна після виходу Фенікса на зв\'язок')),
						_Utils_Tuple2(
						'неизвестно',
						$ChristophP$elm_i18next$I18Next$string('невідомо')),
						_Utils_Tuple2(
						'Положение неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Місцезнаходження невідоме')),
						_Utils_Tuple2(
						'Разблокировать двигатель',
						$ChristophP$elm_i18next$I18Next$string('Розблокувати двигун')),
						_Utils_Tuple2(
						'wait_state',
						$ChristophP$elm_i18next$I18Next$string('{{datetime}}, при наступному сеансі зв\'язку,')),
						_Utils_Tuple2(
						'будет определено текущее местоположение',
						$ChristophP$elm_i18next$I18Next$string('буде визначене фактичне місцезнаходження')),
						_Utils_Tuple2(
						'будет продлена работа Феникса в режиме Поиск',
						$ChristophP$elm_i18next$I18Next$string('буде продовжена робота Фенікса в режимі ПОШУК')),
						_Utils_Tuple2(
						'будет запущена отложенная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('буде запущена відстрочене блокування двигуна')),
						_Utils_Tuple2(
						'будет запущена интеллектуальная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('буде запущена інтелектуальне блокування двигуна')),
						_Utils_Tuple2(
						'двигатель будет разблокирован',
						$ChristophP$elm_i18next$I18Next$string('двигун буде розблоковано')),
						_Utils_Tuple2(
						'Феникс будет выключен',
						$ChristophP$elm_i18next$I18Next$string('Фенікс буде вимкнено')),
						_Utils_Tuple2(
						'блокировка будет сброшена',
						$ChristophP$elm_i18next$I18Next$string('блокування буде відмінено')),
						_Utils_Tuple2(
						'Феникс будет переведён в режим',
						$ChristophP$elm_i18next$I18Next$string('Фенікс буде переведено в режим')),
						_Utils_Tuple2(
						'Внимание',
						$ChristophP$elm_i18next$I18Next$string('Увага')),
						_Utils_Tuple2(
						'Блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('Блокування двигуна')),
						_Utils_Tuple2(
						'Интеллектуальная блокировка',
						$ChristophP$elm_i18next$I18Next$string('Інтелектуальне блокування')),
						_Utils_Tuple2(
						'block_smart_text',
						$ChristophP$elm_i18next$I18Next$string('Буде запущена інтелектуальне блокування двигуна. Фенікс дасть можливість автомобілю безперешкодно покинути місце стоянки, визначить його координати і при першій же зупинці автомобіля - заблокує двигун.')),
						_Utils_Tuple2(
						'block_smart_comment',
						$ChristophP$elm_i18next$I18Next$string('Рекомендується у випадках, якщо автомобіль може знаходитись в підземному гаражі або в спеціальному «відстійнику», де визначення координат може бути неможливим. У випадку блокування двигуна, автомобіль не зможе покинути місце стоянки соїм ходом, що насторожить викрадачіві приведе до виявлення «несправності» і виявлення Фенікса')),
						_Utils_Tuple2(
						'block_lazy_text',
						$ChristophP$elm_i18next$I18Next$string('буде запущена відстрочене блокування двигуна. Якщо автомобіль знаходиться у русі - Фенікс заблокує двигун при його зупинці, якщо автомобіль не рцхається, Фенікс заблокує двигун негайно.')),
						_Utils_Tuple2(
						'block_lazy_comment',
						$ChristophP$elm_i18next$I18Next$string('Рекомендується у випадках, якщо автомобіль точно не встиг доїхати до місця «відстою» або якщо автомобіль знаходиться у прямій видимості.')),
						_Utils_Tuple2(
						'Заблокировать',
						$ChristophP$elm_i18next$I18Next$string('Заблокувати')),
						_Utils_Tuple2(
						'Вы сможете нажать эту кнопку после того как Феникc исполнит команды которые ждут выполнения',
						$ChristophP$elm_i18next$I18Next$string('Ви зможете натиснути цю кнопку після того, як Фенікс виконає усі команди')),
						_Utils_Tuple2(
						'Для включения - откройте крышку Феникса и нажмите кнопку.',
						$ChristophP$elm_i18next$I18Next$string('+++Для включения - откройте крышку Феникса и нажмите кнопку.')),
						_Utils_Tuple2(
						'Это может занять до 15 минут.',
						$ChristophP$elm_i18next$I18Next$string('+++Это может занять до 15 минут.'))
					]))),
			_Utils_Tuple2(
			'params',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'accel.count',
						$ChristophP$elm_i18next$I18Next$string('Тривалість імпульсу акселерометра, ms')),
						_Utils_Tuple2(
						'accel.deb',
						$ChristophP$elm_i18next$I18Next$string('Програмний антибрус, число повторів (2-6)')),
						_Utils_Tuple2(
						'accel.lvl',
						$ChristophP$elm_i18next$I18Next$string('Чутливість акселерометра, mg')),
						_Utils_Tuple2(
						'accel.time',
						$ChristophP$elm_i18next$I18Next$string('Час заспокоєння акселерметра, секунди (10-300)')),
						_Utils_Tuple2(
						'adc.fix.du',
						$ChristophP$elm_i18next$I18Next$string('Поріг зміни напруги резервного живлення, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.dub',
						$ChristophP$elm_i18next$I18Next$string('Поріг зміни напруги основного живлення, V (?-?)')),
						_Utils_Tuple2(
						'adc.fix.umax',
						$ChristophP$elm_i18next$I18Next$string('Поріг максимально допустимого живлення, /29.528, V (350-1200)')),
						_Utils_Tuple2(
						'adc.in.1',
						$ChristophP$elm_i18next$I18Next$string('Логічний поріг напруги входу 1, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.in.2',
						$ChristophP$elm_i18next$I18Next$string('Логічний поріг напруги входу 2, /?, V (?-?)')),
						_Utils_Tuple2(
						'adc.photo',
						$ChristophP$elm_i18next$I18Next$string('Чутливість фотодатчика, (100-2000; 2000-max)')),
						_Utils_Tuple2(
						'adc.photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Час увімкнення фотоохорони, сек (1-32000)')),
						_Utils_Tuple2(
						'photo.delay',
						$ChristophP$elm_i18next$I18Next$string('Час увімкнення фотоохорони, сек (1-32000)')),
						_Utils_Tuple2(
						'adc.u.off',
						$ChristophP$elm_i18next$I18Next$string('Поріг вимкнення основного живлення, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'adc.u.on',
						$ChristophP$elm_i18next$I18Next$string('Поріг увімкнення основного живлення, /29.528, V (100-1000)')),
						_Utils_Tuple2(
						'akkum.block.vbat',
						$ChristophP$elm_i18next$I18Next$string('-- додати опис ---')),
						_Utils_Tuple2(
						'akkum.block.vdd',
						$ChristophP$elm_i18next$I18Next$string('-- додати опис ---')),
						_Utils_Tuple2(
						'akkum.charge.0',
						$ChristophP$elm_i18next$I18Next$string('Індикація повного розряду батареї, /310.29, V (1050-1150)')),
						_Utils_Tuple2(
						'akkum.charge.30',
						$ChristophP$elm_i18next$I18Next$string('Індикація заряду батареї 30%, /310.29, V (1100-1200)')),
						_Utils_Tuple2(
						'akkum.charge.60',
						$ChristophP$elm_i18next$I18Next$string('Індикація заряду батареї 60%, /310.29, V (1150-1250)')),
						_Utils_Tuple2(
						'akkum.charge.temp',
						$ChristophP$elm_i18next$I18Next$string('Максимальна температура батареї, /?, градусов (?-?)')),
						_Utils_Tuple2(
						'akkum.i.0',
						$ChristophP$elm_i18next$I18Next$string('?Початковий струм заряду батареї, х 20.408 mA (1-10)')),
						_Utils_Tuple2(
						'akkum.i.1',
						$ChristophP$elm_i18next$I18Next$string('?Максимальний струм заряду батареї, х 20.408 mA (10-100)')),
						_Utils_Tuple2(
						'akkum.i.charge',
						$ChristophP$elm_i18next$I18Next$string('-- додати опис ---')),
						_Utils_Tuple2(
						'akkum.u.0',
						$ChristophP$elm_i18next$I18Next$string('?Мінімальна напруга для відновлення прошивки, /310.29, V (1100-1300)')),
						_Utils_Tuple2(
						'sleep',
						$ChristophP$elm_i18next$I18Next$string('Час сну в режимі ОЧІКУВАННЯ та ПОШУК, мин')),
						_Utils_Tuple2(
						'adc.vi.fix',
						$ChristophP$elm_i18next$I18Next$string('Корекція показників резервного живлення')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('Телефон адміністратора.')),
						_Utils_Tuple2(
						'alarm.balance',
						$ChristophP$elm_i18next$I18Next$string('SMS про критичний баланс')),
						_Utils_Tuple2(
						'alarm.case',
						$ChristophP$elm_i18next$I18Next$string('SMS про відкриття корпусу')),
						_Utils_Tuple2(
						'alarm.delay',
						$ChristophP$elm_i18next$I18Next$string('SMS про увімкнення чергового режиму')),
						_Utils_Tuple2(
						'alarm.low',
						$ChristophP$elm_i18next$I18Next$string('SMS про низький рівень ємкості батареї')),
						_Utils_Tuple2(
						'alarm.error',
						$ChristophP$elm_i18next$I18Next$string('SMS про неможливість визначення координат')),
						_Utils_Tuple2(
						'alarm.gps',
						$ChristophP$elm_i18next$I18Next$string('SMS про зміну координат')),
						_Utils_Tuple2(
						'alarm.mode',
						$ChristophP$elm_i18next$I18Next$string('SMS про зміну режиму')),
						_Utils_Tuple2(
						'alarm.on',
						$ChristophP$elm_i18next$I18Next$string('SMS про увімкнення Феникса')),
						_Utils_Tuple2(
						'alarm.off',
						$ChristophP$elm_i18next$I18Next$string('SMS про вимкнення Феникса')),
						_Utils_Tuple2(
						'alarm.stealth',
						$ChristophP$elm_i18next$I18Next$string('SMS про активацію режиму Stealth')),
						_Utils_Tuple2(
						'admin',
						$ChristophP$elm_i18next$I18Next$string('Номер телефона адміністратора')),
						_Utils_Tuple2(
						'alarm1',
						$ChristophP$elm_i18next$I18Next$string('Перший номер телефону для відправки SMS')),
						_Utils_Tuple2(
						'alarm2',
						$ChristophP$elm_i18next$I18Next$string('Другий номер телефону для відправки SMS')),
						_Utils_Tuple2(
						'alarm3',
						$ChristophP$elm_i18next$I18Next$string('Третій номер телефону для відправки SMS')),
						_Utils_Tuple2(
						'alarm4',
						$ChristophP$elm_i18next$I18Next$string('Четвертий номер телефону для відправки SMS')),
						_Utils_Tuple2(
						'auto.sleep',
						$ChristophP$elm_i18next$I18Next$string('Автоматичне увімкнення режиму Очікування, хв')),
						_Utils_Tuple2(
						'balance.skip',
						$ChristophP$elm_i18next$I18Next$string('Пропуск цифир в звіті оператора про баланс')),
						_Utils_Tuple2(
						'balance.ussd',
						$ChristophP$elm_i18next$I18Next$string('USSD-запит залишку коштів на картці')),
						_Utils_Tuple2(
						'config.send',
						$ChristophP$elm_i18next$I18Next$string('Період сеансів зв\'язку в режимі Конфігуратор, хв')),
						_Utils_Tuple2(
						'delay',
						$ChristophP$elm_i18next$I18Next$string('Затримка вимкнення GSM-модуля, в циклах зв\'язку')),
						_Utils_Tuple2(
						'distance',
						$ChristophP$elm_i18next$I18Next$string('Максимально допустиме відхилення координат, м')),
						_Utils_Tuple2(
						'gps.angle',
						$ChristophP$elm_i18next$I18Next$string('Мінімально реєструємий кут повороту, градусів')),
						_Utils_Tuple2(
						'gps.aoff',
						$ChristophP$elm_i18next$I18Next$string('Автоввідключення GPS для економії батареї, хв')),
						_Utils_Tuple2(
						'gps.delta',
						$ChristophP$elm_i18next$I18Next$string('Примусова реєстрація координат при переміщенні, м')),
						_Utils_Tuple2(
						'gps.fail',
						$ChristophP$elm_i18next$I18Next$string('Затримка перезапуску GPS-модуля при втраті супутників, хв')),
						_Utils_Tuple2(
						'gps.flush.move',
						$ChristophP$elm_i18next$I18Next$string('Період відправки даних на сервер при русі, сек')),
						_Utils_Tuple2(
						'gps.flush.stop',
						$ChristophP$elm_i18next$I18Next$string('Період відправки даних на сервер при стоянці, сек')),
						_Utils_Tuple2(
						'gps.flush',
						$ChristophP$elm_i18next$I18Next$string('Період відправки даних на сервер, сек')),
						_Utils_Tuple2(
						'gps.tf.move',
						$ChristophP$elm_i18next$I18Next$string('Примусова реєстрація координат при русі, сек')),
						_Utils_Tuple2(
						'gps.tf.stop',
						$ChristophP$elm_i18next$I18Next$string('Період реєстрації координат при стоянці, сек')),
						_Utils_Tuple2(
						'gps.tf',
						$ChristophP$elm_i18next$I18Next$string('Період реєстрації координат, сек')),
						_Utils_Tuple2(
						'gps.tf.stop.acc',
						$ChristophP$elm_i18next$I18Next$string('Період реєстрації координат при стоянці, сек')),
						_Utils_Tuple2(
						'gps.valid.delay',
						$ChristophP$elm_i18next$I18Next$string('Данні від GPS беруться не перші після fix, а пропускається вказана кількість')),
						_Utils_Tuple2(
						'gps.vignacc',
						$ChristophP$elm_i18next$I18Next$string('Швидкість примусової реєстрації руху (ігнорирується акселерометр) × 0,01852 км/год')),
						_Utils_Tuple2(
						'gps.vstart',
						$ChristophP$elm_i18next$I18Next$string('Швидкість реєстрації початку руху × 0,01852 км/год')),
						_Utils_Tuple2(
						'gps.vstop',
						$ChristophP$elm_i18next$I18Next$string('Швидкість реєстрації зупинки об\'єкта × 0,01852 км/год')),
						_Utils_Tuple2(
						'gps.data.1',
						$ChristophP$elm_i18next$I18Next$string('Призначення додаткового поля данних GPS')),
						_Utils_Tuple2(
						'gps.wait',
						$ChristophP$elm_i18next$I18Next$string('Час очікування визначення супутників, хв')),
						_Utils_Tuple2(
						'gsm.apn',
						$ChristophP$elm_i18next$I18Next$string('Програмована точка входу в Інтернет - APN')),
						_Utils_Tuple2(
						'gsm.balance.period',
						$ChristophP$elm_i18next$I18Next$string('Період контролю баланса, год')),
						_Utils_Tuple2(
						'gsm.flags',
						$ChristophP$elm_i18next$I18Next$string('Робота в роумінгу, в тому числі, в національному')),
						_Utils_Tuple2(
						'gsm.lagtime',
						$ChristophP$elm_i18next$I18Next$string('Період перевіркии GSM-модуля на рахунок зависання, хв')),
						_Utils_Tuple2(
						'gsm.predelay',
						$ChristophP$elm_i18next$I18Next$string('Час перевірки GSM-ретрансляторів і очікування SMS, хв')),
						_Utils_Tuple2(
						'gsm.user',
						$ChristophP$elm_i18next$I18Next$string('Ім\'я користувача для GPRS-доступу')),
						_Utils_Tuple2(
						'gsm.pwd',
						$ChristophP$elm_i18next$I18Next$string('Пароль для GPRS-доступу')),
						_Utils_Tuple2(
						'limit',
						$ChristophP$elm_i18next$I18Next$string('Мінімально допустимий баланс SIM-карти')),
						_Utils_Tuple2(
						'link.delay',
						$ChristophP$elm_i18next$I18Next$string('Час утримання кнопки для прив\'язки, сек')),
						_Utils_Tuple2(
						'mode',
						$ChristophP$elm_i18next$I18Next$string('Режим роботи')),
						_Utils_Tuple2(
						'photo',
						$ChristophP$elm_i18next$I18Next$string('Фотодатчик')),
						_Utils_Tuple2(
						'secur.code',
						$ChristophP$elm_i18next$I18Next$string('Код безпеки для SMS-команд')),
						_Utils_Tuple2(
						'sms.confirm',
						$ChristophP$elm_i18next$I18Next$string('Сповіщення про виконання команди зміни режиму')),
						_Utils_Tuple2(
						'timezone',
						$ChristophP$elm_i18next$I18Next$string('Різниця між поточним і світовим часовими поясами, год')),
						_Utils_Tuple2(
						'vin.low',
						$ChristophP$elm_i18next$I18Next$string('Напруга для сповіщення про розряд батареї, V')),
						_Utils_Tuple2(
						'vin.off',
						$ChristophP$elm_i18next$I18Next$string('Напруга автоматичного вимкнення Фенікса, V'))
					]))),
			_Utils_Tuple2(
			'map',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Скрыть трек',
						$ChristophP$elm_i18next$I18Next$string('Сховати трек')),
						_Utils_Tuple2(
						'Закрыть',
						$ChristophP$elm_i18next$I18Next$string('Закрити')),
						_Utils_Tuple2(
						'Последнее положение определено ',
						$ChristophP$elm_i18next$I18Next$string('Останнє становище визначено '))
					]))),
			_Utils_Tuple2(
			'logs',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Не загружено. Нажимте кнопку Обновить.',
						$ChristophP$elm_i18next$I18Next$string('Не завантажене. Натисніть кнопку Оновити.')),
						_Utils_Tuple2(
						'Феникс выключен',
						$ChristophP$elm_i18next$I18Next$string('Фенікс вимкнено')),
						_Utils_Tuple2(
						'Корпус Феникса взят под охрану',
						$ChristophP$elm_i18next$I18Next$string('Корпус Фенiкса взято під охорону')),
						_Utils_Tuple2(
						'Феникс FX-400 включён. Режим: Поиск',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX-400 увімкнено. Режим: ПОШУК')),
						_Utils_Tuple2(
						'Феникс FX400 включён. Режим: ПОИСК',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX400 увімкнено. Режим: ПОШУК')),
						_Utils_Tuple2(
						'Феникс FX400 включён. Режим: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('Фенікс FX400 увімкнено. Режим: ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Режим изменен на: Ожидание',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: Очікування')),
						_Utils_Tuple2(
						'Автоматический переход в режим SLEEP',
						$ChristophP$elm_i18next$I18Next$string('Автоматичний перехід у режим ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Нет сигнала GPS. Невозможно определить местоположение',
						$ChristophP$elm_i18next$I18Next$string('Немає сигналу GPS. Неможливо визначити місцезнаходження')),
						_Utils_Tuple2(
						'Трекинг остановлен',
						$ChristophP$elm_i18next$I18Next$string('Трекінг зупинено')),
						_Utils_Tuple2(
						'Режим изменен на: Поиск',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: ПОШУК')),
						_Utils_Tuple2(
						'Получена команда на смену режима на Поиск через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Отримано команду на зміну режиму на ПОШУК через WEB-додаток')),
						_Utils_Tuple2(
						'Получена команда на смену режима на Ожидание через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Отримано команду на зміну режиму на ОЧІКУВАННЯ через WEB-додаток')),
						_Utils_Tuple2(
						'Двигатель разблокирован',
						$ChristophP$elm_i18next$I18Next$string('Двигун розблоковано')),
						_Utils_Tuple2(
						'Автоматическая смена режима на Поиск',
						$ChristophP$elm_i18next$I18Next$string('Автоматична зміна режиму на ПОШУК')),
						_Utils_Tuple2(
						'Запущена отложенная блокировка двигателя',
						$ChristophP$elm_i18next$I18Next$string('Запущено відкладене блокування двигуна')),
						_Utils_Tuple2(
						'Двигатель заблокирован',
						$ChristophP$elm_i18next$I18Next$string('Двигун заблокований')),
						_Utils_Tuple2(
						'Трекинг активирован',
						$ChristophP$elm_i18next$I18Next$string('Трекінг активовано')),
						_Utils_Tuple2(
						'Внимание! Открыт корпус Феникса',
						$ChristophP$elm_i18next$I18Next$string('Увага! Відкритий корпус Фенікса')),
						_Utils_Tuple2(
						'Работа режима ПОИСК продлена навсегда',
						$ChristophP$elm_i18next$I18Next$string('Робота режиму ПОШУК продовжено назавжди')),
						_Utils_Tuple2(
						'Автоматический переход в режим: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('Автоматичний перехід у режим: ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Нет сигнала GPS',
						$ChristophP$elm_i18next$I18Next$string('Немає сигналу GPS')),
						_Utils_Tuple2(
						'Режим изменен на: ПОИСК',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: ПОШУК')),
						_Utils_Tuple2(
						'Cигнал GPS восстановлен.',
						$ChristophP$elm_i18next$I18Next$string('Сигнал GPS відновлено.')),
						_Utils_Tuple2(
						'Режим изменен на: ОЖИДАНИЕ',
						$ChristophP$elm_i18next$I18Next$string('Режим змінено на: ОЧІКУВАННЯ')),
						_Utils_Tuple2(
						'Получена команда на смену режима на ОЖИДАНИЕ через WEB-приложение',
						$ChristophP$elm_i18next$I18Next$string('Отримана команда на зміну режиму на ОЧІКУВАННЯ через WEB-додаток')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'dates',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						' год',
						$ChristophP$elm_i18next$I18Next$string(' рік')),
						_Utils_Tuple2(
						' года',
						$ChristophP$elm_i18next$I18Next$string(' роки')),
						_Utils_Tuple2(
						' лет',
						$ChristophP$elm_i18next$I18Next$string(' років')),
						_Utils_Tuple2(
						' месяц',
						$ChristophP$elm_i18next$I18Next$string(' місяць')),
						_Utils_Tuple2(
						' месяца',
						$ChristophP$elm_i18next$I18Next$string(' місяці')),
						_Utils_Tuple2(
						' месяцев',
						$ChristophP$elm_i18next$I18Next$string(' місяців')),
						_Utils_Tuple2(
						' день',
						$ChristophP$elm_i18next$I18Next$string(' день')),
						_Utils_Tuple2(
						' дня',
						$ChristophP$elm_i18next$I18Next$string(' дні')),
						_Utils_Tuple2(
						' дней',
						$ChristophP$elm_i18next$I18Next$string(' днів')),
						_Utils_Tuple2(
						' час',
						$ChristophP$elm_i18next$I18Next$string(' година')),
						_Utils_Tuple2(
						' часа',
						$ChristophP$elm_i18next$I18Next$string(' години')),
						_Utils_Tuple2(
						' часов',
						$ChristophP$elm_i18next$I18Next$string(' годин'))
					]))),
			_Utils_Tuple2(
			'status',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Авто в движении',
						$ChristophP$elm_i18next$I18Next$string('Авто в русі')),
						_Utils_Tuple2(
						'Авто в спящем состоянии',
						$ChristophP$elm_i18next$I18Next$string('Авто в сплячому стані')),
						_Utils_Tuple2(
						'Состояние еще неизвестно',
						$ChristophP$elm_i18next$I18Next$string('Стан ще невідомий')),
						_Utils_Tuple2(
						'',
						$ChristophP$elm_i18next$I18Next$string(''))
					]))),
			_Utils_Tuple2(
			'errors',
			$ChristophP$elm_i18next$I18Next$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Код неверный, уже использован или вышло время действия кода.',
						$ChristophP$elm_i18next$I18Next$string('Код хибний, вже використований або закінчився час дії коду.')),
						_Utils_Tuple2(
						'Неверное имя пользователя или пароль.',
						$ChristophP$elm_i18next$I18Next$string('Хибне ім\'я користувача або пароль.')),
						_Utils_Tuple2(
						'Ошибка! Система не существует или у вас недостаточно прав для просмотра.',
						$ChristophP$elm_i18next$I18Next$string('Помилка! Система не існує або у вас недостатньо прав для перегляду.'))
					])))
		]));
var $author$project$I18N$translations = function (lang) {
	var _v0 = A2(
		$elm$core$String$left,
		2,
		$elm$core$String$toUpper(lang));
	switch (_v0) {
		case 'UA':
			return $author$project$I18N$Ua$translations;
		case 'EN':
			return $author$project$I18N$En$translations;
		case 'RU':
			return $author$project$I18N$Ru$translations;
		default:
			return $author$project$I18N$Ua$translations;
	}
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$AppState$initModel = F3(
	function (lang, themeName, scaleName) {
		return {
			cb: lang,
			bm: $elm$time$Time$millisToPosix(0),
			cI: scaleName,
			aX: $ChristophP$elm_i18next$I18Next$t(
				$author$project$I18N$translations(lang)),
			cW: themeName,
			bC: $elm$time$Time$utc,
			cY: A2(
				$ChristophP$elm_i18next$I18Next$tr,
				$author$project$I18N$translations(lang),
				$ChristophP$elm_i18next$I18Next$Curly)
		};
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Types$Connected = 1;
var $author$project$Components$UI$Menu$HidePopups = {$: 9};
var $author$project$Types$MenuMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$API$authRequest = function (token) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'cmd',
				$elm$json$Json$Encode$string('login')),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(token))
			]));
};
var $author$project$Types$GlobalMapMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Types$OnPageMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Types$SystemConfigMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$SystemLogsMsg = function (a) {
	return {$: 4, a: a};
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$computeViewForPage = F2(
	function (page, model) {
		switch (page.$) {
			case 12:
				var _v1 = $author$project$Page$LinkSys$init;
				var init_model = _v1.a;
				var init_cmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aJ: init_model}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var s = page.a;
				var _v2 = $author$project$Page$System$Config$init(
					$elm$core$Maybe$Just(s));
				var init_model = _v2.a;
				var init_cmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aW: init_model}),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$Types$SystemConfigMsg, $author$project$Types$OnPageMsg),
						init_cmd));
			case 10:
				var s = page.a;
				var _v3 = $author$project$Page$System$Logs$init(
					$elm$core$Maybe$Just(s));
				var init_model = _v3.a;
				var init_cmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{as: init_model}),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$Types$SystemLogsMsg, $author$project$Types$OnPageMsg),
						init_cmd));
			case 6:
				var s = page.a;
				var mlat = page.b;
				var mlng = page.c;
				var mday = page.d;
				var _v4 = A4(
					$author$project$Page$GlobalMap$init,
					$elm$core$Maybe$Just(s),
					mlat,
					mlng,
					mday);
				var init_model = _v4.a;
				var init_cmd = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: init_model}),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$Types$GlobalMapMsg, $author$project$Types$OnPageMsg),
						init_cmd));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$API$Error$errorMessageString = function (_v0) {
	var resource = _v0.cD;
	var code = _v0.bO;
	var _v1 = _Utils_Tuple2(resource, code);
	_v1$2:
	while (true) {
		if (_v1.b === 'invalid_credentials') {
			switch (_v1.a) {
				case 'link_code':
					return $elm$core$Maybe$Just('errors.Код неверный, уже использован или вышло время действия кода.');
				case 'token':
					return $elm$core$Maybe$Just('errors.Неверное имя пользователя или пароль.');
				default:
					break _v1$2;
			}
		} else {
			break _v1$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$UI$Menu$hideMobMenu = function (model) {
	return _Utils_update(
		model,
		{C: true});
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {N: frag, dV: params, J: unvisited, eu: value, Q: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.J;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.eu);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.eu);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.cq),
					$elm$url$Url$Parser$prepareQuery(url.cx),
					url.b_,
					$elm$core$Basics$identity)));
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$API$Document = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$API$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$API$Ping = function (a) {
	return {$: 0, a: a};
};
var $author$project$API$Token = function (a) {
	return {$: 1, a: a};
};
var $author$project$API$AccountDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$API$SystemDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$API$SystemDocumentDynamic = function (a) {
	return {$: 2, a: a};
};
var $author$project$API$SystemHoursDocument = function (a) {
	return {$: 5, a: a};
};
var $author$project$API$SystemLogsDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$API$SystemParamsDocument = function (a) {
	return {$: 4, a: a};
};
var $author$project$API$SystemTrackDocument = function (a) {
	return {$: 6, a: a};
};
var $author$project$API$Account$AccountDocumentInfo = F2(
	function (realname, systems) {
		return {d$: realname, ef: systems};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$API$Account$accountDocumentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'systems',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'realname',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$API$Account$AccountDocumentInfo)));
var $author$project$API$System$Dynamic = function (lastping) {
	return function (method) {
		return function (next) {
			return function (latitude) {
				return function (longitude) {
					return function (dt) {
						return function (vin) {
							return function (vout) {
								return function (state) {
									return function (available) {
										return function (waitState) {
											return function (autosleep) {
												return {c7: autosleep, c8: available, ae: dt, dH: lastping, dI: latitude, dJ: longitude, dL: method, dO: next, cQ: state, ev: vin, ew: vout, ex: waitState};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$Dt$Dt = $elm$core$Basics$identity;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Types$Dt$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (t) {
		return $elm$json$Json$Decode$succeed(t);
	},
	$elm$json$Json$Decode$int);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Types$Dt$Offset = $elm$core$Basics$identity;
var $author$project$Types$Dt$offsetDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (t) {
		return $elm$json$Json$Decode$succeed(t);
	},
	$elm$json$Json$Decode$int);
var $author$project$API$System$Beacon = {$: 3};
var $author$project$API$System$CLock = {$: 10};
var $author$project$API$System$Config = {$: 6};
var $author$project$API$System$Hidden = {$: 4};
var $author$project$API$System$Lock = {$: 9};
var $author$project$API$System$Locked = {$: 2};
var $author$project$API$System$Off = {$: 5};
var $author$project$API$System$Point = {$: 7};
var $author$project$API$System$ProlongSleep = function (a) {
	return {$: 12, a: a};
};
var $author$project$API$System$SLock = {$: 8};
var $author$project$API$System$Sleep = {$: 1};
var $author$project$API$System$Tracking = {$: 0};
var $author$project$API$System$Unknown = function (a) {
	return {$: 13, a: a};
};
var $author$project$API$System$Unlock = {$: 11};
var $author$project$API$System$stateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (t) {
		switch (t) {
			case 'tracking':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Tracking);
			case 'sleep':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Sleep);
			case 'locked':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Locked);
			case 'beacon':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Beacon);
			case 'hidden':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Hidden);
			case 'off':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Off);
			case 'config':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Config);
			case 'point':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Point);
			case 'slock':
				return $elm$json$Json$Decode$succeed($author$project$API$System$SLock);
			case 'lock':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Lock);
			case 'unlock':
				return $elm$json$Json$Decode$succeed($author$project$API$System$Unlock);
			case 'clock':
				return $elm$json$Json$Decode$succeed($author$project$API$System$CLock);
			case 'prolong_4':
				return $elm$json$Json$Decode$succeed(
					$author$project$API$System$ProlongSleep(4));
			case 'prolong_24':
				return $elm$json$Json$Decode$succeed(
					$author$project$API$System$ProlongSleep(24));
			case 'prolong_100':
				return $elm$json$Json$Decode$succeed(
					$author$project$API$System$ProlongSleep(100));
			default:
				var other = t;
				return $elm$json$Json$Decode$succeed(
					$author$project$API$System$Unknown(other));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$API$System$dynamicDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'autosleep',
	$elm$json$Json$Decode$maybe($author$project$Types$Dt$offsetDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'wait_state',
		$elm$json$Json$Decode$maybe($author$project$API$System$stateDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'available',
			$elm$json$Json$Decode$list($author$project$API$System$stateDecoder),
			_List_Nil,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'state',
				$elm$json$Json$Decode$maybe($author$project$API$System$stateDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'vout',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'vin',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'dt',
							$elm$json$Json$Decode$maybe($author$project$Types$Dt$decoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'longitude',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'latitude',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'next_session',
										$elm$json$Json$Decode$maybe($author$project$Types$Dt$offsetDecoder),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'method',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'lastping',
												$elm$json$Json$Decode$maybe($author$project$Types$Dt$decoder),
												$elm$core$Maybe$Nothing,
												$elm$json$Json$Decode$succeed($author$project$API$System$Dynamic)))))))))))));
var $author$project$API$System$SystemDocumentInfo = function (id) {
	return function (title) {
		return function (imei) {
			return function (phone) {
				return function (phoneManual) {
					return function (dynamic) {
						return function (balance) {
							return function (hwid) {
								return function (swid) {
									return function (battery) {
										return function (params) {
											return function (icon) {
												return function (executor) {
													return {c9: balance, da: battery, dk: dynamic, dm: executor, dx: hwid, dy: icon, b6: id, dz: imei, dV: params, dW: phone, dX: phoneManual, eb: swid, ej: title};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$API$System$Balance = F2(
	function (dt, value) {
		return {ae: dt, eu: value};
	});
var $author$project$API$System$balanceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'value',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dt',
		$author$project$Types$Dt$decoder,
		$elm$json$Json$Decode$succeed($author$project$API$System$Balance)));
var $author$project$API$System$Battery$Battery = F4(
	function (init_capacity, init_dt, last_update, counters) {
		return {df: counters, dD: init_capacity, dE: init_dt, dG: last_update};
	});
var $author$project$API$System$Battery$Counters = F5(
	function (gsm, gps, accel, gsm_on, sessions) {
		return {bF: accel, b1: gps, b2: gsm, b3: gsm_on, cM: sessions};
	});
var $author$project$API$System$Battery$counterDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'sessions',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'gsm_on',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'accel',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'gps',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'gsm',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$API$System$Battery$Counters))))));
var $author$project$API$System$Battery$batteryDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'counters',
	$author$project$API$System$Battery$counterDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'last_update',
		$author$project$Types$Dt$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'init_dt',
			$author$project$Types$Dt$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'init_capacity',
				$elm$json$Json$Decode$float,
				$elm$json$Json$Decode$succeed($author$project$API$System$Battery$Battery)))));
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$API$System$SystemParams = function (sleep) {
	return {d6: sleep};
};
var $author$project$API$System$systemParamsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'sleep',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$succeed($author$project$API$System$SystemParams));
var $author$project$API$System$systemDocumentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'executor',
	$elm$json$Json$Decode$bool,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'icon',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'params',
			$author$project$API$System$systemParamsDecoder,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'battery',
				$elm$json$Json$Decode$maybe($author$project$API$System$Battery$batteryDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'swid',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'hwid',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'balance',
							$elm$json$Json$Decode$maybe($author$project$API$System$balanceDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'dynamic',
								$elm$json$Json$Decode$maybe($author$project$API$System$dynamicDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'phone_manual',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'phone',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'imei',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'title',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'id',
													$elm$json$Json$Decode$string,
													$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentInfo))))))))))))));
var $author$project$API$System$SystemDocumentHour = F3(
	function (from, to, hours) {
		return {dt: from, dw: hours, ek: to};
	});
var $author$project$API$System$systemDocumentHourDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'hours',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'to',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'from',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentHour))));
var $author$project$API$System$SystemDocumentLog = F2(
	function (dt, text) {
		return {ae: dt, eg: text};
	});
var $author$project$API$System$systemDocumentLogDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'text',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dt',
		$author$project$Types$Dt$decoder,
		$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentLog)));
var $author$project$API$System$SystemDocumentParams = F3(
	function (id, data, queue) {
		return {dg: data, b6: id, d_: queue};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Page$System$Config$ParamDesc$disabled = function (name) {
	return !A2(
		$elm$core$List$member,
		name,
		_List_fromArray(
			['executor.id', 'executor.key', 'executor.dynamic', 'factory.reset', 'fwupdate.path', 'fwupdate.port', 'fwupdate.server', 'gsm.server', 'gsm.extra', 'sim900.init', 'gps.data.1', 'link.delay', 'off.save', 'off.state', 'alarm.delay', 'alarm.stealth', 'gsm.predelay', 'sms.confirm', 'accel.count', 'accel.time', 'gsm.predelay', 'gps.vignacc', 'gps.vstart', 'gps.vstop', 'photo.delay']));
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$API$System$sortParamList = function () {
	var ffilter = function (_v0) {
		var name = _v0.a;
		return $author$project$Page$System$Config$ParamDesc$disabled(name);
	};
	return A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$elm$json$Json$Decode$succeed,
			$elm$core$List$sortBy($elm$core$Tuple$first)),
		$elm$core$List$filter(ffilter));
}();
var $author$project$API$System$SystemDocumentParam = F3(
	function (type_, value, _default) {
		return {dh: _default, es: type_, eu: value};
	});
var $author$project$API$System$systemDocumentParamDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'default',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'value',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentParam))));
var $author$project$API$System$systemDocumentParamsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'queue',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	$elm$core$Dict$empty,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'data',
		A2(
			$elm$json$Json$Decode$andThen,
			$author$project$API$System$sortParamList,
			$elm$json$Json$Decode$keyValuePairs($author$project$API$System$systemDocumentParamDecoder)),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentParams))));
var $author$project$API$System$SystemDocumentTrack = F3(
	function (from, to, track) {
		return {dt: from, ek: to, eq: track};
	});
var $author$project$API$System$TrackPoint = F4(
	function (fsource, dt, lat, lng) {
		return {ae: dt, b$: fsource, cd: lat, cf: lng};
	});
var $author$project$API$System$decodeTrackPoint = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'longitude',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'latitude',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'dt',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'fsource',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$API$System$TrackPoint)))));
var $author$project$API$System$systemDocumentTrackDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'track',
	$elm$json$Json$Decode$list($author$project$API$System$decodeTrackPoint),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'to',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'from',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$API$System$SystemDocumentTrack))));
var $author$project$API$documentDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (c) {
		return A2(
			$elm$json$Json$Decode$field,
			'value',
			function () {
				switch (c) {
					case 'account':
						return A2($elm$json$Json$Decode$map, $author$project$API$AccountDocument, $author$project$API$Account$accountDocumentDecoder);
					case 'system':
						return A2($elm$json$Json$Decode$map, $author$project$API$SystemDocument, $author$project$API$System$systemDocumentDecoder);
					case 'system.dynamic':
						return A2($elm$json$Json$Decode$map, $author$project$API$SystemDocumentDynamic, $author$project$API$System$dynamicDecoder);
					case 'system_logs':
						return A2(
							$elm$json$Json$Decode$map,
							$author$project$API$SystemLogsDocument,
							$elm$json$Json$Decode$list($author$project$API$System$systemDocumentLogDecoder));
					case 'system_params':
						return A2($elm$json$Json$Decode$map, $author$project$API$SystemParamsDocument, $author$project$API$System$systemDocumentParamsDecoder);
					case 'system_hours':
						return A2($elm$json$Json$Decode$map, $author$project$API$SystemHoursDocument, $author$project$API$System$systemDocumentHourDecoder);
					case 'system_track':
						return A2($elm$json$Json$Decode$map, $author$project$API$SystemTrackDocument, $author$project$API$System$systemDocumentTrackDecoder);
					default:
						return $elm$json$Json$Decode$fail('unexpected document ' + c);
				}
			}());
	},
	A2($elm$json$Json$Decode$field, 'collection', $elm$json$Json$Decode$string));
var $author$project$API$Error$ApiError = F2(
	function (resource, code) {
		return {bO: code, cD: resource};
	});
var $author$project$API$Error$errorDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$API$Error$ApiError,
	A2($elm$json$Json$Decode$field, 'resource', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string));
var $author$project$API$PingInfo = function (timestamp) {
	return {ei: timestamp};
};
var $author$project$API$pingDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$API$PingInfo,
	A2($elm$json$Json$Decode$field, 'timestamp', $elm$json$Json$Decode$int));
var $author$project$API$TokenInfo = function (value) {
	return {eu: value};
};
var $author$project$API$tokenDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$API$TokenInfo,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
var $author$project$API$payloadDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (t) {
		switch (t) {
			case 'ping':
				return A2($elm$json$Json$Decode$map, $author$project$API$Ping, $author$project$API$pingDecoder);
			case 'error':
				return A2($elm$json$Json$Decode$map, $author$project$API$Error, $author$project$API$Error$errorDecoder);
			case 'token':
				return A2($elm$json$Json$Decode$map, $author$project$API$Token, $author$project$API$tokenDecoder);
			case 'document':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$API$Document,
					A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
					$author$project$API$documentDecoder);
			default:
				return $elm$json$Json$Decode$fail('unexpected message ' + t);
		}
	},
	A2($elm$json$Json$Decode$field, 'cmd', $elm$json$Json$Decode$string));
var $author$project$API$parsePayload = function (payload) {
	var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$API$payloadDecoder, payload);
	if (!_v0.$) {
		var content = _v0.a;
		return $elm$core$Maybe$Just(content);
	} else {
		var error = _v0.a;
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Route$Auth = {$: 1};
var $author$project$Page$Route$BouncePage = {$: 11};
var $author$project$Page$Route$Config = {$: 7};
var $author$project$Page$Route$GlobalMap = {$: 5};
var $author$project$Page$Route$LinkSys = {$: 12};
var $author$project$Page$Route$Login = {$: 2};
var $author$project$Page$Route$Properties = {$: 4};
var $author$project$Page$Route$SystemConfig = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Route$SystemInfo = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Route$SystemLogs = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Route$SystemOnMap = F4(
	function (a, b, c, d) {
		return {$: 6, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$Route$User = {$: 3};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.Q;
		var unvisited = _v0.J;
		var params = _v0.dV;
		var frag = _v0.N;
		var value = _v0.eu;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.Q;
			var unvisited = _v1.J;
			var params = _v1.dV;
			var frag = _v1.N;
			var value = _v1.eu;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.Q;
		var unvisited = _v1.J;
		var params = _v1.dV;
		var frag = _v1.N;
		var value = _v1.eu;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.Q;
		var unvisited = _v0.J;
		var params = _v0.dV;
		var frag = _v0.N;
		var value = _v0.eu;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.Q;
			var unvisited = _v0.J;
			var params = _v0.dV;
			var frag = _v0.N;
			var value = _v0.eu;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Page$Route$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Page$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$Home,
			$elm$url$Url$Parser$s('home')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$User,
			$elm$url$Url$Parser$s('user')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$Properties,
			$elm$url$Url$Parser$s('properties')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$GlobalMap,
			$elm$url$Url$Parser$s('map')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$SystemOnMap,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('map'),
				A2(
					$elm$url$Url$Parser$questionMark,
					A2(
						$elm$url$Url$Parser$questionMark,
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$string,
							$elm$url$Url$Parser$Query$string('lat')),
						$elm$url$Url$Parser$Query$string('lng')),
					$elm$url$Url$Parser$Query$string('day')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$Config,
			$elm$url$Url$Parser$s('config')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$SystemInfo,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('system'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$SystemConfig,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('system'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('config')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$SystemLogs,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('system'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('logs')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$Auth,
			$elm$url$Url$Parser$s('auth')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$BouncePage,
			$elm$url$Url$Parser$s('logout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Route$LinkSys,
			$elm$url$Url$Parser$s('linksys'))
		]));
var $author$project$Main$saveToken = _Platform_outgoingPort('saveToken', $elm$json$Json$Encode$string);
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.cw;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.b_,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.cx,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.cs,
					_Utils_ap(http, url.b5)),
				url.cq)));
};
var $author$project$AppState$updateNow = F2(
	function (time, appState) {
		return _Utils_update(
			appState,
			{bm: time});
	});
var $author$project$Types$HomeMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Page$PT_Full = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$Page$PUT_Public = function (a) {
	return {$: 1, a: a};
};
var $author$project$Msg$RemoveSystemFromList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var sid = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bq: sid, ar: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ar: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ar: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$Msg$RemoveSystemFromList(model.bq)));
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Components$UI$text = function (value) {
	return $elm$html$Html$text(value);
};
var $author$project$Components$UI$formHeader = function (text_title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('login-title')
			]),
		_List_fromArray(
			[
				$author$project$Components$UI$text(text_title)
			]));
};
var $author$project$Components$UI$formSubtitle = function (text_subtitle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('login-subtitle')
			]),
		_List_fromArray(
			[
				$author$project$Components$UI$text(text_subtitle)
			]));
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Components$UI$greenLink = F2(
	function (url_, txt_) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(url_),
					$elm$html$Html$Attributes$class('accaunt-link-green accaunt-link')
				]),
			_List_fromArray(
				[
					$author$project$Components$UI$text(txt_)
				]));
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Page$Home$List$addCard = function (_v0) {
	var t = _v0.aX;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fenix fenix-bg fenix-add')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-header')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-body')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fenix-add-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('list.Добавьте ещё один Феникс')),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								$elm$html$Html$text(
								t('list.в список наблюдения'))
							])),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/linksys'),
								$elm$html$Html$Attributes$class('btn btn-primary btn-lg btn-add')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('list.Добавить'))
							]))
					]))
			]));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$API$System$Battery$days_full = F2(
	function (appState, d) {
		var t = appState.aX;
		var sd = $elm$core$String$fromInt(d);
		return (!d) ? '' : ((d === 1) ? _Utils_ap(
			sd,
			t('dates. день')) : (((d === 2) || ((d === 3) || (d === 4))) ? _Utils_ap(
			sd,
			t('dates. дня')) : ((d > 30) ? ('3' + A2($author$project$API$System$Battery$days_full, appState, d - 30)) : ((d > 20) ? ('2' + A2($author$project$API$System$Battery$days_full, appState, d - 20)) : _Utils_ap(
			sd,
			t('dates. дней'))))));
	});
var $author$project$API$System$Battery$drain_cpu = function (s) {
	return (s * 0.7) / 3600;
};
var $author$project$API$System$Battery$drain_gsm = function (s) {
	return (s * 1.0) / 3600;
};
var $author$project$API$System$Battery$drain_gsm_on = function (s) {
	return ((s * 0.3573) * 2.0) / 3.0;
};
var $author$project$API$System$Battery$drain_self = function (s) {
	return (s * 0.036) / 3600;
};
var $author$project$API$System$Battery$drain_session = function (s) {
	return ((s * 0.3573) * 1.0) / 3.0;
};
var $author$project$API$System$Battery$fixer = 1.2;
var $author$project$API$System$Battery$month_full = F2(
	function (_v0, m) {
		var t = _v0.aX;
		switch (m) {
			case '0':
				return '';
			case '1':
				return _Utils_ap(
					m,
					t('dates. месяц'));
			case '2':
				return _Utils_ap(
					m,
					t('dates. месяца'));
			case '3':
				return _Utils_ap(
					m,
					t('dates. месяца'));
			case '4':
				return _Utils_ap(
					m,
					t('dates. месяца'));
			default:
				return _Utils_ap(
					m,
					t('dates. месяцев'));
		}
	});
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$API$System$Battery$year_suffix = F2(
	function (_v0, y) {
		var t = _v0.aX;
		return t(
			function () {
				switch (y) {
					case 1:
						return 'dates. год';
					case 2:
						return 'dates. года';
					case 3:
						return 'dates. года';
					case 4:
						return 'dates. года';
					default:
						return 'dates. лет';
				}
			}());
	});
var $author$project$API$System$Battery$expect_at_sleep = F3(
	function (appState, capacity, sleep) {
		var sessions = 24 / (sleep / 60);
		var one_session_drain = (($author$project$API$System$Battery$drain_gsm_on(1) + $author$project$API$System$Battery$drain_gsm(20)) + $author$project$API$System$Battery$drain_cpu(20)) + $author$project$API$System$Battery$drain_session(1);
		var drainD = ((capacity / $author$project$API$System$Battery$fixer) / ($author$project$API$System$Battery$drain_self(86400) + (sessions * one_session_drain))) | 0;
		var y = (drainD / 365) | 0;
		var d = drainD - (y * 365);
		var m = (d / 30) | 0;
		var ss = (y > 10) ? _Utils_ap(
			$elm$core$String$fromInt(y),
			A2($author$project$API$System$Battery$year_suffix, appState, y)) : ((y > 0) ? ($elm$core$String$fromInt(y) + (A2($author$project$API$System$Battery$year_suffix, appState, y) + (' ' + A2(
			$author$project$API$System$Battery$month_full,
			appState,
			$elm$core$String$fromInt((d / 30) | 0))))) : ((d > 29) ? (A2(
			$author$project$API$System$Battery$month_full,
			appState,
			$elm$core$String$fromInt(m)) + (' ' + A2($author$project$API$System$Battery$days_full, appState, d - (m * 30)))) : A2($author$project$API$System$Battery$days_full, appState, d)));
		return ss;
	});
var $author$project$API$System$Battery$drain_gps = function (s) {
	return (s * 27.0) / 3600;
};
var $author$project$API$System$Battery$hours_full = F2(
	function (appState, h) {
		var t = appState.aX;
		var sh = $elm$core$String$fromInt(h);
		return (!h) ? '' : ((h === 1) ? _Utils_ap(
			sh,
			t('dates. час')) : (((h === 2) || ((h === 3) || (h === 4))) ? _Utils_ap(
			sh,
			t('dates. часа')) : ((h > 20) ? ('2' + A2($author$project$API$System$Battery$hours_full, appState, h - 20)) : _Utils_ap(
			sh,
			t('dates. часов')))));
	});
var $author$project$API$System$Battery$expect_at_tracking = F2(
	function (appState, capacity) {
		var secsInH = 3600;
		var drain_each_hour = (((($author$project$API$System$Battery$drain_gsm_on(1) + $author$project$API$System$Battery$drain_session(60)) + $author$project$API$System$Battery$drain_gps(secsInH)) + $author$project$API$System$Battery$drain_gsm(secsInH)) + $author$project$API$System$Battery$drain_cpu(secsInH)) * 0.33;
		var drainH = ((capacity / $author$project$API$System$Battery$fixer) / drain_each_hour) | 0;
		var d = (drainH / 24) | 0;
		var h = drainH - (d * 24);
		var m = (d / 30) | 0;
		return (m > 0) ? (A2(
			$author$project$API$System$Battery$month_full,
			appState,
			$elm$core$String$fromInt(m)) + (' ' + A2($author$project$API$System$Battery$days_full, appState, d - (m * 30)))) : ((d > 0) ? (A2($author$project$API$System$Battery$days_full, appState, d) + (' ' + A2($author$project$API$System$Battery$hours_full, appState, h))) : A2($author$project$API$System$Battery$hours_full, appState, h));
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Page$Home$List$batText = F5(
	function (appState, battery, used, sleep, mdynamic) {
		var t = appState.aX;
		var trackingMode = function () {
			if (mdynamic.$ === 1) {
				return false;
			} else {
				var s = mdynamic.a;
				var _v2 = s.cQ;
				if ((!_v2.$) && (!_v2.a.$)) {
					var _v3 = _v2.a;
					return true;
				} else {
					return false;
				}
			}
		}();
		var capacity = battery.dD - used;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text')
					]),
				function () {
					if (trackingMode) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('list.Режим Поиск:'))
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$API$System$Battery$expect_at_tracking, appState, capacity))
									]))
							]);
					} else {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('list.Режим Ожидание:'))
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A3($author$project$API$System$Battery$expect_at_sleep, appState, capacity, sleep))
									]))
							]);
					}
				}())
			]);
	});
var $author$project$API$System$Battery$drain_accel = function (s) {
	return (s * 0.026) / 3600;
};
var $author$project$API$System$Battery$calculation = F2(
	function (_v0, lifetime) {
		var gsm = _v0.b2;
		var gps = _v0.b1;
		var accel = _v0.bF;
		var gsm_on = _v0.b3;
		var sessions = _v0.cM;
		return (((($author$project$API$System$Battery$drain_self(lifetime) + $author$project$API$System$Battery$drain_gsm(gsm)) + $author$project$API$System$Battery$drain_gps(gps)) + $author$project$API$System$Battery$drain_accel(accel)) + $author$project$API$System$Battery$drain_gsm_on(gsm_on)) + $author$project$API$System$Battery$drain_session(sessions);
	});
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$core$String$cons = _String_cons;
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $author$project$Page$Home$List$height = function (percentage) {
	return A2($myrho$elm_round$Round$round, 0, percentage + 9) + '%';
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Components$UI$Battery$powerLevelClass = function (v) {
	return (v > 90) ? 'full' : ((v > 60) ? 'high' : ((v > 30) ? 'medium' : 'low'));
};
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $author$project$Types$Dt$toInt = function (_v0) {
	var v = _v0;
	return v;
};
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Page$Home$List$smallPowerWidget = F4(
	function (appState, mbattery, msleep, mdynamic) {
		if (mbattery.$ === 1) {
			return $elm$html$Html$text('Unknown');
		} else {
			var battery = mbattery.a;
			var sleep = function () {
				if (msleep.$ === 1) {
					return 120;
				} else {
					var sleepValue = msleep.a;
					return sleepValue;
				}
			}();
			var lifetime = (($elm$time$Time$posixToMillis(appState.bm) / 1000) | 0) - $author$project$Types$Dt$toInt(battery.dE);
			var used = A2($author$project$API$System$Battery$calculation, battery.df, lifetime);
			var percentage = (100 * (battery.dD - used)) / battery.dD;
			var p_as_text = A2($myrho$elm_round$Round$round, 1, percentage) + '%';
			var colour = $author$project$Components$UI$Battery$powerLevelClass(percentage);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-power-wr')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('power-status')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('power')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('power-top')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('power-wr')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('power-bg'),
															$elm$html$Html$Attributes$class(colour),
															A2(
															$elm$html$Html$Attributes$attribute,
															'style',
															'height: ' + $author$project$Page$Home$List$height(percentage))
														]),
													_List_fromArray(
														[
															A2(
															$elm$svg$Svg$svg,
															_List_fromArray(
																[
																	A2($elm$html$Html$Attributes$attribute, 'preserveAspectRatio', 'xMinYMin meet'),
																	$elm$svg$Svg$Attributes$viewBox('0 0 500 500')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$svg$Svg$path,
																	_List_fromArray(
																		[
																			$elm$svg$Svg$Attributes$d('M0, 100 C150, 200 350, 0 500, 100 L500, 00 L0, 0 Z'),
																			A2($elm$html$Html$Attributes$attribute, 'style', 'stroke:none; fill: #323343;')
																		]),
																	_List_Nil)
																]))
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('power-status-title'),
											$elm$html$Html$Attributes$class(colour)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(p_as_text)
										]))
								]))
						]),
					A5($author$project$Page$Home$List$batText, appState, battery, used, sleep, mdynamic)));
		}
	});
var $author$project$Page$Home$List$stateIcon = F2(
	function (_v0, mdynamic) {
		var t = _v0.aX;
		var _v1 = function () {
			if (mdynamic.$ === 1) {
				return _Utils_Tuple2(
					t('status.Состояние еще неизвестно'),
					'sleep');
			} else {
				var d = mdynamic.a;
				var _v3 = d.cQ;
				if (_v3.$ === 1) {
					return _Utils_Tuple2(
						t('status.Состояние еще неизвестно'),
						'sleep');
				} else {
					if (!_v3.a.$) {
						var _v4 = _v3.a;
						return _Utils_Tuple2(
							t('status.Авто в движении'),
							'green-car');
					} else {
						return _Utils_Tuple2(
							t('status.Авто в спящем состоянии'),
							'sleep');
					}
				}
			}
		}();
		var title_ = _v1.a;
		var icon = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tooltip')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tooltip-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tooltip-content')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title_)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tooltip-title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon'),
									$elm$html$Html$Attributes$class(icon)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$Home$List$status_icon = function (mdynamic) {
	var icon = function () {
		if (mdynamic.$ === 1) {
			return '';
		} else {
			var d = mdynamic.a;
			var _v1 = d.cQ;
			if (_v1.$ === 1) {
				return '';
			} else {
				var state = _v1.a;
				switch (state.$) {
					case 0:
						return 'search-status';
					case 1:
						return 'wait-status';
					case 2:
						return 'Блокировка';
					case 3:
						return 'wait-status';
					case 4:
						return 'wait-status';
					case 5:
						return 'off-status';
					case 6:
						return 'config-status';
					case 7:
						return 'point-status';
					case 8:
						return 'lock-status';
					case 9:
						return 'lock-status';
					case 10:
						return 'lock-status';
					case 11:
						return 'unlock-status';
					case 12:
						var hours = state.a;
						return 'prolong-status';
					default:
						var c = state.a;
						return 'unknown-status';
				}
			}
		}
	}();
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('status-icon'),
				$elm$html$Html$Attributes$class(icon)
			]),
		_List_Nil);
};
var $author$project$API$System$stateAsString = function (state) {
	switch (state.$) {
		case 0:
			return 'Поиск';
		case 1:
			return 'СОН';
		case 2:
			return 'Блокировка';
		case 3:
			return 'СОН';
		case 4:
			return 'Ожидание';
		case 5:
			return 'Выключен';
		case 6:
			return 'Конфигурация';
		case 7:
			return 'Точка';
		case 8:
			return 'Интеллектуальная блокировка';
		case 9:
			return 'Блокировка';
		case 10:
			return 'Отмена блокировки';
		case 11:
			return 'Разблокировка';
		case 12:
			var hours = state.a;
			return 'Отложить засыпание на ' + ($elm$core$String$fromInt(hours) + ' ч');
		default:
			var c = state.a;
			return 'Неизвестно:' + c;
	}
};
var $author$project$Page$Home$List$sysState_of = function (dynamic) {
	if (dynamic.$ === 1) {
		return '-';
	} else {
		var d = dynamic.a;
		var _v1 = d.cQ;
		if (_v1.$ === 1) {
			return '-';
		} else {
			var state = _v1.a;
			return $author$project$API$System$stateAsString(state);
		}
	}
};
var $author$project$Page$Home$List$card = F2(
	function (system, appState) {
		var t = appState.aX;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('icon-' + (system.dy + ' fenix-type'))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-header')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fenix-status')
							]),
						_List_fromArray(
							[
								$author$project$Page$Home$List$status_icon(system.dk),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('status')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t(
											$author$project$Page$Home$List$sysState_of(system.dk)))
									])),
								A2($author$project$Page$Home$List$stateIcon, appState, system.dk)
							])),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/system/' + (system.b6 + '/config')),
								$elm$html$Html$Attributes$class('fenix-set-btn')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-body')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fenix-title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(system.ej)
							])),
						A4($author$project$Page$Home$List$smallPowerWidget, appState, system.da, system.dV.d6, system.dk)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fenix-footer')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/map/' + system.b6),
								$elm$html$Html$Attributes$class('btn btn-md btn-secondary open-maps')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('list.На карте'))
							])),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/system/' + system.b6),
								$elm$html$Html$Attributes$class('btn btn-md btn-primary')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('list.Управление'))
							]))
					]))
			]);
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Components$UI$f_icon = function (icon) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('left fas fa-' + icon)
			]),
		_List_Nil);
};
var $author$project$Components$UI$cardFooter = F2(
	function (control_link, m_map_link) {
		var map_key = function () {
			if (m_map_link.$ === 1) {
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-sm dark-btn disabled')
						]),
					_List_fromArray(
						[
							$author$project$Components$UI$text('На карте')
						]));
			} else {
				var map_link = m_map_link.a;
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-sm blue-btn'),
							$elm$html$Html$Attributes$href(map_link)
						]),
					_List_fromArray(
						[
							$author$project$Components$UI$f_icon('map'),
							$author$project$Components$UI$text(' '),
							$author$project$Components$UI$text('На карте')
						]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fenix-footer')
				]),
			_List_fromArray(
				[
					map_key,
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-sm blue-btn'),
							$elm$html$Html$Attributes$href(control_link)
						]),
					_List_fromArray(
						[
							$author$project$Components$UI$f_icon('gamepad'),
							$author$project$Components$UI$text(' '),
							$author$project$Components$UI$text('Управление')
						]))
				]));
	});
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Components$UI$cardHeader = F2(
	function (state_text, config_link) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fenix-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('status')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn-wait orange-btn')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(state_text)
								])),
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('static/images/green-car.svg'),
									$elm$html$Html$Attributes$alt('car-green')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fenix-set-btn'),
							$elm$html$Html$Attributes$href(config_link)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$Home$List$ifPosition = function (maybe_system) {
	if (maybe_system.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var system = maybe_system.a;
		var _v1 = system.dk;
		if (_v1.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var dynamic = _v1.a;
			var _v2 = _Utils_Tuple2(dynamic.dI, dynamic.dJ);
			if ((!_v2.a.$) && (!_v2.b.$)) {
				return $elm$core$Maybe$Just('/map/' + system.b6);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	}
};
var $author$project$Components$UI$row = function (child_list) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		child_list);
};
var $author$project$Components$UI$row_item = function (child) {
	return $author$project$Components$UI$row(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col s12 ')
					]),
				child)
			]));
};
var $author$project$Page$Home$List$systemItem = F4(
	function (systems, appState, index, sysId) {
		var maybe_system = A2($elm$core$Dict$get, sysId, systems);
		var header = function () {
			if (maybe_system.$ === 1) {
				return _List_Nil;
			} else {
				var system = maybe_system.a;
				return _List_fromArray(
					[
						A2(
						$author$project$Components$UI$cardHeader,
						$author$project$Page$Home$List$sysState_of(system.dk),
						'/system/' + (sysId + '/config'))
					]);
			}
		}();
		var footer = _List_fromArray(
			[
				A2(
				$author$project$Components$UI$cardFooter,
				'/system/' + sysId,
				$author$project$Page$Home$List$ifPosition(maybe_system))
			]);
		var body = function () {
			if (maybe_system.$ === 1) {
				return _List_fromArray(
					[
						$author$project$Components$UI$row_item(
						_List_fromArray(
							[
								$author$project$Components$UI$text('Данные по объекту еще не получены или недостаточно прав для доступа')
							]))
					]);
			} else {
				var system = maybe_system.a;
				return A2($author$project$Page$Home$List$card, system, appState);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fenix fenix-bg')
				]),
			body);
	});
var $author$project$Components$UI$systemList = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('fenix-list')
		]));
var $author$project$Page$Home$List$systemList = F3(
	function (sysIds, systems, appState) {
		var timeZone = appState.bC;
		return $author$project$Components$UI$systemList(
			_Utils_ap(
				A2(
					$elm$core$List$indexedMap,
					A2($author$project$Page$Home$List$systemItem, systems, appState),
					sysIds),
				_List_fromArray(
					[
						$author$project$Page$Home$List$addCard(appState)
					])));
	});
var $author$project$Page$Home$List$systemListTitle = function (ttl_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('title-st')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(ttl_)
			]));
};
var $author$project$Components$UI$wellcomeButton = function (title_) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$href('/linksys'),
				$elm$html$Html$Attributes$class('btn btn-primary btn-lg btn-add')
			]),
		_List_fromArray(
			[
				$author$project$Components$UI$text(title_)
			]));
};
var $author$project$Components$UI$wellcomeContent = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('login-content welcome-content')
		]));
var $author$project$Components$UI$wellcomeTitle = function (title_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('login-title welcome-title')
			]),
		_List_fromArray(
			[
				$author$project$Components$UI$text(title_)
			]));
};
var $author$project$Page$Home$auth_info = F3(
	function (appState, macc, systems) {
		var t = appState.aX;
		if (macc.$ === 1) {
			return _List_fromArray(
				[
					$author$project$Components$UI$formHeader(
					t('login.Добро пожаловать')),
					$author$project$Components$UI$formSubtitle(
					t('login.Чтобы пользоваться сервисом, вы должны ')),
					A2(
					$author$project$Components$UI$greenLink,
					'/login',
					t('login.авторизоваться')),
					$author$project$Components$UI$text(' или '),
					A2(
					$author$project$Components$UI$greenLink,
					'/auth',
					t('login.зарегистрироваться')),
					$author$project$Components$UI$text(' в системе.')
				]);
		} else {
			var acc = macc.a;
			return (!$elm$core$List$length(acc.ef)) ? _List_fromArray(
				[
					$author$project$Components$UI$wellcomeContent(
					_List_fromArray(
						[
							$author$project$Components$UI$wellcomeTitle(
							t('login.Добро пожаловать!')),
							$author$project$Components$UI$formSubtitle(
							t('login.Добавьте Феникс в список наблюдения')),
							$author$project$Components$UI$wellcomeButton(
							t('list.Добавить'))
						]))
				]) : _List_fromArray(
				[
					$author$project$Page$Home$List$systemListTitle(
					t('Список Фениксов')),
					A3($author$project$Page$Home$List$systemList, acc.ef, systems, appState)
				]);
		}
	});
var $author$project$Components$UI$ModalText = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$Types$OnCancelRemove = {$: 1};
var $author$project$Page$Home$Types$OnConfirmRemove = {$: 2};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Components$UI$cmdButton = F2(
	function (label, cmd) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-md btn-primary blue-btn'),
					$elm$html$Html$Events$onClick(cmd)
				]),
			_List_fromArray(
				[
					$author$project$Components$UI$text(label)
				]));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Components$UI$modal = F3(
	function (text_title, content, buttons) {
		var element = function (row_value) {
			switch (row_value.$) {
				case 0:
					var text_value = row_value.a;
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(text_value)
							]));
				case 2:
					var icon = row_value.a;
					var text_value = row_value.b;
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'margin', '-3px 10px -3px 0'),
										$elm$html$Html$Attributes$src('static/' + icon)
									]),
								_List_Nil),
								$elm$html$Html$text(text_value)
							]));
				default:
					var html = row_value.a;
					return html;
			}
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal-bg show')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-content modal-content-light')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-close close modal-close-btn')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title')
										]),
									_List_fromArray(
										[
											$author$project$Components$UI$text(text_title)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-body')
										]),
									A2($elm$core$List$map, element, content)),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-btn-group')
										]),
									buttons)
								]))
						]))
				]));
	});
var $author$project$Components$UI$modal_overlay = function (cancelcmd) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-overlay'),
				A2($elm$html$Html$Attributes$style, 'z-index', '1002'),
				A2($elm$html$Html$Attributes$style, 'display', 'block'),
				A2($elm$html$Html$Attributes$style, 'opacity', '0.5'),
				$elm$html$Html$Events$onClick(cancelcmd)
			]),
		_List_Nil);
};
var $author$project$Page$Home$viewRemoveWidget = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return model.ar ? _List_fromArray(
			[
				A3(
				$author$project$Components$UI$modal,
				'Удаление',
				_List_fromArray(
					[
						$author$project$Components$UI$ModalText('Вы уверены что хотите удалить систему из списка наблюдения?'),
						$author$project$Components$UI$ModalText('Напоминаю, что вы не можете просто добавить систему в список наблюдения, необходимо проделать определенную процедуру.')
					]),
				_List_fromArray(
					[
						A2($author$project$Components$UI$cmdButton, 'Да', $author$project$Page$Home$Types$OnConfirmRemove),
						A2($author$project$Components$UI$cmdButton, 'Нет', $author$project$Page$Home$Types$OnCancelRemove)
					])),
				$author$project$Components$UI$modal_overlay($author$project$Page$Home$Types$OnCancelRemove)
			]) : _List_Nil;
	});
var $author$project$Page$Home$view = F4(
	function (appState, model, acc, systems) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_Utils_ap(
				A3($author$project$Page$Home$auth_info, appState, acc, systems),
				A2($author$project$Page$Home$viewRemoveWidget, appState, model)));
	});
var $author$project$Main$homeRec = {
	s: function ($) {
		return $.a4;
	},
	p: A2($elm$core$Basics$composeR, $author$project$Types$HomeMsg, $author$project$Types$OnPageMsg),
	am: F2(
		function (newModel, model) {
			return _Utils_update(
				model,
				{a4: newModel});
		}),
	_: $author$project$Types$Page$PUT_Public($author$project$Page$Home$update),
	K: $author$project$Types$Page$PT_Full($author$project$Page$Home$view)
};
var $author$project$Types$LinkSysMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Types$Page$PT_Nodata = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$Page$PUT_Private = function (a) {
	return {$: 0, a: a};
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dB: index, dK: match, dP: number, d9: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{dc: false, dN: false},
		string);
};
var $author$project$API$linkSysRequest = function (code) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'cmd',
				$elm$json$Json$Encode$string('link')),
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(code))
			]));
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Page$LinkSys$splitAtCouple = F2(
	function (pos, str) {
		return _Utils_Tuple2(
			A2($elm$core$String$left, pos, str),
			A2($elm$core$String$dropLeft, pos, str));
	});
var $author$project$Page$LinkSys$splitEvery = F2(
	function (pos, str) {
		if (_Utils_cmp(
			$elm$core$String$length(str),
			pos) > 0) {
			var _v0 = A2($author$project$Page$LinkSys$splitAtCouple, pos, str);
			var head = _v0.a;
			var tail = _v0.b;
			return A2(
				$elm$core$List$cons,
				head,
				A2($author$project$Page$LinkSys$splitEvery, pos, tail));
		} else {
			return _List_fromArray(
				[str]);
		}
	});
var $author$project$Page$LinkSys$putDashEvery = F2(
	function (len, str) {
		return A2(
			$elm$core$String$join,
			'-',
			A2($author$project$Page$LinkSys$splitEvery, len, str));
	});
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Page$LinkSys$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var newCode = msg.a;
				var cd = A3(
					$elm$regex$Regex$replace,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('[\\W\\s\\._\\-]+')),
					function (i) {
						return '';
					},
					newCode);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bO: A2($author$project$Page$LinkSys$putDashEvery, 2, cd)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								$author$project$API$linkSysRequest(model.bO))
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a3: true}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: model.v + 1}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: model.v - 1}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: page}),
					$elm$core$Platform$Cmd$none);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: true}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$LinkSys$OnLedHelp = {$: 6};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$Page$LinkSys$OnNext = {$: 3};
var $author$project$Page$LinkSys$Page = F4(
	function (title, list, form, buttons) {
		return {a$: buttons, bZ: form, ce: list, ej: title};
	});
var $author$project$Page$LinkSys$page1 = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return A4(
			$author$project$Page$LinkSys$Page,
			'Подготовка SIM-карты',
			_List_fromArray(
				['Установите SIM-карту в мобильный телефон.', 'Активируйте SIM-карту в соответствии с инструкциями GSM-оператора.', 'Убедитесь в том, что PIN-код при включении телефона выключен.', 'В случае необходимости зарегистрируйте SIM-карту на сайте GSM-оператора.', 'Выключите мобильный телефон и извлеките из него подготовленную SIM-карту.']),
			$elm$html$Html$text(''),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnNext)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Далeе'))
						]))
				]));
	});
var $author$project$Page$LinkSys$OnPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$LinkSys$OnPrev = {$: 4};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$LinkSys$page2 = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return A4(
			$author$project$Page$LinkSys$Page,
			'Установка подготовленной SIM-карты в Феникс',
			_List_fromArray(
				['Выкрутите 4 винта и снимите крышку корпуса.', 'Убедитесь в том, что Феникс выключен – светодиодный индикатор не горит и не мигает.', 'Установите подготовленную SIM-карту в Феникс.']),
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('binding-executors')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('master.В случае необходимости произведите привязку экзекуторов.'))
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('orange-gradient-text binding-title'),
										$elm$html$Html$Events$onClick(
										$author$project$Page$LinkSys$OnPage(3))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('master.Привязать экзекутор'))
									]))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('checkmark-wrap checkmark-executors')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('checkboxContainer')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name(''),
												$elm$html$Html$Attributes$type_('checkbox'),
												$elm$html$Html$Attributes$value('')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('checkmark')
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('checkmark-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('master.Экзекутор в наличии'))
									]))
							]))
					])),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnPrev)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Назад'))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$LinkSys$OnPage(4))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Далeе'))
						]))
				]));
	});
var $author$project$Page$LinkSys$page3 = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return A4(
			$author$project$Page$LinkSys$Page,
			'Привязка экзекуторов и активация Феникса',
			_List_fromArray(
				['Исходное состояние: Феникс – выключен.', 'Обесточьте все привязываемые экзекуторы и подготовьте их к подаче питания.', 'Нажмите и удерживайте 3 секунды кнопку Фениска – загорится светодиод.', 'Как только светодиод загорится – подайте питание на все привязываемые экзекуторы – светодиод отработает серию частых вспышек и начнёт отрабатывать редкие одиночные вспышки.', 'Закройте крышку корпуса Фениска и закрутите 4 винта.']),
			$elm$html$Html$text(''),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnPrev)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Назад'))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$LinkSys$OnPage(5))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Далeе'))
						]))
				]));
	});
var $author$project$Page$LinkSys$page4 = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return A4(
			$author$project$Page$LinkSys$Page,
			'Активация Феникса',
			_List_fromArray(
				['Нажмите кнопку Феникса – светодиодный индикатор подтвердит включение.', 'Закройте крышку корпуса Феникса и закрутите 4 винта.']),
			$elm$html$Html$text(''),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$LinkSys$OnPage(2))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Назад'))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnNext)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Далeе'))
						]))
				]));
	});
var $author$project$Page$LinkSys$OnCode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$LinkSys$StartLink = {$: 1};
var $elm$html$Html$Attributes$action = function (uri) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'action',
		_VirtualDom_noJavaScriptUri(uri));
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$pattern = $elm$html$Html$Attributes$stringProperty('pattern');
var $author$project$Page$LinkSys$page5 = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return A4(
			$author$project$Page$LinkSys$Page,
			'Добавление Феникса в наблюдение',
			_List_fromArray(
				['Отправьте на телефонный номер SIM-карты Феникса SMS: link', 'В ответ придёт уникальный код – введите его в поле ниже:']),
			A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$action('')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-list')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input-st')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
												A2($elm$html$Html$Attributes$attribute, 'required', ''),
												$elm$html$Html$Attributes$pattern('[0-9]*'),
												A2($elm$html$Html$Attributes$attribute, 'inputmode', 'numeric'),
												$elm$html$Html$Attributes$value(model.bO),
												$elm$html$Html$Events$onInput($author$project$Page$LinkSys$OnCode)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-label')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('master.Введите уникальный код из SMS'))
											]))
									]))
							]))
					])),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnPrev)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Назад'))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
							$elm$html$Html$Events$onClick($author$project$Page$LinkSys$StartLink)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('master.Подтвердить'))
						]))
				]));
	});
var $author$project$Page$LinkSys$view = F2(
	function (appState, model) {
		var t = appState.aX;
		var page = function () {
			var _v0 = model.v;
			switch (_v0) {
				case 1:
					return A2($author$project$Page$LinkSys$page1, appState, model);
				case 2:
					return A2($author$project$Page$LinkSys$page2, appState, model);
				case 3:
					return A2($author$project$Page$LinkSys$page3, appState, model);
				case 4:
					return A2($author$project$Page$LinkSys$page4, appState, model);
				default:
					return A2($author$project$Page$LinkSys$page5, appState, model);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrapper-bg')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/'),
											$elm$html$Html$Attributes$class('close closeAddFenix')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title-sm-gr')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('master.Мастер добавления Феникса'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title-st')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('master.' + page.ej))
										])),
									A2(
									$elm$html$Html$ol,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('list-numbered list')
										]),
									A2(
										$elm$core$List$map,
										function (e) {
											return A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('master.' + e))
													]));
										},
										page.ce)),
									page.bZ,
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('wrapper-content-btn', true),
													_Utils_Tuple2(
													'btn-group',
													$elm$core$List$length(page.a$) > 1)
												]))
										]),
									page.a$),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('led-indicator-wr modal-open'),
											$elm$html$Html$Events$onClick($author$project$Page$LinkSys$OnLedHelp)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('master.Свериться с'))
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('led-title orange-gradient-text')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$img,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$alt('led'),
															$elm$html$Html$Attributes$src('images/led.svg')
														]),
													_List_Nil),
													$elm$html$Html$text('LED')
												])),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('master.индикатором'))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Main$linkSysRec = {
	s: function ($) {
		return $.aJ;
	},
	p: A2($elm$core$Basics$composeR, $author$project$Types$LinkSysMsg, $author$project$Types$OnPageMsg),
	am: F2(
		function (newModel, model) {
			return _Utils_update(
				model,
				{aJ: newModel});
		}),
	_: $author$project$Types$Page$PUT_Private($author$project$Page$LinkSys$update),
	K: $author$project$Types$Page$PT_Nodata($author$project$Page$LinkSys$view)
};
var $author$project$Types$LoginMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$Auth = {$: 3};
var $author$project$Page$Login$MenuMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Login$ShowPassword = {$: 6};
var $author$project$Page$Login$Update = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$active_if = function (active) {
	if (active) {
		return ' active';
	} else {
		return '';
	}
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Components$UI$Menu$SelectLanguage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$UI$Menu$ShowLanguagePopup = {$: 4};
var $author$project$Components$UI$Menu$activableDropdown = function (d) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('dropdown', true),
						_Utils_Tuple2('active', d)
					]))
			]));
};
var $author$project$I18N$LanguageItem = F3(
	function (langCode, title, flag) {
		return {ds: flag, cb: langCode, ej: title};
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$I18N$languages = _List_fromArray(
	[
		A3($author$project$I18N$LanguageItem, 'UA', 'Українська', 'uk'),
		A3($author$project$I18N$LanguageItem, 'RU', 'Русский', 'ru'),
		A3($author$project$I18N$LanguageItem, 'EN', 'English', 'en')
	]);
var $author$project$I18N$langCode2lang = function (langCode) {
	return A2(
		$elm$core$Maybe$withDefault,
		A3($author$project$I18N$LanguageItem, 'RU', 'Русский', 'ru'),
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (i) {
					return _Utils_eq(i.cb, langCode);
				},
				$author$project$I18N$languages)));
};
var $author$project$Components$UI$Menu$onClickStopPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'click',
		$elm$json$Json$Decode$succeed(
			_Utils_Tuple2(msg, true)));
};
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Components$UI$Menu$menuLanguage = F2(
	function (_v0, _v1) {
		var langCode = _v0.cb;
		var t = _v0.aX;
		var languagePopup = _v1.t;
		var languages = $author$project$I18N$languages;
		var langItem = function (l) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Components$UI$Menu$SelectLanguage(l.cb))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flag ' + l.ds)
									]),
								_List_Nil),
								$elm$html$Html$text(l.ej)
							]))
					]));
		};
		return A2(
			$author$project$Components$UI$Menu$activableDropdown,
			languagePopup,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-title'),
							$author$project$Components$UI$Menu$onClickStopPropagation($author$project$Components$UI$Menu$ShowLanguagePopup)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('language-icon')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('selectedLanguage')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$I18N$langCode2lang(langCode).ej)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-list')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('menu.Язык'))
									]))
							]),
						A2($elm$core$List$map, langItem, languages)))
				]));
	});
var $author$project$Components$UI$Menu$SelectScale = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$UI$Menu$ShowScalesPopup = {$: 1};
var $author$project$Components$UI$Scale$ScaleID = $elm$core$Basics$identity;
var $author$project$Components$UI$Scale$ScaleItem = F2(
	function (name, class_name) {
		return {bN: class_name, cn: name};
	});
var $pzp1997$assoc_list$AssocList$D = $elm$core$Basics$identity;
var $pzp1997$assoc_list$AssocList$remove = F2(
	function (targetKey, _v0) {
		var alist = _v0;
		return A2(
			$elm$core$List$filter,
			function (_v1) {
				var key = _v1.a;
				return !_Utils_eq(key, targetKey);
			},
			alist);
	});
var $pzp1997$assoc_list$AssocList$insert = F3(
	function (key, value, dict) {
		var _v0 = A2($pzp1997$assoc_list$AssocList$remove, key, dict);
		var alteredAlist = _v0;
		return A2(
			$elm$core$List$cons,
			_Utils_Tuple2(key, value),
			alteredAlist);
	});
var $pzp1997$assoc_list$AssocList$fromList = function (alist) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, result) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($pzp1997$assoc_list$AssocList$insert, key, value, result);
			}),
		_List_Nil,
		alist);
};
var $author$project$Components$UI$Scale$defaultScales = $pzp1997$assoc_list$AssocList$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'normal',
			A2($author$project$Components$UI$Scale$ScaleItem, 'normal', 'normal')),
			_Utils_Tuple2(
			'small',
			A2($author$project$Components$UI$Scale$ScaleItem, 'small', 'small')),
			_Utils_Tuple2(
			'big',
			A2($author$project$Components$UI$Scale$ScaleItem, 'big', 'big')),
			_Utils_Tuple2(
			'bigger',
			A2($author$project$Components$UI$Scale$ScaleItem, 'bigger', 'bigger')),
			_Utils_Tuple2(
			'biggest',
			A2($author$project$Components$UI$Scale$ScaleItem, 'biggest', 'biggest'))
		]));
var $pzp1997$assoc_list$AssocList$toList = function (_v0) {
	var alist = _v0;
	return alist;
};
var $author$project$Components$UI$Menu$menuScale = F2(
	function (_v0, _v1) {
		var t = _v0.aX;
		var scaleName = _v0.cI;
		var scalePopup = _v1.G;
		var scales = $author$project$Components$UI$Scale$defaultScales;
		var scale_item = function (_v2) {
			var sid = _v2.a;
			var name = _v2.b.cn;
			var class_name = _v2.b.bN;
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Components$UI$Menu$SelectScale(sid))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('scales.' + name))
							]))
					]));
		};
		return A2(
			$author$project$Components$UI$Menu$activableDropdown,
			scalePopup,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-title'),
							$author$project$Components$UI$Menu$onClickStopPropagation($author$project$Components$UI$Menu$ShowScalesPopup)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mode-icon')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('selectedScale')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('scales.' + scaleName))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-list')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('menu.Размер'))
									]))
							]),
						A2(
							$elm$core$List$map,
							scale_item,
							$pzp1997$assoc_list$AssocList$toList(scales))))
				]));
	});
var $author$project$Components$UI$Menu$SelectTheme = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UI$Menu$ShowThemesPopup = {$: 0};
var $author$project$Components$UI$Theme$ThemeID = $elm$core$Basics$identity;
var $author$project$Components$UI$Theme$ThemeItem = F2(
	function (name, class_name) {
		return {bN: class_name, cn: name};
	});
var $author$project$Components$UI$Theme$defaultThemes = $pzp1997$assoc_list$AssocList$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'dark',
			A2($author$project$Components$UI$Theme$ThemeItem, 'dark', 'dark')),
			_Utils_Tuple2(
			'light',
			A2($author$project$Components$UI$Theme$ThemeItem, 'light', 'light'))
		]));
var $author$project$Components$UI$Menu$menuTheme = F2(
	function (_v0, _v1) {
		var t = _v0.aX;
		var themeName = _v0.cW;
		var themePopup = _v1.z;
		var themes = $author$project$Components$UI$Theme$defaultThemes;
		var theme_item = function (_v2) {
			var tid = _v2.a;
			var name = _v2.b.cn;
			var class_name = _v2.b.bN;
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Components$UI$Menu$SelectTheme(tid))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('themes.' + name))
							]))
					]));
		};
		return A2(
			$author$project$Components$UI$Menu$activableDropdown,
			themePopup,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-title'),
							$author$project$Components$UI$Menu$onClickStopPropagation($author$project$Components$UI$Menu$ShowThemesPopup)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mode-icon')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('selectedTheme')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('themes.' + themeName))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-list')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('menu.Тема'))
									]))
							]),
						A2(
							$elm$core$List$map,
							theme_item,
							$pzp1997$assoc_list$AssocList$toList(themes))))
				]));
	});
var $author$project$Page$Login$password_type = function (enabled) {
	if (enabled) {
		return 'text';
	} else {
		return 'password';
	}
};
var $author$project$Page$Login$loginView = F2(
	function (appState, model) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container container-login')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrapper-bg')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo logo-wr')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$alt('Logo'),
													$elm$html$Html$Attributes$src('images/logo.svg')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('login.Добро пожаловать'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-subtitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('login.Войдите, чтобы продолжить'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-inputs')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-st')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('text'),
															A2($elm$html$Html$Attributes$attribute, 'required', ''),
															A2($elm$html$Html$Attributes$attribute, 'autofocus', ''),
															$elm$html$Html$Attributes$value(model.A),
															$elm$html$Html$Events$onInput(
															function (_new) {
																return $author$project$Page$Login$Update(
																	_Utils_update(
																		model,
																		{A: _new}));
															})
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-label')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Введите Ваш логин'))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-st password')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$id('password'),
															A2($elm$html$Html$Attributes$attribute, 'required', ''),
															$elm$html$Html$Attributes$type_(
															$author$project$Page$Login$password_type(model.x)),
															$elm$html$Html$Attributes$value(model.r),
															$elm$html$Html$Events$onInput(
															function (_new) {
																return $author$project$Page$Login$Update(
																	_Utils_update(
																		model,
																		{r: _new}));
															})
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-label')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Введите Ваш пароль'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class(
															'password-icon' + $author$project$Page$Login$active_if(model.x)),
															$elm$html$Html$Attributes$id('passwordBtn'),
															$elm$html$Html$Events$onClick($author$project$Page$Login$ShowPassword)
														]),
													_List_Nil),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-error')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('error-icon')
																]),
															_List_Nil),
															$elm$html$Html$text(
															t('login.Неправильный пароль'))
														]))
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
													$elm$html$Html$Events$onClick($author$project$Page$Login$Auth)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('login.Войти в систему'))
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('accaunt-link')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.У вас нет аккаунта?'))
														])),
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('link'),
															$elm$html$Html$Attributes$href('/auth')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Зарегистрироваться'))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrapper-content-footer')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Page$Login$MenuMsg,
									A2($author$project$Components$UI$Menu$menuLanguage, appState, model.j)),
									A2(
									$elm$html$Html$map,
									$author$project$Page$Login$MenuMsg,
									A2($author$project$Components$UI$Menu$menuTheme, appState, model.j)),
									A2(
									$elm$html$Html$map,
									$author$project$Page$Login$MenuMsg,
									A2($author$project$Components$UI$Menu$menuScale, appState, model.j))
								]))
						]))
				]));
	});
var $author$project$Msg$MenuMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$API$commonRequest = F2(
	function (cmd, data) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string(cmd)),
					_Utils_Tuple2(
					'data',
					$elm$json$Json$Encode$object(data))
				]));
	});
var $author$project$API$authUserRequest = F2(
	function (username, password_hash) {
		return A2(
			$author$project$API$commonRequest,
			'auth',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string(username)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password_hash))
				]));
	});
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $truqu$elm_md5$MD5$emptyWords = A2($elm$core$Array$repeat, 16, 0);
var $truqu$elm_md5$MD5$addUnsigned = F2(
	function (x, y) {
		return 4294967295 & (x + y);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_md5$MD5$rotateLeft = F2(
	function (bits, input) {
		return (input << bits) | (input >>> (32 - bits));
	});
var $truqu$elm_md5$MD5$cmn = F8(
	function (fun, a, b, c, d, x, s, ac) {
		return A2(
			$truqu$elm_md5$MD5$addUnsigned,
			b,
			A2(
				$truqu$elm_md5$MD5$rotateLeft,
				s,
				A2(
					$truqu$elm_md5$MD5$addUnsigned,
					a,
					A2(
						$truqu$elm_md5$MD5$addUnsigned,
						ac,
						A2(
							$truqu$elm_md5$MD5$addUnsigned,
							A3(fun, b, c, d),
							x)))));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $truqu$elm_md5$MD5$f = F3(
	function (x, y, z) {
		return z ^ (x & (y ^ z));
	});
var $truqu$elm_md5$MD5$ff = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$f, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$g = F3(
	function (x, y, z) {
		return y ^ (z & (x ^ y));
	});
var $truqu$elm_md5$MD5$gg = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$g, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$h = F3(
	function (x, y, z) {
		return z ^ (x ^ y);
	});
var $truqu$elm_md5$MD5$hh = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$h, a, b, c, d, x, s, ac);
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $truqu$elm_md5$MD5$i = F3(
	function (x, y, z) {
		return y ^ (x | (~z));
	});
var $truqu$elm_md5$MD5$ii = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$i, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$hex_ = F2(
	function (xs, acc) {
		var a = acc.ay;
		var b = acc.aC;
		var c = acc.aD;
		var d = acc.aF;
		if ((((((((((((((((xs.b && xs.b.b) && xs.b.b.b) && xs.b.b.b.b) && xs.b.b.b.b.b) && xs.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && (!xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b)) {
			var x0 = xs.a;
			var _v1 = xs.b;
			var x1 = _v1.a;
			var _v2 = _v1.b;
			var x2 = _v2.a;
			var _v3 = _v2.b;
			var x3 = _v3.a;
			var _v4 = _v3.b;
			var x4 = _v4.a;
			var _v5 = _v4.b;
			var x5 = _v5.a;
			var _v6 = _v5.b;
			var x6 = _v6.a;
			var _v7 = _v6.b;
			var x7 = _v7.a;
			var _v8 = _v7.b;
			var x8 = _v8.a;
			var _v9 = _v8.b;
			var x9 = _v9.a;
			var _v10 = _v9.b;
			var x10 = _v10.a;
			var _v11 = _v10.b;
			var x11 = _v11.a;
			var _v12 = _v11.b;
			var x12 = _v12.a;
			var _v13 = _v12.b;
			var x13 = _v13.a;
			var _v14 = _v13.b;
			var x14 = _v14.a;
			var _v15 = _v14.b;
			var x15 = _v15.a;
			var s44 = 21;
			var s43 = 15;
			var s42 = 10;
			var s41 = 6;
			var s34 = 23;
			var s33 = 16;
			var s32 = 11;
			var s31 = 4;
			var s24 = 20;
			var s23 = 14;
			var s22 = 9;
			var s21 = 5;
			var s14 = 22;
			var s13 = 17;
			var s12 = 12;
			var s11 = 7;
			var d00 = d;
			var c00 = c;
			var b00 = b;
			var a00 = a;
			var a01 = A7($truqu$elm_md5$MD5$ff, a00, b00, c00, d00, x0, s11, 3614090360);
			var d01 = A7($truqu$elm_md5$MD5$ff, d00, a01, b00, c00, x1, s12, 3905402710);
			var c01 = A7($truqu$elm_md5$MD5$ff, c00, d01, a01, b00, x2, s13, 606105819);
			var b01 = A7($truqu$elm_md5$MD5$ff, b00, c01, d01, a01, x3, s14, 3250441966);
			var a02 = A7($truqu$elm_md5$MD5$ff, a01, b01, c01, d01, x4, s11, 4118548399);
			var d02 = A7($truqu$elm_md5$MD5$ff, d01, a02, b01, c01, x5, s12, 1200080426);
			var c02 = A7($truqu$elm_md5$MD5$ff, c01, d02, a02, b01, x6, s13, 2821735955);
			var b02 = A7($truqu$elm_md5$MD5$ff, b01, c02, d02, a02, x7, s14, 4249261313);
			var a03 = A7($truqu$elm_md5$MD5$ff, a02, b02, c02, d02, x8, s11, 1770035416);
			var d03 = A7($truqu$elm_md5$MD5$ff, d02, a03, b02, c02, x9, s12, 2336552879);
			var c03 = A7($truqu$elm_md5$MD5$ff, c02, d03, a03, b02, x10, s13, 4294925233);
			var b03 = A7($truqu$elm_md5$MD5$ff, b02, c03, d03, a03, x11, s14, 2304563134);
			var a04 = A7($truqu$elm_md5$MD5$ff, a03, b03, c03, d03, x12, s11, 1804603682);
			var d04 = A7($truqu$elm_md5$MD5$ff, d03, a04, b03, c03, x13, s12, 4254626195);
			var c04 = A7($truqu$elm_md5$MD5$ff, c03, d04, a04, b03, x14, s13, 2792965006);
			var b04 = A7($truqu$elm_md5$MD5$ff, b03, c04, d04, a04, x15, s14, 1236535329);
			var a05 = A7($truqu$elm_md5$MD5$gg, a04, b04, c04, d04, x1, s21, 4129170786);
			var d05 = A7($truqu$elm_md5$MD5$gg, d04, a05, b04, c04, x6, s22, 3225465664);
			var c05 = A7($truqu$elm_md5$MD5$gg, c04, d05, a05, b04, x11, s23, 643717713);
			var b05 = A7($truqu$elm_md5$MD5$gg, b04, c05, d05, a05, x0, s24, 3921069994);
			var a06 = A7($truqu$elm_md5$MD5$gg, a05, b05, c05, d05, x5, s21, 3593408605);
			var d06 = A7($truqu$elm_md5$MD5$gg, d05, a06, b05, c05, x10, s22, 38016083);
			var c06 = A7($truqu$elm_md5$MD5$gg, c05, d06, a06, b05, x15, s23, 3634488961);
			var b06 = A7($truqu$elm_md5$MD5$gg, b05, c06, d06, a06, x4, s24, 3889429448);
			var a07 = A7($truqu$elm_md5$MD5$gg, a06, b06, c06, d06, x9, s21, 568446438);
			var d07 = A7($truqu$elm_md5$MD5$gg, d06, a07, b06, c06, x14, s22, 3275163606);
			var c07 = A7($truqu$elm_md5$MD5$gg, c06, d07, a07, b06, x3, s23, 4107603335);
			var b07 = A7($truqu$elm_md5$MD5$gg, b06, c07, d07, a07, x8, s24, 1163531501);
			var a08 = A7($truqu$elm_md5$MD5$gg, a07, b07, c07, d07, x13, s21, 2850285829);
			var d08 = A7($truqu$elm_md5$MD5$gg, d07, a08, b07, c07, x2, s22, 4243563512);
			var c08 = A7($truqu$elm_md5$MD5$gg, c07, d08, a08, b07, x7, s23, 1735328473);
			var b08 = A7($truqu$elm_md5$MD5$gg, b07, c08, d08, a08, x12, s24, 2368359562);
			var a09 = A7($truqu$elm_md5$MD5$hh, a08, b08, c08, d08, x5, s31, 4294588738);
			var d09 = A7($truqu$elm_md5$MD5$hh, d08, a09, b08, c08, x8, s32, 2272392833);
			var c09 = A7($truqu$elm_md5$MD5$hh, c08, d09, a09, b08, x11, s33, 1839030562);
			var b09 = A7($truqu$elm_md5$MD5$hh, b08, c09, d09, a09, x14, s34, 4259657740);
			var a10 = A7($truqu$elm_md5$MD5$hh, a09, b09, c09, d09, x1, s31, 2763975236);
			var d10 = A7($truqu$elm_md5$MD5$hh, d09, a10, b09, c09, x4, s32, 1272893353);
			var c10 = A7($truqu$elm_md5$MD5$hh, c09, d10, a10, b09, x7, s33, 4139469664);
			var b10 = A7($truqu$elm_md5$MD5$hh, b09, c10, d10, a10, x10, s34, 3200236656);
			var a11 = A7($truqu$elm_md5$MD5$hh, a10, b10, c10, d10, x13, s31, 681279174);
			var d11 = A7($truqu$elm_md5$MD5$hh, d10, a11, b10, c10, x0, s32, 3936430074);
			var c11 = A7($truqu$elm_md5$MD5$hh, c10, d11, a11, b10, x3, s33, 3572445317);
			var b11 = A7($truqu$elm_md5$MD5$hh, b10, c11, d11, a11, x6, s34, 76029189);
			var a12 = A7($truqu$elm_md5$MD5$hh, a11, b11, c11, d11, x9, s31, 3654602809);
			var d12 = A7($truqu$elm_md5$MD5$hh, d11, a12, b11, c11, x12, s32, 3873151461);
			var c12 = A7($truqu$elm_md5$MD5$hh, c11, d12, a12, b11, x15, s33, 530742520);
			var b12 = A7($truqu$elm_md5$MD5$hh, b11, c12, d12, a12, x2, s34, 3299628645);
			var a13 = A7($truqu$elm_md5$MD5$ii, a12, b12, c12, d12, x0, s41, 4096336452);
			var d13 = A7($truqu$elm_md5$MD5$ii, d12, a13, b12, c12, x7, s42, 1126891415);
			var c13 = A7($truqu$elm_md5$MD5$ii, c12, d13, a13, b12, x14, s43, 2878612391);
			var b13 = A7($truqu$elm_md5$MD5$ii, b12, c13, d13, a13, x5, s44, 4237533241);
			var a14 = A7($truqu$elm_md5$MD5$ii, a13, b13, c13, d13, x12, s41, 1700485571);
			var d14 = A7($truqu$elm_md5$MD5$ii, d13, a14, b13, c13, x3, s42, 2399980690);
			var c14 = A7($truqu$elm_md5$MD5$ii, c13, d14, a14, b13, x10, s43, 4293915773);
			var b14 = A7($truqu$elm_md5$MD5$ii, b13, c14, d14, a14, x1, s44, 2240044497);
			var a15 = A7($truqu$elm_md5$MD5$ii, a14, b14, c14, d14, x8, s41, 1873313359);
			var d15 = A7($truqu$elm_md5$MD5$ii, d14, a15, b14, c14, x15, s42, 4264355552);
			var c15 = A7($truqu$elm_md5$MD5$ii, c14, d15, a15, b14, x6, s43, 2734768916);
			var b15 = A7($truqu$elm_md5$MD5$ii, b14, c15, d15, a15, x13, s44, 1309151649);
			var a16 = A7($truqu$elm_md5$MD5$ii, a15, b15, c15, d15, x4, s41, 4149444226);
			var d16 = A7($truqu$elm_md5$MD5$ii, d15, a16, b15, c15, x11, s42, 3174756917);
			var c16 = A7($truqu$elm_md5$MD5$ii, c15, d16, a16, b15, x2, s43, 718787259);
			var b16 = A7($truqu$elm_md5$MD5$ii, b15, c16, d16, a16, x9, s44, 3951481745);
			var b17 = A2($truqu$elm_md5$MD5$addUnsigned, b00, b16);
			var c17 = A2($truqu$elm_md5$MD5$addUnsigned, c00, c16);
			var d17 = A2($truqu$elm_md5$MD5$addUnsigned, d00, d16);
			var a17 = A2($truqu$elm_md5$MD5$addUnsigned, a00, a16);
			return {ay: a17, aC: b17, aD: c17, aF: d17};
		} else {
			return acc;
		}
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $truqu$elm_md5$MD5$iget = F2(
	function (index, array) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Array$get, index, array));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $truqu$elm_md5$MD5$consume = F2(
	function (_char, _v0) {
		var hashState = _v0.a;
		var _v1 = _v0.b;
		var byteCount = _v1.a;
		var words = _v1.b;
		var totalByteCount = _v0.c;
		var wordCount = (byteCount / 4) | 0;
		var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
		var bytePosition = 8 * (byteCount % 4);
		var code = _char << bytePosition;
		var newWord = oldWord | code;
		var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
		return (byteCount === 63) ? _Utils_Tuple3(
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState),
			_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
			totalByteCount + 1) : _Utils_Tuple3(
			hashState,
			_Utils_Tuple2(byteCount + 1, newWords),
			totalByteCount + 1);
	});
var $truqu$elm_md5$MD5$finishUp = function (_v0) {
	var hashState = _v0.a;
	var _v1 = _v0.b;
	var byteCount = _v1.a;
	var words = _v1.b;
	var totalByteCount = _v0.c;
	var wordCount = (byteCount / 4) | 0;
	var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
	var bytePosition = 8 * (byteCount % 4);
	var code = 128 << bytePosition;
	var newWord = oldWord | code;
	var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
	return (wordCount < 14) ? function (x) {
		return A2($truqu$elm_md5$MD5$hex_, x, hashState);
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, newWords)))) : function (x) {
		return A2(
			$truqu$elm_md5$MD5$hex_,
			x,
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState));
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, $truqu$elm_md5$MD5$emptyWords))));
};
var $elm$core$String$foldl = _String_foldl;
var $zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8 = F3(
	function (add, _char, acc) {
		return (_char < 128) ? A2(add, _char, acc) : ((_char < 2048) ? A2(
			add,
			128 | (63 & _char),
			A2(add, 192 | (_char >>> 6), acc)) : ((_char < 65536) ? A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(add, 224 | (_char >>> 12), acc))) : A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(
					add,
					128 | (63 & (_char >>> 12)),
					A2(add, 240 | (_char >>> 18), acc))))));
	});
var $zwilias$elm_utf_tools$String$UTF8$foldl = F3(
	function (op, initialAcc, input) {
		return A3(
			$elm$core$String$foldl,
			F2(
				function (_char, acc) {
					return A3(
						$zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8,
						op,
						$elm$core$Char$toCode(_char),
						acc);
				}),
			initialAcc,
			input);
	});
var $truqu$elm_md5$MD5$State = F4(
	function (a, b, c, d) {
		return {ay: a, aC: b, aD: c, aF: d};
	});
var $truqu$elm_md5$MD5$initialHashState = A4($truqu$elm_md5$MD5$State, 1732584193, 4023233417, 2562383102, 271733878);
var $truqu$elm_md5$MD5$hash = function (input) {
	return $truqu$elm_md5$MD5$finishUp(
		A3(
			$zwilias$elm_utf_tools$String$UTF8$foldl,
			$truqu$elm_md5$MD5$consume,
			_Utils_Tuple3(
				$truqu$elm_md5$MD5$initialHashState,
				_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
				0),
			input));
};
var $truqu$elm_md5$MD5$bytes = function (string) {
	var _v0 = $truqu$elm_md5$MD5$hash(string);
	var a = _v0.ay;
	var b = _v0.aC;
	var c = _v0.aD;
	var d = _v0.aF;
	return _List_fromArray(
		[a & 255, (a >>> 8) & 255, (a >>> 16) & 255, (a >>> 24) & 255, b & 255, (b >>> 8) & 255, (b >>> 16) & 255, (b >>> 24) & 255, c & 255, (c >>> 8) & 255, (c >>> 16) & 255, (c >>> 24) & 255, d & 255, (d >>> 8) & 255, (d >>> 16) & 255, (d >>> 24) & 255]);
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $truqu$elm_md5$MD5$toHex = function (_byte) {
	switch (_byte) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return 'a';
		case 11:
			return 'b';
		case 12:
			return 'c';
		case 13:
			return 'd';
		case 14:
			return 'e';
		case 15:
			return 'f';
		default:
			return _Utils_ap(
				$truqu$elm_md5$MD5$toHex((_byte / 16) | 0),
				$truqu$elm_md5$MD5$toHex(_byte % 16));
	}
};
var $truqu$elm_md5$MD5$hex = function (s) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (b, acc) {
				return _Utils_ap(
					acc,
					A3(
						$elm$core$String$padLeft,
						2,
						'0',
						$truqu$elm_md5$MD5$toHex(b)));
			}),
		'',
		$truqu$elm_md5$MD5$bytes(s));
};
var $author$project$API$registerUserRequest = F2(
	function (username, password_hash) {
		return A2(
			$author$project$API$commonRequest,
			'register',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string(username)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password_hash))
				]));
	});
var $author$project$Components$UI$Menu$ChangeLanguage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UI$Menu$ChangeScale = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UI$Menu$ChangeTheme = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UI$Menu$Logout = {$: 3};
var $author$project$Components$UI$Menu$saveLanguage = _Platform_outgoingPort('saveLanguage', $elm$json$Json$Encode$string);
var $author$project$Components$UI$Menu$saveScale = _Platform_outgoingPort('saveScale', $elm$json$Json$Encode$string);
var $author$project$Components$UI$Menu$saveTheme = _Platform_outgoingPort('saveTheme', $elm$json$Json$Encode$string);
var $author$project$Components$UI$Menu$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: false, G: false, H: false, z: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: false, G: true, H: false, z: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var tid = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{z: false}),
					$author$project$Components$UI$Menu$saveTheme(tid),
					$elm$core$Maybe$Just(
						$author$project$Components$UI$Menu$ChangeTheme(tid)));
			case 3:
				var tid = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{G: false}),
					$author$project$Components$UI$Menu$saveScale(tid),
					$elm$core$Maybe$Just(
						$author$project$Components$UI$Menu$ChangeScale(tid)));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: true, G: false, H: false, z: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 5:
				var langCode = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Components$UI$Menu$saveLanguage(langCode)
							])),
					$elm$core$Maybe$Just(
						$author$project$Components$UI$Menu$ChangeLanguage(langCode)));
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ac: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{H: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{H: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ac: false, t: false, G: false, z: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$Components$UI$Menu$Logout));
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{C: !model.C}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{D: !model.D}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{A: val}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{r: val}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var newModel = msg.a;
				return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 3:
				var password_hash = $truqu$elm_md5$MD5$hex(model.r);
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								A2($author$project$API$authUserRequest, model.A, password_hash))
							])),
					$elm$core$Maybe$Nothing);
			case 4:
				var password_hash = $truqu$elm_md5$MD5$hex(model.r);
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								A2($author$project$API$registerUserRequest, model.A, password_hash))
							])),
					$elm$core$Maybe$Nothing);
			case 5:
				var menuMsg = msg.a;
				var _v1 = A2($author$project$Components$UI$Menu$update, menuMsg, model.j);
				var updatedMenuModel = _v1.a;
				var upstream = _v1.b;
				var upmessage = _v1.c;
				if (upmessage.$ === 1) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{j: updatedMenuModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Login$MenuMsg, upstream),
						$elm$core$Maybe$Nothing);
				} else {
					var upMsg = upmessage.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{j: updatedMenuModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Login$MenuMsg, upstream),
						$elm$core$Maybe$Just(
							$author$project$Msg$MenuMsg(upMsg)));
				}
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{x: !model.x}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			default:
				var v = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aI: v}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$Main$loginRec = {
	s: function ($) {
		return $.bd;
	},
	p: A2($elm$core$Basics$composeR, $author$project$Types$LoginMsg, $author$project$Types$OnPageMsg),
	am: F2(
		function (newModel, model) {
			return _Utils_update(
				model,
				{bd: newModel});
		}),
	_: $author$project$Types$Page$PUT_Public($author$project$Page$Login$update),
	K: $author$project$Types$Page$PT_Nodata($author$project$Page$Login$loginView)
};
var $author$project$Components$UI$Scale$replaceScale = F2(
	function (scaleName, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var appState = model.bJ;
		var newAppState = _Utils_update(
			appState,
			{cI: scaleName});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{bJ: newAppState}),
			cmd);
	});
var $author$project$Components$UI$Theme$replaceTheme = F2(
	function (themeName, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var appState = model.bJ;
		var newAppState = _Utils_update(
			appState,
			{cW: themeName});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{bJ: newAppState}),
			cmd);
	});
var $author$project$I18N$replaceTranslator = F2(
	function (langCode, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var tr = A2(
			$ChristophP$elm_i18next$I18Next$tr,
			$author$project$I18N$translations(langCode),
			$ChristophP$elm_i18next$I18Next$Curly);
		var t = $ChristophP$elm_i18next$I18Next$t(
			$author$project$I18N$translations(langCode));
		var appState = model.bJ;
		var newAppState = _Utils_update(
			appState,
			{cb: langCode, aX: t, cY: tr});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{bJ: newAppState}),
			cmd);
	});
var $author$project$Types$Page$PT_SystemParams = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$System$Config$Types$SS_Battery = 6;
var $author$project$Page$System$Config$Types$SS_Congrat = 3;
var $author$project$Page$System$Config$Types$SS_Custom = 2;
var $author$project$Page$System$Config$Types$SS_Details = 4;
var $author$project$Page$System$Config$Types$SS_Master = 1;
var $author$project$Page$System$Config$Types$SS_NameAndIcon = 5;
var $author$project$API$System$getParams = function (sysId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'cmd',
				$elm$json$Json$Encode$string('system_params')),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(sysId))
			]));
};
var $author$project$Page$System$Config$loadParams = function (sysId) {
	return $author$project$API$websocketOut(
		$author$project$API$System$getParams(sysId));
};
var $author$project$Page$System$Config$Master$Types$MasterPage2 = 1;
var $author$project$Page$System$Config$Master$Types$MasterPage3 = 2;
var $author$project$Page$System$Config$Master$Types$MasterPage4 = 3;
var $author$project$Page$System$Config$Master$Types$MasterPage5 = 4;
var $author$project$Page$System$Config$Master$Types$masterNextPage = function (showMasterDialog) {
	switch (showMasterDialog) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 0;
	}
};
var $author$project$Page$System$Config$Master$Types$masterPrevPage = function (showMasterDialog) {
	switch (showMasterDialog) {
		case 4:
			return 3;
		case 3:
			return 2;
		case 2:
			return 1;
		case 1:
			return 0;
		default:
			return 0;
	}
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$API$System$paramQueue = F2(
	function (sysId, queue) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string('params_queue')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(sysId)),
					_Utils_Tuple2(
					'value',
					A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$string, queue))
				]));
	});
var $author$project$Page$System$Config$paramsSetQueue = F2(
	function (sysId, newQueue) {
		return $author$project$API$websocketOut(
			A2($author$project$API$System$paramQueue, sysId, newQueue));
	});
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$API$System$resetBattery = F2(
	function (sysId, capacity) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string('system_reset_battery')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(sysId)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$float(
						A2(
							$elm$core$Maybe$withDefault,
							5800,
							$elm$core$String$toFloat(capacity))))
				]));
	});
var $author$project$API$Document$updateDocumentRequest = F2(
	function (name, query) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string('update_document')),
					_Utils_Tuple2(
					'collection',
					$elm$json$Json$Encode$string(name)),
					_Utils_Tuple2('query', query)
				]));
	});
var $author$project$API$System$setBatteryCapacity = F2(
	function (sysId, capacity) {
		return A2(
			$author$project$API$Document$updateDocumentRequest,
			'system',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string(sysId)),
						_Utils_Tuple2(
						'path',
						$elm$json$Json$Encode$string('battery.init_capacity')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$float(
							A2(
								$elm$core$Maybe$withDefault,
								5800,
								$elm$core$String$toFloat(capacity))))
					])));
	});
var $author$project$Page$System$Config$Master$Types$setMasterDataEco = F2(
	function (v, m) {
		return _Utils_update(
			m,
			{ch: v});
	});
var $author$project$Page$System$Config$Master$Types$setMasterDataSecur = F3(
	function (val, s, m) {
		var _v0 = m.ci;
		var s1 = _v0.a;
		var s2 = _v0.b;
		if (val === 1) {
			return _Utils_update(
				m,
				{
					ci: _Utils_Tuple2(s, s2)
				});
		} else {
			return _Utils_update(
				m,
				{
					ci: _Utils_Tuple2(s1, s)
				});
		}
	});
var $author$project$Page$System$Config$Master$Types$setMasterDataSmsEvent = F3(
	function (updater, s, m) {
		return _Utils_update(
			m,
			{
				I: A2(updater, s, m.I)
			});
	});
var $author$project$Page$System$Config$Master$Types$setMasterDataTrack = F2(
	function (v, m) {
		return _Utils_update(
			m,
			{cj: v});
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$API$System$setSystemExecutor = F2(
	function (sysId, newExecutor) {
		return A2(
			$author$project$API$Document$updateDocumentRequest,
			'system',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string(sysId)),
						_Utils_Tuple2(
						'path',
						$elm$json$Json$Encode$string('executor')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$bool(newExecutor))
					])));
	});
var $author$project$API$System$setSystemIcon = F2(
	function (sysId, newIcon) {
		return A2(
			$author$project$API$Document$updateDocumentRequest,
			'system',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string(sysId)),
						_Utils_Tuple2(
						'path',
						$elm$json$Json$Encode$string('icon')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$string(newIcon))
					])));
	});
var $author$project$API$System$setSystemPhone = F2(
	function (sysId, newPhone) {
		return A2(
			$author$project$API$Document$updateDocumentRequest,
			'system',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string(sysId)),
						_Utils_Tuple2(
						'path',
						$elm$json$Json$Encode$string('phone_manual')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$string(newPhone))
					])));
	});
var $author$project$API$System$setSystemTitle = F2(
	function (sysId, newTitle) {
		return A2(
			$author$project$API$Document$updateDocumentRequest,
			'system',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string(sysId)),
						_Utils_Tuple2(
						'path',
						$elm$json$Json$Encode$string('title')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$string(newTitle))
					])));
	});
var $author$project$Page$System$Config$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var oldTitle = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bl: oldTitle, aV: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				var enteredTitle = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bl: enteredTitle}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var sysId = msg.a;
				var newTitle = msg.b;
				var cmd = $author$project$API$websocketOut(
					A2($author$project$API$System$setSystemTitle, sysId, newTitle));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aV: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[cmd])),
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aV: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var oldIcon = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bj: oldIcon, aS: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 6:
				var sysId = msg.a;
				var newIcon = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aS: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								A2($author$project$API$System$setSystemIcon, sysId, newIcon))
							])),
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aS: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 5:
				var selectedIcon = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bj: selectedIcon}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: A2($author$project$Page$System$Config$Master$Types$setMasterDataEco, val, model.u)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 11:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: A2($author$project$Page$System$Config$Master$Types$setMasterDataTrack, val, model.u)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 12:
				var val = msg.a;
				var s = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: A3($author$project$Page$System$Config$Master$Types$setMasterDataSecur, val, s, model.u)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 13:
				var updater = msg.a;
				var s = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: A3($author$project$Page$System$Config$Master$Types$setMasterDataSmsEvent, updater, s, model.u)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							X: $author$project$Page$System$Config$Master$Types$masterNextPage(model.X)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 15:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							X: $author$project$Page$System$Config$Master$Types$masterPrevPage(model.X)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 21:
				var sid = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bq: sid, ar: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 22:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ar: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 23:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ar: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$Msg$RemoveSystemFromList(model.bq)));
			case 24:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cO: s}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 25:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c_: s}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 26:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bI: s}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 27:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bH: s}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 8:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aP: false, X: 0, y: 1}),
					$author$project$Page$System$Config$loadParams(s),
					$elm$core$Maybe$Nothing);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 17:
				var sysId = msg.a;
				var queue = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: 3}),
					A2($author$project$Page$System$Config$paramsSetQueue, sysId, queue),
					$elm$core$Maybe$Nothing);
			case 18:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: 4}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 35:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: 5}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 36:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: 6}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 19:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aP: !model.aP}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 16:
				var s = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aq: false, y: 2}),
					$author$project$Page$System$Config$loadParams(s),
					$elm$core$Maybe$Nothing);
			case 28:
				var sysId = msg.a;
				var name = msg.b;
				var value = msg.c;
				var description = msg.d;
				var showParamChangeDialog = {di: description, cn: name, ec: sysId, eu: value};
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							P: $elm$core$Maybe$Just(showParamChangeDialog)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 29:
				var sysId = msg.a;
				var queue = msg.b;
				var name = msg.c;
				var newQueue = A2($elm$core$Dict$remove, name, queue);
				return _Utils_Tuple3(
					model,
					A2($author$project$Page$System$Config$paramsSetQueue, sysId, newQueue),
					$elm$core$Maybe$Nothing);
			case 30:
				var value = msg.a;
				var _v1 = model.P;
				if (_v1.$ === 1) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				} else {
					var showParamChangeDialog = _v1.a;
					var newShowParamChangeDialog = _Utils_update(
						showParamChangeDialog,
						{eu: value});
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								P: $elm$core$Maybe$Just(newShowParamChangeDialog)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 31:
				var sysId = msg.a;
				var oldQueue = msg.b;
				var name = msg.c;
				var value = msg.d;
				var newQueue = A3($elm$core$Dict$insert, name, value, oldQueue);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: $elm$core$Maybe$Nothing}),
					A2($author$project$Page$System$Config$paramsSetQueue, sysId, newQueue),
					$elm$core$Maybe$Nothing);
			case 32:
				var sysId = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: $elm$core$Maybe$Nothing, aq: false}),
					A2($author$project$Page$System$Config$paramsSetQueue, sysId, $elm$core$Dict$empty),
					$elm$core$Maybe$Nothing);
			case 33:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aq: !model.aq}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 34:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 37:
				var capacity = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: capacity}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 38:
				var sysId = msg.a;
				var capacity = msg.b;
				var cmd = function () {
					var _v2 = model.ah;
					switch (_v2.$) {
						case 0:
							return $elm$core$Platform$Cmd$none;
						case 1:
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$API$websocketOut(
										A2($author$project$API$System$resetBattery, sysId, capacity))
									]));
						default:
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$API$websocketOut(
										A2($author$project$API$System$setBatteryCapacity, sysId, capacity))
									]));
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: $author$project$Page$System$Config$Types$BC_None}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 39:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: $author$project$Page$System$Config$Types$BC_None}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 40:
				var sysId = msg.a;
				var value = msg.b;
				var cmd = $author$project$API$websocketOut(
					A2($author$project$API$System$setSystemExecutor, sysId, value));
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[cmd])),
					$elm$core$Maybe$Nothing);
			case 41:
				var oldPhone = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bk: oldPhone, aR: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 42:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aR: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 43:
				var enteredPhone = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bk: enteredPhone}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 44:
				var sysId = msg.a;
				var newPhone = msg.b;
				var cmd = $author$project$API$websocketOut(
					A2($author$project$API$System$setSystemPhone, sysId, newPhone));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aR: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[cmd])),
					$elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Page$System$Config$Types$BC_Capacity = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$System$Config$Types$OnBatteryCapacityCancel = {$: 39};
var $author$project$Page$System$Config$Types$OnBatteryCapacityConfirm = F2(
	function (a, b) {
		return {$: 38, a: a, b: b};
	});
var $author$project$Page$System$Config$Types$OnBatteryChange = function (a) {
	return {$: 37, a: a};
};
var $author$project$Page$System$Config$Dialogs$batteryChangeCapacityDialogView = F4(
	function (_v0, model, sysId, capacity) {
		var t = _v0.aX;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg modal-change-capacity show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content modal-md')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnBatteryCapacityCancel)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Емкость батареи'))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.bat_ch_capacity'))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-st modal-input')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-sm-label')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('config.Укажите начальную емкость батареи (мАч)'))
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('number'),
																A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
																$elm$html$Html$Attributes$pattern('[0-9]*'),
																A2($elm$html$Html$Attributes$attribute, 'inputmode', 'numeric'),
																$elm$html$Html$Attributes$value(capacity),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$Page$System$Config$Types$OnBatteryChange, $author$project$Page$System$Config$Types$BC_Capacity))
															]),
														_List_Nil)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnBatteryCapacityCancel)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
														$elm$html$Html$Events$onClick(
														A2($author$project$Page$System$Config$Types$OnBatteryCapacityConfirm, sysId, capacity))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Применить'))
													]))
											]))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Types$BC_Change = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$System$Config$Dialogs$batteryReplaceDialogView = F4(
	function (_v0, model, sysId, capacity) {
		var t = _v0.aX;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg modal-change-battery show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content modal-md')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnBatteryCapacityCancel)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Замена батареи'))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.bat_replace_text'))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-st modal-input')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-sm-label')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('config.Укажите начальную емкость батареи (мАч)'))
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('number'),
																A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
																$elm$html$Html$Attributes$pattern('[0-9]*'),
																A2($elm$html$Html$Attributes$attribute, 'inputmode', 'numeric'),
																$elm$html$Html$Attributes$value(capacity),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$Page$System$Config$Types$OnBatteryChange, $author$project$Page$System$Config$Types$BC_Change))
															]),
														_List_Nil)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnBatteryCapacityCancel)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
														$elm$html$Html$Events$onClick(
														A2($author$project$Page$System$Config$Types$OnBatteryCapacityConfirm, sysId, capacity))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Применить'))
													]))
											]))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Dialogs$batteryMaintanceDialogView = F3(
	function (appState, model, sysId) {
		var _v0 = model.ah;
		switch (_v0.$) {
			case 0:
				return _List_Nil;
			case 1:
				var capacity = _v0.a;
				return A4($author$project$Page$System$Config$Dialogs$batteryReplaceDialogView, appState, model, sysId, capacity);
			default:
				var capacity = _v0.a;
				return A4($author$project$Page$System$Config$Dialogs$batteryChangeCapacityDialogView, appState, model, sysId, capacity);
		}
	});
var $author$project$Page$System$Config$Types$OnEditPhoneCancel = {$: 42};
var $author$project$Page$System$Config$Types$OnPhoneChange = function (a) {
	return {$: 43, a: a};
};
var $author$project$Page$System$Config$Types$OnPhoneConfirm = F2(
	function (a, b) {
		return {$: 44, a: a, b: b};
	});
var $author$project$Page$System$Config$Dialogs$editPhoneDialogView = F3(
	function (_v0, model, sysId) {
		var t = _v0.aX;
		return model.aR ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg modal-change-name show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnEditPhoneCancel)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Номер телефона'))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-st modal-input')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-sm-label')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('config.Введите номер телефона'))
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
																$elm$html$Html$Attributes$value(model.bk),
																$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnPhoneChange)
															]),
														_List_Nil)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnEditPhoneCancel)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
														$elm$html$Html$Events$onClick(
														A2($author$project$Page$System$Config$Types$OnPhoneConfirm, sysId, model.bk))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Сохранить'))
													]))
											]))
									]))
							]))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$System$Config$Types$OnIconCancel = {$: 7};
var $author$project$Page$System$Config$Types$OnIconChange = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$System$Config$Types$OnIconConfirm = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$System$Config$Dialogs$iconChangeDialogView = F3(
	function (_v0, model, sysId) {
		var t = _v0.aX;
		if (model.aS) {
			var icon = F2(
				function (isActive, v) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('config-select-item select-icon-item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'active',
										_Utils_eq(model.bj, v))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$Page$System$Config$Types$OnIconChange(v))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('select-icon-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon-' + (v + ' image-big'))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon-' + (v + '_icon image-logo'))
											]),
										_List_Nil)
									]))
							]));
				});
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-bg modal-select-icon show')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-wr')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
													$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnIconCancel)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Иконка Феникса'))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-body')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Выберите подходящую иконку для вашего феникса'))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('select-icon-wr')
														]),
													_List_fromArray(
														[
															A2(icon, true, 'car'),
															A2(icon, false, 'truck'),
															A2(icon, false, 'taxi'),
															A2(icon, false, 'emergency'),
															A2(icon, false, 'fire_engine'),
															A2(icon, false, 'wagon'),
															A2(icon, false, 'dump_truck'),
															A2(icon, false, 'concrete_mixer'),
															A2(icon, false, 'bus')
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-btn-group')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
															$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnIconCancel)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Отмена'))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
															$elm$html$Html$Events$onClick(
															A2($author$project$Page$System$Config$Types$OnIconConfirm, sysId, model.bj))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Сохранить'))
														]))
												]))
										]))
								]))
						]))
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Page$System$Config$Types$OnCancelParam = {$: 34};
var $author$project$Page$System$Config$Types$OnChangeParamValue = function (a) {
	return {$: 30, a: a};
};
var $author$project$Page$System$Config$Types$OnConfirmParam = F4(
	function (a, b, c, d) {
		return {$: 31, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$System$Config$Dialogs$paramChangeDialogView = F3(
	function (_v0, model, mparams) {
		var t = _v0.aX;
		var oldQueue = function () {
			if (mparams.$ === 1) {
				return $elm$core$Dict$empty;
			} else {
				var queue = mparams.a.d_;
				return queue;
			}
		}();
		var _v1 = model.P;
		if (_v1.$ === 1) {
			return _List_Nil;
		} else {
			var sysId = _v1.a.ec;
			var name = _v1.a.cn;
			var value = _v1.a.eu;
			var description = _v1.a.di;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-bg modal-axelerometr show')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-wr')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-content modal-md')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
													$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnCancelParam)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(description)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-body')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-st modal-input')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
																	$elm$html$Html$Attributes$value(value),
																	$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnChangeParamValue)
																]),
															_List_Nil)
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-btn-group')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
															$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnCancelParam)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Отмена'))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
															$elm$html$Html$Events$onClick(
															A4($author$project$Page$System$Config$Types$OnConfirmParam, sysId, oldQueue, name, value))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Применить'))
														]))
												]))
										]))
								]))
						]))
				]);
		}
	});
var $author$project$Page$System$Config$Types$OnTitleCancel = {$: 3};
var $author$project$Page$System$Config$Types$OnTitleChange = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$System$Config$Types$OnTitleConfirm = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$System$Config$Dialogs$titleChangeDialogView = F3(
	function (_v0, model, sysId) {
		var t = _v0.aX;
		return model.aV ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg modal-change-name show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnTitleCancel)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Название Феникса'))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-st modal-input')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('input-sm-label')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('config.Введите название'))
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
																$elm$html$Html$Attributes$value(model.bl),
																$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnTitleChange)
															]),
														_List_Nil)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnTitleCancel)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
														$elm$html$Html$Events$onClick(
														A2($author$project$Page$System$Config$Types$OnTitleConfirm, sysId, model.bl))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Сохранить'))
													]))
											]))
									]))
							]))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$System$Config$Types$OnMasterCustom = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$System$Config$Types$OnOpenBattery = function (a) {
	return {$: 36, a: a};
};
var $author$project$Page$System$Config$Types$OnOpenDetails = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$System$Config$Types$OnOpenNameAndIcon = function (a) {
	return {$: 35, a: a};
};
var $author$project$Page$System$Config$Types$OnStartMaster = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$System$Config$Types$OnClearQueue = function (a) {
	return {$: 32, a: a};
};
var $author$project$Page$System$Config$Types$OnShowQueue = {$: 33};
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$System$Config$Custom$footer = F3(
	function (_v0, sysId, queue) {
		var t = _v0.aX;
		var _v1 = $elm$core$Dict$isEmpty(queue);
		if (_v1) {
			return _List_Nil;
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-footer setting-footer')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#'),
									$elm$html$Html$Attributes$class('red-text cursor-pointer details-footer-btn modal-open-cancel'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$System$Config$Types$OnClearQueue(sysId))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-remove')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('config.Отменить')),
											$elm$html$Html$text(' '),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mob-hidde')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.изменения'))
												]))
										]))
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#'),
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									$elm$html$Html$Attributes$class('blue-text cursor-pointer details-footer-btn modal-open-changes'),
									$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnShowQueue)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-eye_active')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('config.Показать')),
											$elm$html$Html$text(' '),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mob-hidde')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.изменения'))
												]))
										]))
								]))
						]))
				]);
		}
	});
var $author$project$Page$System$Config$Types$OnRestoreParam = F3(
	function (a, b, c) {
		return {$: 29, a: a, b: b, c: c};
	});
var $author$project$Page$System$Config$Types$OnStartEditParam = F4(
	function (a, b, c, d) {
		return {$: 28, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$System$Config$ParamDesc$PDI = F2(
	function (title, ptype) {
		return {dZ: ptype, ej: title};
	});
var $author$project$Page$System$Config$ParamDesc$PDIE_Desc = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$System$Config$ParamDesc$PDIE_Link = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$System$Config$ParamDesc$PD_Enum = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$System$Config$ParamDesc$PD_Int = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$System$Config$ParamDesc$PD_String = {$: 0};
var $author$project$Page$System$Config$ParamDesc$paramDesc = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'accel.count',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Длительность импульса акселерометра, ms', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'accel.deb',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Программный антидребезг, число повторов (2-6)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 10))),
			_Utils_Tuple2(
			'accel.lvl',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Чувствительность акселерометра, mg',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 1000))),
			_Utils_Tuple2(
			'accel.time',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время успокоения акселерметра, секунды (10-300)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 600))),
			_Utils_Tuple2(
			'adc.fix.du',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Порог изменения напряжения резервного питания, V (?-?)', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'adc.fix.dub',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Порог изменения напряжения основного питания, V (?-?)', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'adc.fix.umax',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Порог максимально допустимого питания, /29.528, V (350-1200)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 300, 1240))),
			_Utils_Tuple2(
			'adc.in.1',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Логический порог напряжения входа 1, /?, V (?-?)', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'adc.in.2',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Логический порог напряжения входа 2, /?, V (?-?)', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'adc.photo',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Чувствительность фотодатчика, (100-2000; 2000-max)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 2047))),
			_Utils_Tuple2(
			'adc.photo.delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время включения фотоохраны, сек (1-32000)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 32767))),
			_Utils_Tuple2(
			'photo.delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время включения фотоохраны, сек (1-32000)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 32767))),
			_Utils_Tuple2(
			'adc.u.off',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Порог выключения основного питания, /29.528, V (100-1000)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 1240))),
			_Utils_Tuple2(
			'adc.u.on',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Порог включения основного питания, /29.528, V (100-1000)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 1240))),
			_Utils_Tuple2(
			'akkum.block.vbat',
			A2($author$project$Page$System$Config$ParamDesc$PDI, '-- добавить описание ---', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'akkum.block.vdd',
			A2($author$project$Page$System$Config$ParamDesc$PDI, '-- добавить описание ---', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'akkum.charge.0',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Индикация полного разряда батареи, /310.29, V (1050-1150)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1050, 1150))),
			_Utils_Tuple2(
			'akkum.charge.30',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Индикация заряда батареи 30%, /310.29, V (1100-1200)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1000, 1300))),
			_Utils_Tuple2(
			'akkum.charge.60',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Индикация заряда батареи 60%, /310.29, V (1150-1250)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1000, 1300))),
			_Utils_Tuple2(
			'akkum.charge.temp',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Максимальная температура батареи, /?, градусов (?-?)', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'akkum.i.0',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'?Начальный ток заряда батареи, х 20.408 mA (1-10)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 100))),
			_Utils_Tuple2(
			'akkum.i.1',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'?Максимальный ток заряда батареи, х 20.408 mA (10-100)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 100))),
			_Utils_Tuple2(
			'akkum.i.charge',
			A2($author$project$Page$System$Config$ParamDesc$PDI, '-- добавить описание ---', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'akkum.u.0',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'?Минимальное напряжение для обновления прошивки, /310.29, V (1100-1300)',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1000, 1300))),
			_Utils_Tuple2(
			'sleep',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Время сна в режимах Ожидание и Поиск, мин', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'adc.vi.fix',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Коррекция показаний резервного питания.', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'admin',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Телефон администратора.', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'alarm.balance',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о критическом балансе',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.case',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о вскрытии корпуса',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о включении дежурного режима',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.low',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о низком уровне ёмкости батареи',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.error',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о невозможности определения координат',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.gps',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS об изменении координат',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.mode',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о смене режима',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.on',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о включении Феникса',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.off',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS о выключении Феникса',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'alarm.stealth',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'SMS об активации режима Stealth',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm1')),
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm2')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm3')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Link('alarm4'))
						])))),
			_Utils_Tuple2(
			'admin',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Номер телефона администратора', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'alarm1',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Первый номер телефона для отправки SMS', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'alarm2',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Второй номер телефона для отправки SMS', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'alarm3',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Третий номер телефона для отправки SMS', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'alarm4',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Четвертый номер телефона для отправки SMS', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'auto.sleep',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Автоматическое включение режима Ожидание, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 60, 1440))),
			_Utils_Tuple2(
			'balance.skip',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Пропуск цифр в отчёте оператора о балансе',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 0, 128))),
			_Utils_Tuple2(
			'balance.ussd',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'USSD-запрос остатка средств на карточке', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'config.send',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период сеансов связи в режиме Конфигуратор, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 60))),
			_Utils_Tuple2(
			'delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Задержка выключения GSM-модуля, в циклах связи',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 0, 60))),
			_Utils_Tuple2(
			'distance',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Максимально допустимое отклонение координат, м',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 1000))),
			_Utils_Tuple2(
			'gps.angle',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Минимальный регистрируемый угол поворота, градусов',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 5, 90))),
			_Utils_Tuple2(
			'gps.aoff',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Автовыключение GPS для экономии батареи, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 600))),
			_Utils_Tuple2(
			'gps.delta',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Принудительная регистрация координат при перемещении, м',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 10000))),
			_Utils_Tuple2(
			'gps.fail',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Задержка перезапуска GPS-модуля при пропадании спутников, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 60))),
			_Utils_Tuple2(
			'gps.flush.move',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период отправки данных на сервер при движении, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 600))),
			_Utils_Tuple2(
			'gps.flush.stop',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период отправки данных на сервер при стоянке, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 600))),
			_Utils_Tuple2(
			'gps.flush',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период отправки данных на сервер, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 600))),
			_Utils_Tuple2(
			'gps.tf.move',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Принудительная регистрация координат при движении, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 120))),
			_Utils_Tuple2(
			'gps.tf.stop',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период регистрации координат при остановке, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 120))),
			_Utils_Tuple2(
			'gps.tf',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период регистрации координат, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 120))),
			_Utils_Tuple2(
			'gps.tf.stop.acc',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период регистрации координат при стоянке, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 30, 120))),
			_Utils_Tuple2(
			'gps.valid.delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Данные от GPS берутся не первые после fix, а пропускается указанное кол-во',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 0, 120))),
			_Utils_Tuple2(
			'gps.vignacc',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Скорость принудительной регистрации движения (игнорируется акселерометр) × 0,01852 км/ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 30000))),
			_Utils_Tuple2(
			'gps.vstart',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Скорость регистрации начала движения × 0,01852 км/ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 30000))),
			_Utils_Tuple2(
			'gps.vstop',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Скорость регистрирации остановки объекта × 0,01852 км/ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 30000))),
			_Utils_Tuple2(
			'gps.vsmart',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Скорость больше которой запускается процедура интеллектуальной блокировки × 0,01852 км/ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 100, 30000))),
			_Utils_Tuple2(
			'gps.data.1',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Назначение дополнительного поля данных GPS',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'2',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Погрешность GPS')),
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Фотодатчик')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Температура'))
						])))),
			_Utils_Tuple2(
			'gps.wait',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время ожидания обнаружения спутников, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 1440))),
			_Utils_Tuple2(
			'gsm.apn',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Программируеная точка входа в Интернет - APN', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'gsm.balance.period',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период контроля баланса, ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 720))),
			_Utils_Tuple2(
			'gsm.flags',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Работа в роуминге, в том числе, в национальном',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 0, 1))),
			_Utils_Tuple2(
			'gsm.lagtime',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Период проверки GSM-модуля на предмет зависания, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 120))),
			_Utils_Tuple2(
			'gsm.predelay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время проверки GSM-ретрансляторов и ожидания SMS, мин',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 3, 120))),
			_Utils_Tuple2(
			'gsm.user',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Имя пользователя для GPRS-доступа', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'gsm.pwd',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Пароль для GPRS-доступа', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'limit',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Минимально допустимый баланс SIM-карты',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 10, 30000))),
			_Utils_Tuple2(
			'link.delay',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Время удержания кнопки для привязки, сек',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 3, 10))),
			_Utils_Tuple2(
			'mode',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Режим работы',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'3',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Поиск')),
							_Utils_Tuple2(
							'4',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Рыбалка (шутка)')),
							_Utils_Tuple2(
							'5',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Ожидание')),
							_Utils_Tuple2(
							'6',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Конфигуратор'))
						])))),
			_Utils_Tuple2(
			'photo',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Фотодатчик',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключен')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Включен'))
						])))),
			_Utils_Tuple2(
			'secur.code',
			A2($author$project$Page$System$Config$ParamDesc$PDI, 'Код безопасности для SMS-команд', $author$project$Page$System$Config$ParamDesc$PD_String)),
			_Utils_Tuple2(
			'sms.confirm',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Оповещение о выполнении команды смены режима.',
				$author$project$Page$System$Config$ParamDesc$PD_Enum(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'0',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Выключено')),
							_Utils_Tuple2(
							'1',
							$author$project$Page$System$Config$ParamDesc$PDIE_Desc('Включено'))
						])))),
			_Utils_Tuple2(
			'timezone',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Разница между текущим и мировым часовыми поясами, ч',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 24))),
			_Utils_Tuple2(
			'vin.low',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Напряжение для сообщения о разряде батареи, V',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 24))),
			_Utils_Tuple2(
			'vin.off',
			A2(
				$author$project$Page$System$Config$ParamDesc$PDI,
				'Напряжение автоматического выключения Феникса, V',
				A2($author$project$Page$System$Config$ParamDesc$PD_Int, 1, 24)))
		]));
var $author$project$Page$System$Config$ParamDesc$description = function (name) {
	var _v0 = A2($elm$core$Dict$get, name, $author$project$Page$System$Config$ParamDesc$paramDesc);
	if (_v0.$ === 1) {
		return '{' + (name + '}');
	} else {
		var title = _v0.a.ej;
		return title;
	}
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$Page$System$Config$Custom$paramsWidget = F4(
	function (_v0, sysId, params, showQueue) {
		var t = _v0.aX;
		var prow = F2(
			function (queue, _v4) {
				var name = _v4.a;
				var type_ = _v4.b.es;
				var value = _v4.b.eu;
				var _default = _v4.b.dh;
				var valueField = function () {
					var _v3 = A2($elm$core$Dict$get, name, queue);
					if (_v3.$ === 1) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('setting-item')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('setting-item-header')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(value)
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('setting-edit open-modal-axelerometr'),
														$elm$html$Html$Events$onClick(
														A4(
															$author$project$Page$System$Config$Types$OnStartEditParam,
															sysId,
															name,
															value,
															$author$project$Page$System$Config$ParamDesc$description(name)))
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('icon-edit')
															]),
														_List_Nil)
													]))
											]))
									]))
							]);
					} else {
						var expect = _v3.a;
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('setting-item setting-changed')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('setting-item-header')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('name')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(value)
															])),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('setting-changed-data orange-gradient-text')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon-arrow_next')
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(expect)
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('details-btn-title red-text modal-open-cancel'),
														$elm$html$Html$Events$onClick(
														A3($author$project$Page$System$Config$Types$OnRestoreParam, sysId, queue, name))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													]))
											]))
									]))
							]);
					}
				}();
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('params.' + name))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('setting-item-wr')
								]),
							valueField)
						]));
			});
		var data = function () {
			if (!showQueue) {
				return params.dg;
			} else {
				return A2(
					$elm$core$List$filter,
					function (_v2) {
						var name = _v2.a;
						return A2($elm$core$Dict$member, name, params.d_);
					},
					params.dg);
			}
		}();
		return A2(
			$elm$core$List$map,
			prow(params.d_),
			data);
	});
var $author$project$Page$System$Config$Custom$configCustomView = F4(
	function (appState, model, sysId, sysparams) {
		var t = appState.aX;
		if (sysparams.$ === 1) {
			return _Utils_ap(
				_List_fromArray(
					[
						$author$project$Components$UI$row(
						_List_fromArray(
							[
								$author$project$Components$UI$text(
								t('config.error_custom'))
							]))
					]),
				A3($author$project$Page$System$Config$Custom$footer, appState, sysId, $elm$core$Dict$empty));
		} else {
			var params = sysparams.a;
			return _Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details-header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('menu.Расширенные настройки'))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details-items general-setting-wr scroll scroll-height')
							]),
						_Utils_ap(
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('warning')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('warning-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('warning-title')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$alt(''),
																	$elm$html$Html$Attributes$src('/images/warning.svg')
																]),
															_List_Nil),
															$elm$html$Html$text(
															t('config.Предупреждение!'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('warning-text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.warning_custom'))
														]))
												]))
										]))
								]),
							A4($author$project$Page$System$Config$Custom$paramsWidget, appState, sysId, params, model.aq)))
					]),
				A3($author$project$Page$System$Config$Custom$footer, appState, sysId, params.d_));
		}
	});
var $author$project$Page$System$Config$Master$Widget$hint_row = function (s) {
	return $author$project$Components$UI$row(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col')
					]),
				_List_fromArray(
					[
						$author$project$Components$UI$text(s)
					]))
			]));
};
var $author$project$Page$System$Config$Master$Types$M_ECO_MAX = 0;
var $author$project$Page$System$Config$Master$Types$M_ECO_MIN = 2;
var $author$project$Page$System$Config$Types$OnMasterEco1 = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$System$Config$Types$OnMasterNext = {$: 14};
var $author$project$Page$System$Config$Master$masterDialogView1 = F4(
	function (_v0, model, sysId, params) {
		var t = _v0.aX;
		var ev = model.u.ch;
		var it = function (v) {
			return $elm$html$Html$div(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('config-select-item', true),
								_Utils_Tuple2(
								'active',
								_Utils_eq(ev, v))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$Page$System$Config$Types$OnMasterEco1(v))
					]));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title-st')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Период выхода на связь'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-select')
					]),
				_List_fromArray(
					[
						A2(
						it,
						0,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Редко'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Феникс будет выходить на связь один раз в сутки.'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Ожидаемый срок службы батареи - 15 лет.'))
											]))
									]))
							])),
						A2(
						it,
						1,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Оптимально'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Феникс будет выходить на связь каждые 6 часов.'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Ожидаемый срок службы батареи - 6 лет.'))
											]))
									]))
							])),
						A2(
						it,
						2,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Часто'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Феникс будет выходить на связь каждые 2 часа.'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Ожидаемый срок службы батареи - 2 года.'))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next mt-40'),
						$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterNext)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Далее'))
					]))
			]);
	});
var $author$project$Page$System$Config$Master$Types$M_TRACK_MAX = 2;
var $author$project$Page$System$Config$Master$Types$M_TRACK_MIN = 0;
var $author$project$Page$System$Config$Types$OnMasterPrev = {$: 15};
var $author$project$Page$System$Config$Types$OnMasterTrack1 = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$System$Config$Master$masterDialogView2 = F4(
	function (_v0, model, sysId, params) {
		var t = _v0.aX;
		var ev = model.u.cj;
		var it = function (v) {
			return $elm$html$Html$div(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('config-select-item', true),
								_Utils_Tuple2(
								'active',
								_Utils_eq(ev, v))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$Page$System$Config$Types$OnMasterTrack1(v))
					]));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title-st')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Время работы в режиме Поиск'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-select')
					]),
				_List_fromArray(
					[
						A2(
						it,
						0,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Продолжительно'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Максимальное время работы в режиме Поиск - ')),
												$elm$html$Html$text(
												t('config.7 суток')),
												$elm$html$Html$text('.')
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Рекомендуется для охраны автомобиля на длительной стоянке.'))
											]))
									]))
							])),
						A2(
						it,
						1,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Оптимально'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Максимальное время работы в режиме Поиск - ')),
												$elm$html$Html$text(
												t('config.24 часа')),
												$elm$html$Html$text('.')
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Рекомендуется для наиболее вероятного обнаружения угнанного автомобиля.'))
											]))
									]))
							])),
						A2(
						it,
						2,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('config-select-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Минимально'))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Максимальное время работы в режиме Поиск - ')),
												$elm$html$Html$text(
												t('config.6 часов')),
												$elm$html$Html$text('.')
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Рекомендуется для максимальной экономии энергии батареи питания.'))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('wrapper-content-btn btn-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterPrev)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Назад'))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterNext)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Далее'))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Types$OnMasterSMSEvent = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Page$System$Config$Types$OnSMSPhone1 = function (a) {
	return {$: 24, a: a};
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Page$System$Config$Master$masterDialogView3 = F4(
	function (_v0, model, sysId, params) {
		var t = _v0.aX;
		var toggler = F3(
			function (ttl, v, u) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toggler')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('toggler-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.' + ttl))
								])),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('toggler-wr')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$checked(v),
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Events$onCheck(
											$author$project$Page$System$Config$Types$OnMasterSMSEvent(u))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('toggler-icon')
										]),
									_List_Nil)
								]))
						]));
			});
		var phone = model.cO;
		var _v1 = model.u.I;
		var balance = _v1.c9;
		var lowPower = _v1.be;
		var changeMode = _v1.a1;
		var moved = _v1.bi;
		var caseOpen = _v1.a0;
		var onOff = _v1.aM;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title-st')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Информирование'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('page-subtext')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Когда происходят определенные события, Феникс может отправлять SMS на заданный номер'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-sm-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Укажите номер телефона'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-set-phone input-st')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
								$elm$html$Html$Attributes$value(model.cO),
								$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnSMSPhone1)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-sm-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Выберите события'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('toggler-list'),
						$elm$html$Html$Attributes$disabled(true)
					]),
				_List_fromArray(
					[
						A3(
						toggler,
						'Критический остаток средств',
						balance,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{c9: v});
							})),
						A3(
						toggler,
						'Низкий уровень заряда батареи',
						lowPower,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{be: v});
							})),
						A3(
						toggler,
						'Изменение режима (Поиск <-> Ожидание)',
						changeMode,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{a1: v});
							})),
						A3(
						toggler,
						'Изменение местоположения (в режиме Поиск)',
						moved,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{bi: v});
							})),
						A3(
						toggler,
						'Включение и выключение Феникса',
						onOff,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{aM: v});
							})),
						A3(
						toggler,
						'Вскрытие корпуса',
						caseOpen,
						F2(
							function (v, m) {
								return _Utils_update(
									m,
									{a0: v});
							}))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('wrapper-content-btn btn-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterPrev)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Назад'))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterNext)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Далее'))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Types$OnUSSDPhone = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$System$Config$Master$masterDialogView4 = F4(
	function (_v0, model, sysId, params) {
		var t = _v0.aX;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title-st')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.Контроль баланса SIM-карты'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-sm-title config-ussd-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						t('config.USSD-запрос баланса SIM-карты'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('config-set-ussd input-st')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
								$elm$html$Html$Attributes$value(model.c_),
								$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnUSSDPhone)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('wrapper-content-btn btn-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterPrev)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Назад'))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
								$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterNext)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Далее'))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Types$OnAdminCode = function (a) {
	return {$: 27, a: a};
};
var $author$project$Page$System$Config$Types$OnAdminPhone = function (a) {
	return {$: 26, a: a};
};
var $author$project$Page$System$Config$Types$OnConfirmMaster = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Page$System$Config$Types$OnMasterSecur1 = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Page$System$Config$Types$OnShowChanges = {$: 19};
var $author$project$Page$System$Config$Master$Types$ecoToValue = function (v) {
	switch (v) {
		case 0:
			return '1440';
		case 1:
			return '240';
		default:
			return '120';
	}
};
var $author$project$Page$System$Config$Master$Types$trackToValue = function (v) {
	switch (v) {
		case 0:
			return '10080';
		case 1:
			return '1440';
		default:
			return '360';
	}
};
var $author$project$Page$System$Config$Master$changesList = function (model) {
	var masterData = model.u;
	var sp = function (f) {
		return f ? '1' : '0';
	};
	var _v0 = masterData.ci;
	var s1 = _v0.a;
	var s2 = _v0.b;
	var phone = function () {
		if (s1) {
			return model.bI;
		} else {
			return '';
		}
	}();
	var code = function () {
		if (s2) {
			return model.bH;
		} else {
			return '';
		}
	}();
	return $elm$core$Dict$fromList(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'sleep',
					$author$project$Page$System$Config$Master$Types$ecoToValue(masterData.ch)),
					_Utils_Tuple2(
					'auto.sleep',
					$author$project$Page$System$Config$Master$Types$trackToValue(masterData.cj)),
					_Utils_Tuple2('admin', phone),
					_Utils_Tuple2('secur.code', code),
					_Utils_Tuple2('alarm1', model.cO),
					_Utils_Tuple2('balance.ussd', model.c_)
				]),
			(model.cO !== '') ? _List_fromArray(
				[
					_Utils_Tuple2(
					'alarm.balance',
					sp(masterData.I.c9)),
					_Utils_Tuple2(
					'alarm.case',
					sp(masterData.I.a0)),
					_Utils_Tuple2(
					'alarm.low',
					sp(masterData.I.be)),
					_Utils_Tuple2(
					'alarm.mode',
					sp(masterData.I.a1)),
					_Utils_Tuple2(
					'alarm.on',
					sp(masterData.I.aM)),
					_Utils_Tuple2(
					'alarm.off',
					sp(masterData.I.aM)),
					_Utils_Tuple2(
					'alarm.gps',
					sp(masterData.I.bi))
				]) : _List_fromArray(
				[
					_Utils_Tuple2('alarm.balance', '0'),
					_Utils_Tuple2('alarm.case', '0'),
					_Utils_Tuple2('alarm.low', '0'),
					_Utils_Tuple2('alarm.mode', '0'),
					_Utils_Tuple2('alarm.on', '0'),
					_Utils_Tuple2('alarm.off', '0'),
					_Utils_Tuple2('alarm.gps', '0')
				])));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$System$Config$Master$Widget$changes_table = F2(
	function (_v0, dict) {
		var t = _v0.aX;
		var row = function (_v1) {
			var ttl = _v1.a;
			var val = _v1.b;
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('right-align'),
								A2($elm$html$Html$Attributes$style, 'width', '50%')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(ttl)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(val)
							]))
					]));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col s12 m10 offset-m1 l8 offset-l2 xl6 offset-xl3')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Следующие параметры будут изменены:')),
								A2(
								$elm$html$Html$table,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tbody,
										_List_Nil,
										A2(
											$elm$core$List$map,
											row,
											$elm$core$Dict$toList(dict)))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$System$Config$Master$showChanges = F3(
	function (appState, model, sysId) {
		var _v0 = model.aP;
		if (!_v0) {
			return _List_Nil;
		} else {
			return A2(
				$author$project$Page$System$Config$Master$Widget$changes_table,
				appState,
				$author$project$Page$System$Config$Master$changesList(model));
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Page$System$Config$Master$masterDialogView5 = F4(
	function (appState, model, sysId, params) {
		var t = appState.aX;
		var mixedQueue = A2(
			$elm$core$Dict$union,
			$author$project$Page$System$Config$Master$changesList(model),
			params.d_);
		var _v0 = model.u.ci;
		var s1 = _v0.a;
		var s2 = _v0.b;
		var phoneHelp = (s1 && (model.bI !== '')) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-subtext')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('config.Управление возможно только с телефона:')),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('+380 '),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('phoneText')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(model.bI)
								]))
						]))
				])) : A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-subtext')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('config.По умолчанию управление возможно с любого телефона.'))
				]));
		var smsHelp = (s2 && (model.bH !== '')) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-subtext')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('config.SMS-коды управления имеют вид:')),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('passwordText')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(model.bH)
						])),
					$elm$html$Html$text(' link')
				])) : A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-subtext')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('config.SMS-коды управления имеют вид:')),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('passwordText')
						]),
					_List_Nil),
					$elm$html$Html$text('link')
				]));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title-st')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('config.Безопасность'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('security-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('page-subtext')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Чтобы никто посторонний не смог получить управление Вашим Фениксом, вы можете привязать управление к конкретному телефону и установить свой код доступа.'))
								])),
							phoneHelp,
							smsHelp,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('config-security-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('toggler toggler-show-data')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('toggler-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Привязать к телефону'))
												])),
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('toggler-wr')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$id('bindingPhone'),
															$elm$html$Html$Attributes$type_('checkbox'),
															$elm$html$Html$Attributes$checked(s1),
															$elm$html$Html$Events$onCheck(
															$author$project$Page$System$Config$Types$OnMasterSecur1(1))
														]),
													_List_Nil),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('toggler-icon')
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('config-set-phone input-st'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('bindingPhoneShowed', !s1)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('phone-prefix')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+380')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
													$elm$html$Html$Attributes$id('bindingPhoneText'),
													A2($elm$html$Html$Attributes$attribute, 'maxlength', '9'),
													$elm$html$Html$Attributes$value(model.bI),
													$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnAdminPhone)
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('config-security-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('toggler toggler-show-data')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('toggler-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Установить пароль доступа'))
												])),
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('toggler-wr')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$id('setPassword'),
															$elm$html$Html$Attributes$type_('checkbox'),
															$elm$html$Html$Attributes$checked(s2),
															$elm$html$Html$Events$onCheck(
															$author$project$Page$System$Config$Types$OnMasterSecur1(2))
														]),
													_List_Nil),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('toggler-icon')
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input-st'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('setPasswordShowed', !s2)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-sm-label')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Вводите только латинские буквы и цифры'))
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
													$elm$html$Html$Attributes$id('setPasswordText'),
													$elm$html$Html$Attributes$value(model.bH),
													$elm$html$Html$Events$onInput($author$project$Page$System$Config$Types$OnAdminCode)
												]),
											_List_Nil)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-content-btn btn-group')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-prev'),
									$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnMasterPrev)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Назад'))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text'),
									$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnShowChanges)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('(?)')
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next'),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$System$Config$Types$OnConfirmMaster, sysId, mixedQueue))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Применить'))
								]))
						]))
				]),
			A3($author$project$Page$System$Config$Master$showChanges, appState, model, sysId));
	});
var $author$project$Page$System$Config$Master$Types$masterPageNumber = function (mp) {
	switch (mp) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Page$System$Config$Master$progressBar = function (progress) {
	var classed = function (pos) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('progress-item', true),
					_Utils_Tuple2(
					'active',
					_Utils_eq(progress, pos)),
					_Utils_Tuple2(
					'done',
					_Utils_cmp(progress, pos) > 0)
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('progress')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classed(1)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('progress-icon icon-clock')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classed(2)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('progress-icon icon-magnifier')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classed(3)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('progress-icon icon-phone')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classed(4)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('progress-icon icon-sim_logo')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classed(5)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('progress-icon icon-done')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$System$Config$Master$masterDialogView = F4(
	function (appState, model, sysId, mparams) {
		var t = appState.aX;
		if (mparams.$ === 1) {
			return _List_fromArray(
				[
					$author$project$Page$System$Config$Master$Widget$hint_row('Ошибка загрузки или данные от Феникса еще не получены.')
				]);
		} else {
			var params = mparams.a;
			var p = function () {
				var _v1 = model.X;
				switch (_v1) {
					case 0:
						return A4($author$project$Page$System$Config$Master$masterDialogView1, appState, model, sysId, params);
					case 1:
						return A4($author$project$Page$System$Config$Master$masterDialogView2, appState, model, sysId, params);
					case 2:
						return A4($author$project$Page$System$Config$Master$masterDialogView3, appState, model, sysId, params);
					case 3:
						return A4($author$project$Page$System$Config$Master$masterDialogView4, appState, model, sysId, params);
					default:
						return A4($author$project$Page$System$Config$Master$masterDialogView5, appState, model, sysId, params);
				}
			}();
			return _Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('title-sm-gr')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('config.Основные настройки феникса'))
							])),
						$author$project$Page$System$Config$Master$progressBar(
						$author$project$Page$System$Config$Master$Types$masterPageNumber(model.X))
					]),
				p);
		}
	});
var $author$project$Page$System$Config$Battery$height = function (percentage) {
	return A2($myrho$elm_round$Round$round, 0, percentage + 9) + '%';
};
var $author$project$Page$System$Config$Battery$batteryWidget = function (percentage) {
	var p_as_text = A2($myrho$elm_round$Round$round, 1, percentage) + '%';
	var colour = $author$project$Components$UI$Battery$powerLevelClass(percentage);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('power-status')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('power')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('power-top')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('power-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('power-bg'),
										$elm$html$Html$Attributes$class(colour),
										A2(
										$elm$html$Html$Attributes$attribute,
										'style',
										'height: ' + $author$project$Page$System$Config$Battery$height(percentage))
									]),
								_List_fromArray(
									[
										A2(
										$elm$svg$Svg$svg,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$attribute, 'preserveAspectRatio', 'xMinYMin meet'),
												$elm$svg$Svg$Attributes$viewBox('0 0 500 500')
											]),
										_List_fromArray(
											[
												A2(
												$elm$svg$Svg$path,
												_List_fromArray(
													[
														$elm$svg$Svg$Attributes$d('M0, 100 C150, 200 350, 0 500, 100 L500, 00 L0, 0 Z'),
														A2($elm$html$Html$Attributes$attribute, 'style', 'stroke:none; fill: #323343;')
													]),
												_List_Nil)
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('power-status-title'),
						$elm$html$Html$Attributes$class(colour)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(p_as_text)
					]))
			]));
};
var $author$project$Page$System$Config$Battery$beginAt = F2(
	function (_v0, value) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('config.Начало эксплуатации')),
							$elm$html$Html$text(':')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $ryannhg$date_format$DateFormat$DayOfMonthNumber = {$: 5};
var $ryannhg$date_format$DateFormat$dayOfMonthNumber = $ryannhg$date_format$DateFormat$DayOfMonthNumber;
var $ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryannhg$date_format$DateFormat$text = $ryannhg$date_format$DateFormat$Text;
var $author$project$Components$Dates$divToken = $ryannhg$date_format$DateFormat$text(' ');
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.bA, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.el(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		bS: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cl: month,
		c2: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bS;
	});
var $ryannhg$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $ryannhg$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryannhg$date_format$DateFormat$days)))));
	});
var $ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $ryannhg$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cl;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryannhg$date_format$DateFormat$months))));
	});
var $ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).c2;
	});
var $ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryannhg$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $elm$core$String$toLower = _String_toLower;
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$core$Basics$round = _Basics_round;
var $ryannhg$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryannhg$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.em(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.en(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.eo(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.ep(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Z(num));
				}(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryannhg$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryannhg$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {el: toAmPm, em: toMonthAbbreviation, en: toMonthName, Z: toOrdinalSuffix, eo: toWeekdayAbbreviation, ep: toWeekdayName};
	});
var $author$project$Components$Dates$EN$monthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'Augest';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $author$project$Components$Dates$EN$weekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'monday';
		case 1:
			return 'вторник';
		case 2:
			return 'среда';
		case 3:
			return 'четверг';
		case 4:
			return 'friday';
		case 5:
			return 'sat';
		default:
			return 'sunday';
	}
};
var $author$project$Components$Dates$EN$weekdayNameShort = function (weekday) {
	switch (weekday) {
		case 0:
			return 'пн';
		case 1:
			return 'вт';
		case 2:
			return 'ср';
		case 3:
			return 'чт';
		case 4:
			return 'пт';
		case 5:
			return 'сб';
		default:
			return 'вс';
	}
};
var $author$project$Components$Dates$EN$language = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$author$project$Components$Dates$EN$monthName,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Dates$EN$monthName,
		$elm$core$String$left(3)),
	$author$project$Components$Dates$EN$weekdayName,
	$author$project$Components$Dates$EN$weekdayNameShort,
	function (_v0) {
		return '';
	},
	function (_v1) {
		return '';
	});
var $author$project$Components$Dates$RU$monthName = function (month) {
	switch (month) {
		case 0:
			return 'Январь';
		case 1:
			return 'Февраль';
		case 2:
			return 'Март';
		case 3:
			return 'Апрель';
		case 4:
			return 'Май';
		case 5:
			return 'Июнь';
		case 6:
			return 'Июль';
		case 7:
			return 'Август';
		case 8:
			return 'Сентябрь';
		case 9:
			return 'Октябрь';
		case 10:
			return 'Ноябрь';
		default:
			return 'Декабрь';
	}
};
var $author$project$Components$Dates$RU$weekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'понедельник';
		case 1:
			return 'вторник';
		case 2:
			return 'среда';
		case 3:
			return 'четверг';
		case 4:
			return 'пятница';
		case 5:
			return 'суббота';
		default:
			return 'воскресенье';
	}
};
var $author$project$Components$Dates$RU$weekdayNameShort = function (weekday) {
	switch (weekday) {
		case 0:
			return 'пн';
		case 1:
			return 'вт';
		case 2:
			return 'ср';
		case 3:
			return 'чт';
		case 4:
			return 'пт';
		case 5:
			return 'сб';
		default:
			return 'вс';
	}
};
var $author$project$Components$Dates$RU$language = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$author$project$Components$Dates$RU$monthName,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Dates$RU$monthName,
		$elm$core$String$left(3)),
	$author$project$Components$Dates$RU$weekdayName,
	$author$project$Components$Dates$RU$weekdayNameShort,
	function (_v0) {
		return '';
	},
	function (_v1) {
		return '';
	});
var $author$project$Components$Dates$UA$monthName = function (month) {
	switch (month) {
		case 0:
			return 'Січень';
		case 1:
			return 'Лютий';
		case 2:
			return 'Березень';
		case 3:
			return 'Квітень';
		case 4:
			return 'Травень';
		case 5:
			return 'Червень';
		case 6:
			return 'Липень';
		case 7:
			return 'Серпень';
		case 8:
			return 'Вересень';
		case 9:
			return 'Жовтень';
		case 10:
			return 'Листопад';
		default:
			return 'Грудень';
	}
};
var $author$project$Components$Dates$UA$weekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'понеділок';
		case 1:
			return 'вівторок';
		case 2:
			return 'середа';
		case 3:
			return 'четвер';
		case 4:
			return 'п\'ятниця';
		case 5:
			return 'субота';
		default:
			return 'неділя';
	}
};
var $author$project$Components$Dates$UA$weekdayNameShort = function (weekday) {
	switch (weekday) {
		case 0:
			return 'пн';
		case 1:
			return 'вт';
		case 2:
			return 'ср';
		case 3:
			return 'чт';
		case 4:
			return 'пт';
		case 5:
			return 'сб';
		default:
			return 'нд';
	}
};
var $author$project$Components$Dates$UA$language = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$author$project$Components$Dates$UA$monthName,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Dates$UA$monthName,
		$elm$core$String$left(3)),
	$author$project$Components$Dates$UA$weekdayName,
	$author$project$Components$Dates$UA$weekdayNameShort,
	function (_v0) {
		return '';
	},
	function (_v1) {
		return '';
	});
var $author$project$Components$Dates$language = function (langCode) {
	var _v0 = A2(
		$elm$core$String$left,
		2,
		$elm$core$String$toUpper(langCode));
	switch (_v0) {
		case 'EN':
			return $author$project$Components$Dates$EN$language;
		case 'RU':
			return $author$project$Components$Dates$RU$language;
		default:
			return $author$project$Components$Dates$UA$language;
	}
};
var $ryannhg$date_format$DateFormat$MonthNameAbbreviated = {$: 3};
var $ryannhg$date_format$DateFormat$monthNameAbbreviated = $ryannhg$date_format$DateFormat$MonthNameAbbreviated;
var $ryannhg$date_format$DateFormat$YearNumber = {$: 16};
var $ryannhg$date_format$DateFormat$yearNumber = $ryannhg$date_format$DateFormat$YearNumber;
var $author$project$Components$Dates$dateFormatFull = function (langCode) {
	return A2(
		$ryannhg$date_format$DateFormat$formatWithLanguage,
		$author$project$Components$Dates$language(langCode),
		_List_fromArray(
			[$ryannhg$date_format$DateFormat$dayOfMonthNumber, $author$project$Components$Dates$divToken, $ryannhg$date_format$DateFormat$monthNameAbbreviated, $author$project$Components$Dates$divToken, $ryannhg$date_format$DateFormat$yearNumber]));
};
var $author$project$Page$System$Config$Battery$footer = F2(
	function (_v0, battery) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-footer setting-footer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('orange-gradient-text cursor-pointer details-footer-btn modal-change-battery-btn'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$System$Config$Types$OnBatteryChange(
								$author$project$Page$System$Config$Types$BC_Change('5800')))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('image icon-battery orange-gradient-text')
								]),
							_List_Nil),
							$elm$html$Html$text(
							t('config.Замена батареи'))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('blue-text cursor-pointer details-footer-btn modal-change-capacity-btn'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$System$Config$Types$OnBatteryChange(
								$author$project$Page$System$Config$Types$BC_Capacity(
									$elm$core$String$fromFloat(battery.dD))))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('image icon-change_battery')
								]),
							_List_Nil),
							$elm$html$Html$text(
							t('config.Изменить начальную емкость'))
						]))
				]));
	});
var $author$project$Page$System$Config$Battery$startCapacity = F2(
	function (_v0, value) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('config.Начальная емкость батареи')),
							$elm$html$Html$text(':')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value),
							$elm$html$Html$text(' mAh')
						]))
				]));
	});
var $author$project$Types$Dt$toPosix = function (_v0) {
	var dt = _v0;
	return $elm$time$Time$millisToPosix(dt * 1000);
};
var $author$project$Page$System$Config$Battery$trackerModeTime = F2(
	function (_v0, value) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-mode')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mode-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('Режим')),
							$elm$html$Html$text(' '),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('uppercase-txt')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('Поиск'))
								])),
							$elm$html$Html$text(': ')
						])),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $author$project$Page$System$Config$Battery$usedBattery = F2(
	function (_v0, value) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('config.Израсходовано энергии батареи')),
							$elm$html$Html$text(':')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value),
							$elm$html$Html$text(' mAh')
						]))
				]));
	});
var $author$project$Page$System$Config$Battery$waitModeTime = F2(
	function (_v0, value) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('wait-mode')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mode-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('Режим')),
							$elm$html$Html$text(' '),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('uppercase-txt')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('Ожидание'))
								])),
							$elm$html$Html$text(':')
						])),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $author$project$Page$System$Config$Battery$view = F4(
	function (appState, model, system, mparams) {
		var t = appState.aX;
		var sleep = function () {
			var _v1 = system.dV.d6;
			if (_v1.$ === 1) {
				return 120;
			} else {
				var sleepValue = _v1.a;
				return sleepValue;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('wrapper-content wrapper-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-wrapper-bg')
						]),
					function () {
						var _v0 = system.da;
						if (_v0.$ === 1) {
							return _List_fromArray(
								[
									$elm$html$Html$text(
									t('control.Информация будет доступна после выхода Феникса на связь.'))
								]);
						} else {
							var battery = _v0.a;
							var lifetime = (($elm$time$Time$posixToMillis(appState.bm) / 1000) | 0) - $author$project$Types$Dt$toInt(battery.dE);
							var used = A2($author$project$API$System$Battery$calculation, battery.df, lifetime);
							var percentage = (100 * (battery.dD - used)) / battery.dD;
							var colour = (percentage > 66) ? 'green' : ((percentage > 33) ? 'yellow' : 'red');
							var capacity = battery.dD - used;
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('details-header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Обслуживание батареи'))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('details-items')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Предполагаемое время')),
															A2($elm$html$Html$br, _List_Nil, _List_Nil),
															$elm$html$Html$text(
															t('config.работы батареи'))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('setting-item-wr')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('content-item-group')
																]),
															_List_fromArray(
																[
																	$author$project$Page$System$Config$Battery$batteryWidget(percentage),
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('details-mode')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$author$project$Page$System$Config$Battery$waitModeTime,
																			appState,
																			A3($author$project$API$System$Battery$expect_at_sleep, appState, capacity, sleep)),
																			A2(
																			$author$project$Page$System$Config$Battery$trackerModeTime,
																			appState,
																			A2($author$project$API$System$Battery$expect_at_tracking, appState, capacity))
																		]))
																]))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('config.Статистика работы')),
															$elm$html$Html$text(':')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('setting-item-wr')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('setting-item')
																]),
															_List_fromArray(
																[
																	A2(
																	$author$project$Page$System$Config$Battery$startCapacity,
																	appState,
																	$elm$core$String$fromFloat(battery.dD)),
																	A2(
																	$author$project$Page$System$Config$Battery$beginAt,
																	appState,
																	A3(
																		$author$project$Components$Dates$dateFormatFull,
																		appState.cb,
																		appState.bC,
																		$author$project$Types$Dt$toPosix(battery.dE))),
																	A2(
																	$author$project$Page$System$Config$Battery$usedBattery,
																	appState,
																	A2($myrho$elm_round$Round$round, 1, used))
																]))
														]))
												]))
										])),
									A2($author$project$Page$System$Config$Battery$footer, appState, battery)
								]);
						}
					}())
				]));
	});
var $author$project$Page$System$Config$Types$OnEditPhone = function (a) {
	return {$: 41, a: a};
};
var $author$project$Page$System$Config$Types$OnExecutor = F2(
	function (a, b) {
		return {$: 40, a: a, b: b};
	});
var $author$project$Page$System$Config$Types$OnRemove = function (a) {
	return {$: 21, a: a};
};
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Page$System$Config$Details$view = F4(
	function (appState, model, system, mparams) {
		var t = appState.aX;
		var maybeRowEditable = F4(
			function (label, field, field_manual, foo) {
				var _v1 = function () {
					var _v2 = _Utils_Tuple2(field, field_manual);
					if (!_v2.b.$) {
						var value = _v2.b.a;
						return _Utils_Tuple2(
							t('config.Установлен пользователем'),
							$elm$core$Maybe$Just(value));
					} else {
						if (!_v2.a.$) {
							var value = _v2.a.a;
							var _v3 = _v2.b;
							return _Utils_Tuple2(
								t('config.Определено автоматически'),
								$elm$core$Maybe$Just(value));
						} else {
							return _Utils_Tuple2('?', $elm$core$Maybe$Nothing);
						}
					}
				}();
				var mark = _v1.a;
				var v = _v1.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content-item setting-det-phone')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('name')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.' + label)),
									$elm$html$Html$text(':')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text'),
									$elm$html$Html$Attributes$title(mark)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2(
										$elm$core$Maybe$withDefault,
										t('config.Не указан'),
										v))
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('setting-edit details-edit-btn openModalPhone'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$System$Config$Types$OnEditPhone(
										A2($elm$core$Maybe$withDefault, '', v)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-edit')
										]),
									_List_Nil)
								]))
						]));
			});
		var maybeRow = F3(
			function (label, field, foo) {
				if (field.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var v = field.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('content-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('name')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('config.' + label)),
										$elm$html$Html$text(':')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(v)
									]))
							]));
				}
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-wrapper-bg')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Детали о Фениксе'))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-items')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('setting-item')
										]),
									_List_fromArray(
										[
											A3(maybeRow, 'Модель', system.dx, $elm$core$Basics$identity),
											A3(maybeRow, 'Версия ПО', system.eb, $elm$core$Basics$identity),
											A3(maybeRow, 'IMEI', system.dz, $elm$core$Basics$identity),
											A4(maybeRowEditable, 'SIM-карта', system.dW, system.dX, $elm$core$Basics$identity),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('checkmark-wrap setting-executors')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('checkboxContainer')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'checked', ''),
																			$elm$html$Html$Attributes$name(''),
																			$elm$html$Html$Attributes$type_('checkbox'),
																			$elm$html$Html$Attributes$checked(system.dm),
																			$elm$html$Html$Events$onCheck(
																			$author$project$Page$System$Config$Types$OnExecutor(system.b6))
																		]),
																	_List_Nil),
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('checkmark')
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('checkmark-text')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	t('config.Экзекутор в наличии'))
																]))
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-footer setting-footer')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#'),
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									$elm$html$Html$Attributes$class('red-text cursor-pointer details-footer-btn modal-open'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$System$Config$Types$OnRemove(system.b6))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-remove')
										]),
									_List_Nil),
									$elm$html$Html$text(
									t('config.Удалить Феникс'))
								]))
						]))
				]));
	});
var $author$project$Page$System$Config$Types$OnIconChangeStart = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$System$Config$Types$OnTitleChangeStart = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$System$Config$NameAndIcon$view = F4(
	function (appState, model, system, mparams) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-wrapper-bg')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Иконка и название'))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-items')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('config.Название Феникса'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('setting-item-wr')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('setting-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('setting-item-header')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('name')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(system.ej)
																])),
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('setting-edit btn-change-name'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$System$Config$Types$OnTitleChangeStart(system.ej))
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('icon-edit')
																		]),
																	_List_Nil)
																]))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('config.Иконка Феникса'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('setting-item-wr')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('setting-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('setting-item-header')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('setting-selected-icon btn-select-icon'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$System$Config$Types$OnIconChangeStart(system.dy))
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('icon-' + (system.dy + ' image'))
																		]),
																	_List_Nil),
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('icon-' + (system.dy + '_icon image-logo'))
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('setting-edit btn-select-icon'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$System$Config$Types$OnIconChangeStart(system.dy))
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('icon-edit')
																		]),
																	_List_Nil)
																]))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$System$Config$viewContainer = F4(
	function (appState, model, system, mparams) {
		var t = appState.aX;
		var _v0 = model.y;
		switch (_v0) {
			case 0:
				var item = F3(
					function (icon, lab, msg) {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('setting-nav-item'),
									$elm$html$Html$Events$onClick(msg)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon icon-' + icon)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text')
										]),
									_List_fromArray(
										[
											$author$project$Components$UI$text(
											t('menu.' + lab))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('setting-nav-arrow')
										]),
									_List_Nil)
								]));
					});
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-wrapper-bg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('details-title manage-details-title')
										]),
									_List_fromArray(
										[
											$author$project$Components$UI$text(
											t('menu.Настройки'))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('setting-nav-wr')
								]),
							_List_fromArray(
								[
									A3(
									item,
									'car1',
									'Иконка и название Феникса',
									$author$project$Page$System$Config$Types$OnOpenNameAndIcon(system.b6)),
									A3(
									item,
									'settings-sm',
									'Основные настройки',
									$author$project$Page$System$Config$Types$OnStartMaster(system.b6)),
									A3(
									item,
									'tool',
									'Расширенные настройки',
									$author$project$Page$System$Config$Types$OnMasterCustom(system.b6)),
									A3(
									item,
									'battery_setting',
									'Обслуживание батареи',
									$author$project$Page$System$Config$Types$OnOpenBattery(system.b6)),
									A3(
									item,
									'id',
									'Детали о Фениксе',
									$author$project$Page$System$Config$Types$OnOpenDetails(system.b6))
								]))
						]));
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-bg')
						]),
					A4($author$project$Page$System$Config$Master$masterDialogView, appState, model, system.b6, mparams));
			case 2:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-wrapper-bg')
						]),
					A4($author$project$Page$System$Config$Custom$configCustomView, appState, model, system.b6, mparams));
			case 3:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-bg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('config-img')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$alt(''),
											$elm$html$Html$Attributes$src('/images/setting_done.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title-st')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Поздравляем!')),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									$elm$html$Html$text(
									t('config.Основные настройки применены'))
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-md btn-primary btn-next mt-40'),
									$elm$html$Html$Attributes$href('/system/' + system.b6)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('config.Перейти к Фениксу'))
								]))
						]));
			case 4:
				return A4($author$project$Page$System$Config$Details$view, appState, model, system, mparams);
			case 5:
				return A4($author$project$Page$System$Config$NameAndIcon$view, appState, model, system, mparams);
			default:
				return A4($author$project$Page$System$Config$Battery$view, appState, model, system, mparams);
		}
	});
var $author$project$Page$System$Config$Types$OnCancelRemove = {$: 22};
var $author$project$Page$System$Config$Types$OnConfirmRemove = {$: 23};
var $author$project$Page$System$Config$Dialogs$viewRemoveWidget = F3(
	function (_v0, model, system) {
		var t = _v0.aX;
		var tr = _v0.cY;
		return model.ar ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content modal-sm')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnCancelRemove)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title modal-title-sm')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('config.Удалить Феникс?'))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														A2(
															tr,
															'config.remove_fx',
															_List_fromArray(
																[
																	_Utils_Tuple2('title', system.ej)
																])))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnCancelRemove)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Нет'))
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-cancel'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Config$Types$OnConfirmRemove)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Да, удалить'))
													]))
											]))
									]))
							]))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$System$Config$view = F4(
	function (appState, model, system, mparams) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('wrapper-content wrapper-page')
							]),
						_List_fromArray(
							[
								A4($author$project$Page$System$Config$viewContainer, appState, model, system, mparams)
							]))
					]),
				_Utils_ap(
					A3($author$project$Page$System$Config$Dialogs$titleChangeDialogView, appState, model, system.b6),
					_Utils_ap(
						A3($author$project$Page$System$Config$Dialogs$iconChangeDialogView, appState, model, system.b6),
						_Utils_ap(
							A3($author$project$Page$System$Config$Dialogs$viewRemoveWidget, appState, model, system),
							_Utils_ap(
								A3($author$project$Page$System$Config$Dialogs$paramChangeDialogView, appState, model, mparams),
								_Utils_ap(
									A3($author$project$Page$System$Config$Dialogs$batteryMaintanceDialogView, appState, model, system.b6),
									A3($author$project$Page$System$Config$Dialogs$editPhoneDialogView, appState, model, system.b6))))))));
	});
var $author$project$Main$systemConfigRec = {
	s: function ($) {
		return $.aW;
	},
	p: A2($elm$core$Basics$composeR, $author$project$Types$SystemConfigMsg, $author$project$Types$OnPageMsg),
	am: F2(
		function (newModel, model) {
			return _Utils_update(
				model,
				{aW: newModel});
		}),
	_: $author$project$Types$Page$PUT_Public($author$project$Page$System$Config$update),
	K: $author$project$Types$Page$PT_SystemParams($author$project$Page$System$Config$view)
};
var $author$project$Types$Page$PT_System = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$SystemInfoMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$System$Info$Types$OnCopyPhoneDone = {$: 14};
var $author$project$Page$System$Info$Types$OnHidePhone = {$: 12};
var $author$project$API$System$cancelSystemState = function (sysId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'cmd',
				$elm$json$Json$Encode$string('system_state')),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(sysId)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(''))
			]));
};
var $author$project$Page$System$Info$copyToClipboard = _Platform_outgoingPort('copyToClipboard', $elm$json$Json$Encode$string);
var $author$project$API$System$setSystemState = F2(
	function (sysId, newState) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cmd',
					$elm$json$Json$Encode$string('system_state')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(sysId)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string(
						function () {
							switch (newState.$) {
								case 0:
									return 'tracking';
								case 1:
									return 'sleep';
								case 2:
									return 'locked';
								case 3:
									return 'beacon';
								case 4:
									return 'hidden';
								case 5:
									return 'off';
								case 6:
									return 'config';
								case 7:
									return 'point';
								case 8:
									return 'slock';
								case 9:
									return 'lock';
								case 10:
									return 'clock';
								case 11:
									return 'unlock';
								case 12:
									var hours = newState.a;
									return 'prolong_' + $elm$core$String$fromInt(hours);
								default:
									var c = newState.a;
									return c;
							}
						}()))
				]));
	});
var $author$project$API$System$prolongSleep = F2(
	function (sysId, hours) {
		return A2(
			$author$project$API$System$setSystemState,
			sysId,
			$author$project$API$System$ProlongSleep(hours));
	});
var $author$project$Page$System$Info$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var sysId = msg.a;
				var state = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ao: $elm$core$Maybe$Just(state)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 0:
				if (msg.b.$ === 5) {
					var sysId = msg.a;
					var _v1 = msg.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{dQ: sysId, d5: true}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var sysId = msg.a;
					var state = msg.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ao: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$API$websocketOut(
									A2($author$project$API$System$setSystemState, sysId, state))
								])),
						$elm$core$Maybe$Nothing);
				}
			case 2:
				var sysId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								$author$project$API$System$cancelSystemState(sysId))
							])),
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dq: !model.dq}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{d5: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								A2($author$project$API$System$setSystemState, model.dQ, $author$project$API$System$Off))
							])),
					$elm$core$Maybe$Nothing);
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{d5: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ao: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 9:
				var sysId = msg.a;
				var hours = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: false}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$API$websocketOut(
								A2($author$project$API$System$prolongSleep, sysId, hours))
							])),
					$elm$core$Maybe$Nothing);
			case 10:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bt: true}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								function (_v2) {
									return $author$project$Page$System$Info$Types$OnHidePhone;
								},
								$elm$core$Process$sleep(6500))
							])),
					$elm$core$Maybe$Nothing);
			case 12:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bt: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 13:
				var phone = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aQ: true}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Page$System$Info$copyToClipboard(phone),
								A2(
								$elm$core$Task$perform,
								function (_v3) {
									return $author$project$Page$System$Info$Types$OnCopyPhoneDone;
								},
								$elm$core$Process$sleep(6500))
							])),
					$elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aQ: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$Page$System$Info$Types$OnHideCmdConfirmDialog = {$: 8};
var $author$project$Page$System$Info$Types$OnSysCmd = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$System$Info$Types$OnSysCmdPre = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Page$System$Info$block = F4(
	function (_v0, slock, pre, sysId) {
		var t = _v0.aX;
		var opstate = function () {
			if (slock) {
				return $author$project$API$System$Lock;
			} else {
				return $author$project$API$System$SLock;
			}
		}();
		var _v1 = function () {
			if (slock) {
				return _Utils_Tuple3('control.block_smart_text', $author$project$API$System$SLock, 'control.block_smart_comment');
			} else {
				return _Utils_Tuple3('control.block_lazy_text', $author$project$API$System$Lock, 'control.block_lazy_comment');
			}
		}();
		var text_ = _v1.a;
		var state = _v1.b;
		var comment_ = _v1.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal-bg show')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-img-mob')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$alt(''),
													$elm$html$Html$Attributes$src('/images/engaine_blocking.svg')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('control.Блокировка двигателя'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-body')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(pre),
													$elm$html$Html$text(' '),
													$elm$html$Html$text(
													t(text_))
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('checkmark-wrap')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('checkboxContainer')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$checked(slock),
																	$elm$html$Html$Attributes$name(''),
																	$elm$html$Html$Attributes$type_('checkbox'),
																	$elm$html$Html$Events$onCheck(
																	$elm$core$Basics$always(
																		A2($author$project$Page$System$Info$Types$OnSysCmdPre, sysId, opstate)))
																]),
															_List_Nil),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('checkmark')
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('checkmark-text')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('checkmark-title')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	t('control.Интеллектуальная блокировка'))
																])),
															$elm$html$Html$text(
															t(comment_))
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-btn-group')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
													$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnHideCmdConfirmDialog)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('config.Отмена'))
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
													$elm$html$Html$Events$onClick(
													A2($author$project$Page$System$Info$Types$OnSysCmd, sysId, state))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Заблокировать'))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Types$Dt$fromInt = function (i) {
	return i;
};
var $author$project$Types$Dt$addSecs = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return a + (b * 60);
	});
var $author$project$Components$Dates$dateFormat = function (langCode) {
	return A2(
		$ryannhg$date_format$DateFormat$formatWithLanguage,
		$author$project$Components$Dates$language(langCode),
		_List_fromArray(
			[$ryannhg$date_format$DateFormat$dayOfMonthNumber, $author$project$Components$Dates$divToken, $ryannhg$date_format$DateFormat$monthNameAbbreviated]));
};
var $author$project$Components$Dates$colonToken = $ryannhg$date_format$DateFormat$text(':');
var $ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryannhg$date_format$DateFormat$Language$english = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	$ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var $ryannhg$date_format$DateFormat$format = $ryannhg$date_format$DateFormat$formatWithLanguage($ryannhg$date_format$DateFormat$Language$english);
var $ryannhg$date_format$DateFormat$HourMilitaryFixed = {$: 25};
var $ryannhg$date_format$DateFormat$hourMilitaryFixed = $ryannhg$date_format$DateFormat$HourMilitaryFixed;
var $ryannhg$date_format$DateFormat$MinuteFixed = {$: 31};
var $ryannhg$date_format$DateFormat$minuteFixed = $ryannhg$date_format$DateFormat$MinuteFixed;
var $author$project$Components$Dates$timeFormat = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[$ryannhg$date_format$DateFormat$hourMilitaryFixed, $author$project$Components$Dates$colonToken, $ryannhg$date_format$DateFormat$minuteFixed]));
var $author$project$Components$Dates$dateTimeFormat = F2(
	function (_v0, time) {
		var langCode = _v0.cb;
		var timeZone = _v0.bC;
		return A3($author$project$Components$Dates$dateFormat, langCode, timeZone, time) + (' ' + A2($author$project$Components$Dates$timeFormat, timeZone, time));
	});
var $author$project$Components$Dates$nextSessionText = F3(
	function (appState, last_session, next_session) {
		var t = appState.aX;
		if (next_session.$ === 1) {
			return t('control.неизвестно');
		} else {
			var offset = next_session.a;
			return A2(
				$author$project$Components$Dates$dateTimeFormat,
				appState,
				$author$project$Types$Dt$toPosix(
					A2($author$project$Types$Dt$addSecs, last_session, offset)));
		}
	});
var $author$project$Page$System$Info$Dialogs$waitStateLabel = F2(
	function (_v0, waitState) {
		var t = _v0.aX;
		var tr = _v0.cY;
		switch (waitState.$) {
			case 7:
				return t('control.будет определено текущее местоположение');
			case 12:
				var duration = waitState.a;
				var durationText = function (h) {
					switch (h) {
						case 4:
							return t('control.На 4 часа');
						case 24:
							return t('control.На сутки');
						case 100:
							return t('control.Навсегда');
						default:
							return A2(
								tr,
								'control.На ч',
								_List_fromArray(
									[
										_Utils_Tuple2(
										'h',
										$elm$core$String$fromInt(h))
									]));
					}
				};
				return t('control.будет продлена работа Феникса в режиме Поиск') + (' ' + $elm$core$String$toLower(
					durationText(duration)));
			case 9:
				return t('control.будет запущена отложенная блокировка двигателя');
			case 8:
				return t('control.будет запущена интеллектуальная блокировка двигателя');
			case 11:
				return t('control.двигатель будет разблокирован');
			case 5:
				return t('control.Феникс будет выключен');
			case 10:
				return t('control.блокировка будет сброшена');
			default:
				var wState = waitState;
				return t('control.Феникс будет переведён в режим') + (' ' + $elm$core$String$toUpper(
					$author$project$API$System$stateAsString(wState)));
		}
	});
var $author$project$Page$System$Info$confirmDialogs = F4(
	function (appState, model, sysId, mdynamic) {
		var t = appState.aX;
		var tr = appState.cY;
		var pre = function () {
			if (mdynamic.$ === 1) {
				return t('При следующем сеансе связи');
			} else {
				var dynamic = mdynamic.a;
				var last_session = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Types$Dt$fromInt(0),
					dynamic.dH);
				var pre_date = A3($author$project$Components$Dates$nextSessionText, appState, last_session, dynamic.dO);
				return A2(
					tr,
					'control.wait_state',
					_List_fromArray(
						[
							_Utils_Tuple2('datetime', pre_date)
						]));
			}
		}();
		var _v0 = model.ao;
		if (_v0.$ === 1) {
			return _List_Nil;
		} else {
			switch (_v0.a.$) {
				case 8:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							A4($author$project$Page$System$Info$block, appState, true, pre, sysId)
						]);
				case 9:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							A4($author$project$Page$System$Info$block, appState, false, pre, sysId)
						]);
				default:
					var state = _v0.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-bg show')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-wr')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
															$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnHideCmdConfirmDialog)
														]),
													_List_Nil),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-title')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('control.Внимание'))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-body')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('modal-text')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(pre),
																	$elm$html$Html$text(' '),
																	$elm$html$Html$text(
																	A2($author$project$Page$System$Info$Dialogs$waitStateLabel, appState, state))
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-btn-group')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
																	$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnHideCmdConfirmDialog)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	t('config.Отмена'))
																])),
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('btn btn-md btn-primary'),
																	$elm$html$Html$Events$onClick(
																	A2($author$project$Page$System$Info$Types$OnSysCmd, sysId, state))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	t('config.Хорошо'))
																]))
														]))
												]))
										]))
								]))
						]);
			}
		}
	});
var $author$project$Page$System$Info$Types$OnHideProlongSleepDialog = {$: 7};
var $author$project$Page$System$Info$Types$OnProlongSleep = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$System$Info$Dialogs$prolongSleepDialogView = F3(
	function (_v0, model, sysId) {
		var t = _v0.aX;
		return model.aU ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-bg show')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-close close modal-close-btn'),
												$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnHideProlongSleepDialog)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-img-mob')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$alt(''),
														$elm$html$Html$Attributes$src('/images/search_continue.svg')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('control.Продлить работу в режиме')),
												$elm$html$Html$text(' '),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('uppercase-txt')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('control.Поиск'))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('control.Укажите на какое время вы хотите продлить работу')),
														$elm$html$Html$text(' '),
														A2($elm$html$Html$br, _List_Nil, _List_Nil),
														$elm$html$Html$text(
														t('control.в режиме')),
														$elm$html$Html$text(' '),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('uppercase-txt')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('control.Поиск'))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('extend-mode')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn extend-mode-btn'),
																$elm$html$Html$Events$onClick(
																A2($author$project$Page$System$Info$Types$OnProlongSleep, sysId, 4))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		t('control.На 4 часа'))
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn extend-mode-btn'),
																$elm$html$Html$Events$onClick(
																A2($author$project$Page$System$Info$Types$OnProlongSleep, sysId, 24))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		t('control.На сутки'))
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn extend-mode-btn'),
																$elm$html$Html$Events$onClick(
																A2($author$project$Page$System$Info$Types$OnProlongSleep, sysId, 100))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		t('control.Навсегда'))
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-btn-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
														$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnHideProlongSleepDialog)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('config.Отмена'))
													]))
											]))
									]))
							]))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$System$Info$Battery$height = function (percentage) {
	return A2($myrho$elm_round$Round$round, 0, percentage + 9) + '%';
};
var $author$project$Page$System$Info$Battery$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.Предполагаемое время работы батареи'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content-item-group')
										]),
									function () {
										var _v0 = system.da;
										if (_v0.$ === 1) {
											return _List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Информация будет доступна после выхода Феникса на связь.'))
												]);
										} else {
											var battery = _v0.a;
											var sleep = function () {
												var _v2 = system.dV.d6;
												if (_v2.$ === 1) {
													return 120;
												} else {
													var sleepValue = _v2.a;
													return sleepValue;
												}
											}();
											var lifetime = (($elm$time$Time$posixToMillis(appState.bm) / 1000) | 0) - $author$project$Types$Dt$toInt(battery.dE);
											var used = A2($author$project$API$System$Battery$calculation, battery.df, lifetime);
											var percentage = (100 * (battery.dD - used)) / battery.dD;
											var p_as_text = A2($myrho$elm_round$Round$round, 1, percentage) + '%';
											var colour = $author$project$Components$UI$Battery$powerLevelClass(percentage);
											var capacity = battery.dD - used;
											var hiddenMode = A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('wait-mode')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('mode-title')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('control.Режим')),
																$elm$html$Html$text(' '),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('uppercase-txt')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		t('Ожидание'))
																	])),
																$elm$html$Html$text(': ')
															])),
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																A3($author$project$API$System$Battery$expect_at_sleep, appState, capacity, sleep))
															]))
													]));
											var trackingMode = A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('search-mode')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('mode-title')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('control.Режим')),
																$elm$html$Html$text(' '),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('uppercase-txt')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		t('Поиск'))
																	])),
																$elm$html$Html$text(': ')
															])),
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																A2($author$project$API$System$Battery$expect_at_tracking, appState, capacity))
															]))
													]));
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('power-status')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('power')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('power-top')
																		]),
																	_List_Nil),
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('power-wr')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$span,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('power-bg'),
																					$elm$html$Html$Attributes$class(colour),
																					A2(
																					$elm$html$Html$Attributes$attribute,
																					'style',
																					'height: ' + $author$project$Page$System$Info$Battery$height(percentage))
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$svg$Svg$svg,
																					_List_fromArray(
																						[
																							A2($elm$html$Html$Attributes$attribute, 'preserveAspectRatio', 'xMinYMin meet'),
																							$elm$svg$Svg$Attributes$viewBox('0 0 500 500')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$svg$Svg$path,
																							_List_fromArray(
																								[
																									$elm$svg$Svg$Attributes$d('M0, 100 C150, 200 350, 0 500, 100 L500, 00 L0, 0 Z'),
																									A2($elm$html$Html$Attributes$attribute, 'style', 'stroke:none; fill: #323343;')
																								]),
																							_List_Nil)
																						]))
																				]))
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('power-status-title'),
																	$elm$html$Html$Attributes$class(colour)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(p_as_text)
																]))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('details-mode')
														]),
													function () {
														var _v1 = system.dk;
														if (_v1.$ === 1) {
															return _List_fromArray(
																[hiddenMode, trackingMode]);
														} else {
															var dynamic = _v1.a;
															return _Utils_eq(
																dynamic.cQ,
																$elm$core$Maybe$Just($author$project$API$System$Tracking)) ? _List_fromArray(
																[trackingMode, hiddenMode]) : _List_fromArray(
																[hiddenMode, trackingMode]);
														}
													}())
												]);
										}
									}())
								]))
						]))
				]));
	});
var $author$project$Page$System$Info$Footer$footerNoWait = F4(
	function (appState, model, system, available) {
		var t = appState.aX;
		return A2($elm$core$List$member, $author$project$API$System$CLock, available) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('green-gradient-text block-engine-btn modal-open cursor-pointer'),
					$elm$html$Html$Events$onClick(
					A2($author$project$Page$System$Info$Types$OnSysCmd, system.b6, $author$project$API$System$CLock))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon-key')
						]),
					_List_Nil),
					$elm$html$Html$text(
					t('control.Отмена процедуры блокировки'))
				])) : (A2($elm$core$List$member, $author$project$API$System$Unlock, available) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('green-gradient-text block-engine-btn modal-open cursor-pointer'),
					$elm$html$Html$Events$onClick(
					A2($author$project$Page$System$Info$Types$OnSysCmdPre, system.b6, $author$project$API$System$Unlock))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon-key')
						]),
					_List_Nil),
					$elm$html$Html$text(
					t('control.Разблокировать двигатель'))
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orange-gradient-text block-engine-btn modal-open cursor-pointer'),
					$elm$html$Html$Events$onClick(
					A2($author$project$Page$System$Info$Types$OnSysCmdPre, system.b6, $author$project$API$System$SLock))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon-block')
						]),
					_List_Nil),
					$elm$html$Html$text(
					t('control.Заблокировать двигатель'))
				])));
	});
var $author$project$Page$System$Info$Types$OnSysCmdCancel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$System$Info$Footer$footerWait = F5(
	function (appState, model, system, waitState, dynamic) {
		var t = appState.aX;
		var tr = appState.cY;
		var tlabel = function (t_) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('cmdWaitLabel')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(t_)
					]));
		};
		var last_session = function () {
			var _v0 = dynamic.dH;
			if (_v0.$ === 1) {
				return $author$project$Types$Dt$fromInt(0);
			} else {
				var lastping = _v0.a;
				return lastping;
			}
		}();
		var pre_date = A3($author$project$Components$Dates$nextSessionText, appState, last_session, dynamic.dO);
		var pre = A2(
			tr,
			'control.wait_state',
			_List_fromArray(
				[
					_Utils_Tuple2('datetime', pre_date)
				]));
		var hint = A2($author$project$Page$System$Info$Dialogs$waitStateLabel, appState, waitState);
		var cmdWidget = $elm$html$Html$div(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cmdWidget')
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('processing-engeine')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('processing-engeine-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon-block orange-gradient-text')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(pre),
									$elm$html$Html$text(' '),
									$elm$html$Html$text(hint)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-blue-title red-text'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$System$Info$Types$OnSysCmdCancel(system.b6))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('config.Отмена'))
						]))
				]));
	});
var $author$project$Page$System$Info$Footer$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		var _v0 = system.dk;
		if (_v0.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var dynamic = _v0.a;
			var _v1 = dynamic.cQ;
			if ((!_v1.$) && (_v1.a.$ === 5)) {
				var _v2 = _v1.a;
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			} else {
				var _v3 = dynamic.ex;
				if (_v3.$ === 1) {
					return system.dm ? A4($author$project$Page$System$Info$Footer$footerNoWait, appState, model, system, dynamic.c8) : $elm$html$Html$text('');
				} else {
					var waitState = _v3.a;
					return A5($author$project$Page$System$Info$Footer$footerWait, appState, model, system, waitState, dynamic);
				}
			}
		}
	});
var $author$project$Page$System$Info$UI$disabledOnWait = F3(
	function (dynamic, _v0, child) {
		var t = _v0.aX;
		var _v1 = dynamic.ex;
		if (_v1.$ === 1) {
			return child;
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tooltip')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tooltip-wr')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('tooltip-content tooltip-content-big')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('control.Вы сможете нажать эту кнопку после того как Феникc исполнит команды которые ждут выполнения'))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tooltip-title')
							]),
						_List_fromArray(
							[child]))
					]));
		}
	});
var $author$project$Page$System$Info$UI$disabledOnWaitClass = function (dynamic) {
	return $elm$html$Html$Attributes$class(
		function () {
			var _v0 = dynamic.ex;
			if (_v0.$ === 1) {
				return '';
			} else {
				return 'btn-disabled-opacity';
			}
		}());
};
var $author$project$Page$System$Info$Position$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-item details-title-flat')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.Положение'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					function () {
						var _v0 = system.dk;
						if (_v0.$ === 1) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content-item')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('name')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Информация будет доступна после выхода Феникса на связь.'))
												]))
										]))
								]);
						} else {
							var dynamic = _v0.a;
							return _List_fromArray(
								[
									function () {
									var _v1 = _Utils_Tuple3(dynamic.dI, dynamic.dJ, dynamic.ae);
									if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
										var latitude = _v1.a.a;
										var longitude = _v1.b.a;
										var dt = _v1.c.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('name')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('control.Положение определено:'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2(
																$author$project$Components$Dates$dateTimeFormat,
																appState,
																$author$project$Types$Dt$toPosix(dt)))
														]))
												]));
									} else {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('name')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('control.Положение неизвестно'))
														]))
												]));
									}
								}(),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content-item content-item-group')
										]),
									_Utils_ap(
										function () {
											var _v2 = _Utils_Tuple3(dynamic.dI, dynamic.dJ, dynamic.ae);
											if (((!_v2.a.$) && (!_v2.b.$)) && (!_v2.c.$)) {
												var latitude = _v2.a.a;
												var longitude = _v2.b.a;
												var dt = _v2.c.a;
												return _List_fromArray(
													[
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('details-blue-title blue-gradient-text'),
																$elm$html$Html$Attributes$href(
																'/map/' + (system.b6 + ('?lat=' + ($elm$core$String$fromFloat(latitude) + ('&lng=' + $elm$core$String$fromFloat(longitude))))))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('details-icon icon-map')
																	]),
																_List_Nil),
																$elm$html$Html$text(
																t('control.Показать'))
															]))
													]);
											} else {
												return _List_Nil;
											}
										}(),
										function () {
											var _v3 = A2($elm$core$List$member, $author$project$API$System$Point, dynamic.c8);
											if (_v3) {
												return _List_fromArray(
													[
														A3(
														$author$project$Page$System$Info$UI$disabledOnWait,
														dynamic,
														appState,
														A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('details-blue-title blue-gradient-text'),
																	$author$project$Page$System$Info$UI$disabledOnWaitClass(dynamic),
																	$elm$html$Html$Events$onClick(
																	A2($author$project$Page$System$Info$Types$OnSysCmdPre, system.b6, $author$project$API$System$Point))
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('details-icon icon-refresh')
																		]),
																	_List_Nil),
																	$elm$html$Html$text(
																	t('control.Обновить'))
																])))
													]);
											} else {
												return _List_Nil;
											}
										}()))
								]);
						}
					}())
				]));
	});
var $author$project$Page$System$Info$Session$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		var now = appState.bm;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-item details-title-flat')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.Связь'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					function () {
						var _v0 = system.dk;
						if (_v0.$ === 1) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content-item')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('name')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Информация будет доступна после выхода Феникса на связь.'))
												]))
										]))
								]);
						} else {
							var dynamic = _v0.a;
							var last_session = function () {
								var _v3 = dynamic.dH;
								if (_v3.$ === 1) {
									return $author$project$Types$Dt$fromInt(0);
								} else {
									var lastping = _v3.a;
									return lastping;
								}
							}();
							var nextSessionTextRow = function () {
								var _v1 = dynamic.cQ;
								if ((!_v1.$) && (_v1.a.$ === 5)) {
									var _v2 = _v1.a;
									return _List_Nil;
								} else {
									return _List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content-item')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('name')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('control.Следующий сеанс связи:'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A3($author$project$Components$Dates$nextSessionText, appState, last_session, dynamic.dO))
														]))
												]))
										]);
								}
							}();
							return _Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('content-item')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('control.Последний сеанс связи:'))
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														A2(
															$author$project$Components$Dates$dateTimeFormat,
															appState,
															$author$project$Types$Dt$toPosix(last_session)))
													]))
											]))
									]),
								nextSessionTextRow);
						}
					}())
				]));
	});
var $author$project$Page$System$Info$Types$OnCopyPhone = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$System$Info$Types$OnNoCmd = {$: 10};
var $author$project$Page$System$Info$Types$OnShowPhone = {$: 11};
var $author$project$Page$System$Info$SimCard$view = F3(
	function (appState, _v0, system) {
		var t = appState.aX;
		var showPhone = _v0.bt;
		var phoneClick = function () {
			var _v2 = system.dW;
			if (_v2.$ === 1) {
				return $author$project$Page$System$Info$Types$OnNoCmd;
			} else {
				var phone = _v2.a;
				return $author$project$Page$System$Info$Types$OnCopyPhone(phone);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.SIM-карта'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content-item')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-sim')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('name')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('control.Баланс:'))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text')
										]),
									_List_fromArray(
										[
											function () {
											var _v1 = system.c9;
											if (_v1.$ === 1) {
												return $elm$html$Html$text(
													t('control.Данные еще не получены'));
											} else {
												var dt = _v1.a.ae;
												var value = _v1.a.eu;
												return $elm$html$Html$text(
													$elm$core$String$fromFloat(value));
											}
										}()
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content-item topUpAccount'),
									$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnShowPhone)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('number-phone accountPhone'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('showAnimate', showPhone)
												])),
											$elm$html$Html$Events$onClick(phoneClick)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-icon icon-phone')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text'),
													$elm$html$Html$Attributes$id('phoneForCopy')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2(
														$elm$core$Maybe$withDefault,
														A2(
															$elm$core$Maybe$withDefault,
															t('config.Не указан'),
															system.dW),
														system.dX))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('details-blue-title blue-gradient-text topUpText'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('hidden', showPhone)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-icon icon-card')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('top-account')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Пополнить счет'))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$System$Info$Types$OnShowProlongSleepDialog = {$: 6};
var $author$project$Types$Dt$fromMinutes = function (i) {
	return i;
};
var $author$project$Page$System$Info$State$expectSleepIn = F3(
	function (appState, dynamic, prolongCmd) {
		var t = appState.aX;
		var now = appState.bm;
		var last_session = function () {
			var _v1 = dynamic.dH;
			if (_v1.$ === 1) {
				return $author$project$Types$Dt$fromInt(0);
			} else {
				var lastping = _v1.a;
				return lastping;
			}
		}();
		var autosleep = function () {
			var _v0 = dynamic.c7;
			if (_v0.$ === 1) {
				return '-';
			} else {
				var offset = _v0.a;
				return _Utils_eq(
					offset,
					$author$project$Types$Dt$fromMinutes(6000)) ? 'никогда' : A2(
					$author$project$Components$Dates$dateTimeFormat,
					appState,
					$author$project$Types$Dt$toPosix(
						A2($author$project$Types$Dt$addSecs, last_session, offset)));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details-changed-mode')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-revert-mode')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('control.Переход в режим')),
											$elm$html$Html$text(' '),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('uppercase-txt mode')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('control.Ожидание'))
												])),
											$elm$html$Html$text(':')
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('date')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(autosleep)
										]))
								])),
							A3(
							$author$project$Page$System$Info$UI$disabledOnWait,
							dynamic,
							appState,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details-blue-title blue-gradient-text modal-open'),
										$author$project$Page$System$Info$UI$disabledOnWaitClass(dynamic),
										$elm$html$Html$Events$onClick($author$project$Page$System$Info$Types$OnShowProlongSleepDialog)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('control.Продлить режим')),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('uppercase-txt mode')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('Поиск'))
											]))
									])))
						]))
				]));
	});
var $author$project$Page$System$Info$State$just_text = function (v) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details-changed-mode')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details-revert-mode')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(v)
							]))
					]))
			]));
};
var $author$project$Page$System$Info$State$startNewState = F5(
	function (appState, state, sysId, i, dynamic) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content-item')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Page$System$Info$UI$disabledOnWait,
					dynamic,
					appState,
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details-blue-title blue-gradient-text'),
								$author$project$Page$System$Info$UI$disabledOnWaitClass(dynamic),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$System$Info$Types$OnSysCmdPre, sysId, i))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details-icon icon-search')
									]),
								_List_Nil),
								$elm$html$Html$text(
								t('control.Включить режим')),
								$elm$html$Html$text(' '),
								$elm$html$Html$text(
								t('control.' + state))
							])))
				]));
	});
var $author$project$Page$System$Info$State$stateTextAndWidget = F2(
	function (appState, system) {
		var t = appState.aX;
		var _v0 = system.dk;
		if (_v0.$ === 1) {
			return _Utils_Tuple2('Данные о состоянии еще не получены', _List_Nil);
		} else {
			var dynamic = _v0.a;
			var _v1 = dynamic.cQ;
			if (_v1.$ === 1) {
				return _Utils_Tuple2('идет определение...', _List_Nil);
			} else {
				switch (_v1.a.$) {
					case 5:
						var _v2 = _v1.a;
						return _Utils_Tuple2(
							'Феникс выключен.',
							_List_fromArray(
								[
									$author$project$Page$System$Info$State$just_text(
									t('control.Для включения - откройте крышку Феникса и нажмите кнопку.'))
								]));
					case 7:
						var _v3 = _v1.a;
						return _Utils_Tuple2(
							'Идет определение местоположения...',
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('control.Это может занять до 15 минут.'))
								]));
					case 0:
						var _v4 = _v1.a;
						var prolongCmd = function () {
							var _v6 = dynamic.ex;
							if (_v6.$ === 1) {
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col s12 l3')
											]),
										_List_fromArray(
											[
												A2($author$project$Components$UI$cmdButton, 'Отложить', $author$project$Page$System$Info$Types$OnShowProlongSleepDialog)
											]))
									]);
							} else {
								return _List_Nil;
							}
						}();
						var last_session = function () {
							var _v5 = dynamic.dH;
							if (_v5.$ === 1) {
								return $author$project$Types$Dt$fromInt(0);
							} else {
								var lastping = _v5.a;
								return lastping;
							}
						}();
						return _Utils_Tuple2(
							'Поиск',
							_List_fromArray(
								[
									A3($author$project$Page$System$Info$State$expectSleepIn, appState, dynamic, prolongCmd),
									A5($author$project$Page$System$Info$State$startNewState, appState, 'Ожидание', system.b6, $author$project$API$System$Hidden, dynamic)
								]));
					case 4:
						var _v7 = _v1.a;
						return _Utils_Tuple2(
							$author$project$API$System$stateAsString($author$project$API$System$Hidden),
							_List_fromArray(
								[
									A5($author$project$Page$System$Info$State$startNewState, appState, 'Поиск', system.b6, $author$project$API$System$Tracking, dynamic)
								]));
					default:
						var state = _v1.a;
						return _Utils_Tuple2(
							$author$project$API$System$stateAsString(state),
							_List_Nil);
				}
			}
		}
	});
var $author$project$Page$System$Info$State$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		var _v0 = A2($author$project$Page$System$Info$State$stateTextAndWidget, appState, system);
		var stateText = _v0.a;
		var controlWidgets = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.Текущий режим'))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('content-item fenix-status fenix-big-status')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('status-big-icon wait-status status-icon')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('status')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												t('control.' + stateText))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas icon fa-parking')
											]),
										_List_Nil)
									]))
							]),
						controlWidgets))
				]));
	});
var $author$project$Page$System$Info$view = F3(
	function (appState, model, system) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('wrapper-content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details-wrapper-bg')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('details-title manage-details-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(system.ej)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('details-items')
											]),
										_List_fromArray(
											[
												A3($author$project$Page$System$Info$State$view, appState, model, system),
												A3($author$project$Page$System$Info$Session$view, appState, model, system),
												A3($author$project$Page$System$Info$Position$view, appState, model, system),
												A3($author$project$Page$System$Info$Battery$view, appState, model, system),
												A3($author$project$Page$System$Info$SimCard$view, appState, model, system)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('details-footer')
											]),
										_List_fromArray(
											[
												A3($author$project$Page$System$Info$Footer$view, appState, model, system)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('copiedMess'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('showAnimate', model.aQ)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('phone-copied-message')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										t('control.Номер телефона был скопирован'))
									]))
							]))
					]),
				_Utils_ap(
					A3($author$project$Page$System$Info$Dialogs$prolongSleepDialogView, appState, model, system.b6),
					A4($author$project$Page$System$Info$confirmDialogs, appState, model, system.b6, system.dk))));
	});
var $author$project$Main$systemInfoRec = {
	s: function ($) {
		return $.bc;
	},
	p: A2($elm$core$Basics$composeR, $author$project$Types$SystemInfoMsg, $author$project$Types$OnPageMsg),
	am: F2(
		function (newModel, model) {
			return _Utils_update(
				model,
				{bc: newModel});
		}),
	_: $author$project$Types$Page$PUT_Public($author$project$Page$System$Info$update),
	K: $author$project$Types$Page$PT_System($author$project$Page$System$Info$view)
};
var $author$project$Page$GlobalMap$CalendarMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$GlobalMap$GPSMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$GlobalMap$HideAddress = {$: 3};
var $author$project$Components$UI$Calendar$LoadHours = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Map$Types$Marker = F3(
	function (pos, title, icon) {
		return {dy: icon, dY: pos, ej: title};
	});
var $author$project$Page$GlobalMap$ResponseAddress = function (a) {
	return {$: 2, a: a};
};
var $author$project$API$Geo$join = A2(
	$elm$core$List$foldl,
	F2(
		function (i, l) {
			return (i === '') ? l : ((l === '') ? i : (l + (', ' + i)));
		}),
	'');
var $author$project$API$Geo$optnl = $elm$core$Maybe$withDefault('');
var $author$project$API$Geo$orUse = F2(
	function (other, def) {
		if (!def.$) {
			var v = def.a;
			return $elm$core$Maybe$Just(v);
		} else {
			return other;
		}
	});
var $author$project$API$Geo$addressToString = function (a) {
	var compose = _List_fromArray(
		[
			$author$project$API$Geo$optnl(a.cE),
			$author$project$API$Geo$optnl(a.a5),
			_Utils_eq(a.a_, a.a5) ? '' : $author$project$API$Geo$optnl(
			A2(
				$author$project$API$Geo$orUse,
				a.cS,
				A2(
					$author$project$API$Geo$orUse,
					a.co,
					A2($author$project$API$Geo$orUse, a.cT, a.a_)))),
			$author$project$API$Geo$optnl(
			A2($author$project$API$Geo$orUse, a.cX, a.bM)),
			$author$project$API$Geo$optnl(a.cQ),
			$author$project$API$Geo$optnl(a.bR)
		]);
	return $author$project$API$Geo$join(compose);
};
var $author$project$API$Geo$Address = function (osm_type) {
	return function (display_name) {
		return function (road) {
			return function (house_number) {
				return function (building) {
					return function (supermarket) {
						return function (neighbourhood) {
							return function (suburb) {
								return function (city) {
									return function (town) {
										return function (state) {
											return function (country) {
												return {a_: building, bM: city, bR: country, dj: display_name, a5: house_number, co: neighbourhood, dU: osm_type, cE: road, cQ: state, cS: suburb, cT: supermarket, cX: town};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$API$Geo$addressDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
	_List_fromArray(
		['address', 'country']),
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['address', 'state']),
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
			_List_fromArray(
				['address', 'town']),
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
				_List_fromArray(
					['address', 'city']),
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
					_List_fromArray(
						['address', 'suburb']),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['address', 'neighbourhood']),
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
							_List_fromArray(
								['address', 'supermarket']),
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
								_List_fromArray(
									['address', 'building']),
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
									_List_fromArray(
										['address', 'house_number']),
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
										_List_fromArray(
											['address', 'road']),
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'display_name',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'osm_type',
												$elm$json$Json$Decode$string,
												$elm$json$Json$Decode$succeed($author$project$API$Geo$Address)))))))))))));
var $author$project$API$Geo$apiURL = 'https://gis.navi.cc/nominatim/reverse.php?format=json&accept-language=ua';
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.d7));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cz: reqs, cR: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.er;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cz));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.cR)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					c5: r.c5,
					db: r.db,
					dn: A2(_Http_mapExpect, func, r.dn),
					du: r.du,
					dL: r.dL,
					eh: r.eh,
					er: r.er,
					et: r.et
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{c5: false, db: r.db, dn: r.dn, du: r.du, dL: r.dL, eh: r.eh, er: r.er, et: r.et}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{db: $elm$http$Http$emptyBody, dn: r.dn, du: _List_Nil, dL: 'GET', eh: $elm$core$Maybe$Nothing, er: $elm$core$Maybe$Nothing, et: r.et});
};
var $author$project$API$Geo$getAddress = F2(
	function (_v0, msg) {
		var lat = _v0.a;
		var lon = _v0.b;
		return $elm$http$Http$get(
			{
				dn: A2($elm$http$Http$expectJson, msg, $author$project$API$Geo$addressDecoder),
				et: $author$project$API$Geo$apiURL + ('&lat=' + ($elm$core$String$fromFloat(lat) + ('&lon=' + $elm$core$String$fromFloat(lon))))
			});
	});
var $author$project$API$GPS$GotHours = $elm$core$Basics$identity;
var $author$project$API$GPS$HoursResp = F4(
	function (system, from, to, hours) {
		return {dt: from, dw: hours, ed: system, ek: to};
	});
var $author$project$API$GPS$toInt = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$int,
			A2(
			$elm$json$Json$Decode$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$toInt,
				$elm$core$Maybe$withDefault(0)),
			$elm$json$Json$Decode$string)
		]));
var $author$project$API$GPS$decodeHoursResp = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'hours',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'to',
		$author$project$API$GPS$toInt,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'from',
			$author$project$API$GPS$toInt,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'system',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$API$GPS$HoursResp)))));
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{c5: true, db: r.db, dn: r.dn, du: r.du, dL: r.dL, eh: r.eh, er: r.er, et: r.et}));
};
var $author$project$API$GPS$getHours = function (sysId) {
	return $elm$http$Http$riskyRequest(
		{
			db: $elm$http$Http$emptyBody,
			dn: A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$API$GPS$decodeHoursResp),
			du: _List_fromArray(
				[
					A2($elm$http$Http$header, 'Accept', 'application/json')
				]),
			dL: 'GET',
			eh: $elm$core$Maybe$Nothing,
			er: $elm$core$Maybe$Nothing,
			et: 'https://fx.navi.cc/1.0/geos/' + (sysId + '/hours?from=0&rand=1963672576&to=1000000&access_token=fenix_wip_token')
		});
};
var $author$project$Components$UI$Calendar$Opened = 1;
var $author$project$Components$UI$Calendar$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cQ: 1}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cQ: 0}),
					$elm$core$Platform$Cmd$none);
			default:
				var hs = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dw: hs}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$GlobalMap$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 5:
				var sysId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Page$GlobalMap$GPSMsg,
								$author$project$API$GPS$getHours(sysId))
							])),
					$elm$core$Maybe$Nothing);
			case 0:
				var newPos = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: newPos}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				var lat = msg.a.cd;
				var lng = msg.a.cf;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							M: A2($author$project$Page$Map$Types$LatLng, lat, lng),
							bf: _List_fromArray(
								[
									A3(
									$author$project$Page$Map$Types$Marker,
									A2($author$project$Page$Map$Types$LatLng, lat, lng),
									'???',
									'car')
								]),
							an: true
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$author$project$API$Geo$getAddress,
								_Utils_Tuple2(lat, lng),
								$author$project$Page$GlobalMap$ResponseAddress),
								A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always($author$project$Page$GlobalMap$HideAddress),
								$elm$core$Process$sleep(5000.0))
							])),
					$elm$core$Maybe$Nothing);
			case 2:
				if (!msg.a.$) {
					var address = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aA: $elm$core$Maybe$Just(
									$author$project$API$Geo$addressToString(address))
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var newPos = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: newPos}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 6:
				if (!msg.a.$) {
					var d = msg.a.a;
					var cmodel = A2(
						$author$project$Components$UI$Calendar$update,
						$author$project$Components$UI$Calendar$LoadHours(d.dw),
						model.L).a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{L: cmodel}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			default:
				var sub_msg = msg.a;
				var _v1 = A2($author$project$Components$UI$Calendar$update, sub_msg, model.L);
				var cmodel = _v1.a;
				var cmsg = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{L: cmodel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$GlobalMap$CalendarMsg, cmsg),
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$Page$System$Logs$update = F2(
	function (msg, model) {
		var sid = msg.a;
		var offset = msg.b;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($author$project$Page$System$Logs$getLogs, sid, offset)
					])),
			$elm$core$Maybe$Nothing);
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$API$Account$fixSysListRequest = function (syslist) {
	return A2(
		$author$project$API$Document$updateDocumentRequest,
		'account',
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'path',
					$elm$json$Json$Encode$string('systems')),
					_Utils_Tuple2(
					'value',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, syslist))
				])));
};
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var y = xs.a;
			var ys = xs.b;
			return _Utils_eq(x, y) ? ys : A2(
				$elm$core$List$cons,
				y,
				A2($elm_community$list_extra$List$Extra$remove, x, ys));
		}
	});
var $author$project$Types$Page$upmessageUpdate = F2(
	function (msg, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		if (msg.$ === 1) {
			return _Utils_Tuple2(model, cmd);
		} else {
			switch (msg.a.$) {
				case 0:
					var sid = msg.a.a;
					var _v2 = model.az;
					if (_v2.$ === 1) {
						return _Utils_Tuple2(model, cmd);
					} else {
						var account = _v2.a;
						var newSysList = A2($elm_community$list_extra$List$Extra$remove, sid, account.ef);
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmd,
										$author$project$API$websocketOut(
										$author$project$API$Account$fixSysListRequest(newSysList)),
										A2($elm$browser$Browser$Navigation$pushUrl, model.U, '/')
									])));
					}
				case 1:
					var menuMsg = msg.a.a;
					switch (menuMsg.$) {
						case 0:
							var langCode = menuMsg.a;
							return A2(
								$author$project$I18N$replaceTranslator,
								langCode,
								_Utils_Tuple2(model, cmd));
						case 1:
							var themeName = menuMsg.a;
							return A2(
								$author$project$Components$UI$Theme$replaceTheme,
								themeName,
								_Utils_Tuple2(model, cmd));
						case 2:
							var scaleName = menuMsg.a;
							return A2(
								$author$project$Components$UI$Scale$replaceScale,
								scaleName,
								_Utils_Tuple2(model, cmd));
						default:
							return _Utils_Tuple2(model, cmd);
					}
				default:
					var sysId = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bD: A2($elm$core$Dict$remove, sysId, model.bD)
							}),
						cmd);
			}
		}
	});
var $author$project$Types$Page$updateOverRec = F3(
	function (msg, rec, model) {
		var _v0 = rec._;
		if (!_v0.$) {
			var u = _v0.a;
			var _v1 = A2(
				u,
				msg,
				rec.s(model));
			var updatedModel = _v1.a;
			var upstream = _v1.b;
			return _Utils_Tuple2(
				A2(rec.am, updatedModel, model),
				A2($elm$core$Platform$Cmd$map, rec.p, upstream));
		} else {
			var u = _v0.a;
			var _v2 = A2(
				u,
				msg,
				rec.s(model));
			var updatedModel = _v2.a;
			var upstream = _v2.b;
			var upmessage = _v2.c;
			return A2(
				$author$project$Types$Page$upmessageUpdate,
				upmessage,
				_Utils_Tuple2(
					A2(rec.am, updatedModel, model),
					A2($elm$core$Platform$Cmd$map, rec.p, upstream)));
		}
	});
var $author$project$Main$updatePage = F2(
	function (pageMsg, model) {
		switch (pageMsg.$) {
			case 0:
				var homeMsg = pageMsg.a;
				return A3($author$project$Types$Page$updateOverRec, homeMsg, $author$project$Main$homeRec, model);
			case 1:
				var loginMsg = pageMsg.a;
				return A3($author$project$Types$Page$updateOverRec, loginMsg, $author$project$Main$loginRec, model);
			case 6:
				var smsg = pageMsg.a;
				return A3($author$project$Types$Page$updateOverRec, smsg, $author$project$Main$linkSysRec, model);
			case 5:
				var globalMapMsg = pageMsg.a;
				var _v1 = A2($author$project$Page$GlobalMap$update, globalMapMsg, model.af);
				var updatedModel = _v1.a;
				var upstream = _v1.b;
				var upmessage = _v1.c;
				return A2(
					$author$project$Types$Page$upmessageUpdate,
					upmessage,
					_Utils_Tuple2(
						_Utils_update(
							model,
							{af: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeR, $author$project$Types$GlobalMapMsg, $author$project$Types$OnPageMsg),
							upstream)));
			case 2:
				var globalInfoMsg = pageMsg.a;
				return A3($author$project$Types$Page$updateOverRec, globalInfoMsg, $author$project$Main$systemInfoRec, model);
			case 3:
				var msg = pageMsg.a;
				return A3($author$project$Types$Page$updateOverRec, msg, $author$project$Main$systemConfigRec, model);
			case 4:
				var msg = pageMsg.a;
				var _v2 = A2($author$project$Page$System$Logs$update, msg, model.as);
				var updatedModel = _v2.a;
				var upstream = _v2.b;
				var upmessage = _v2.c;
				return A2(
					$author$project$Types$Page$upmessageUpdate,
					upmessage,
					_Utils_Tuple2(
						_Utils_update(
							model,
							{as: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeR, $author$project$Types$SystemLogsMsg, $author$project$Types$OnPageMsg),
							upstream)));
			default:
				var msg = pageMsg.a;
				var _v3 = A2($author$project$Components$UI$Menu$update, msg, model.j);
				var updatedModel = _v3.a;
				var upstream = _v3.b;
				var upmessage = _v3.c;
				var newModel = _Utils_update(
					model,
					{j: updatedModel});
				var newCmd = A2(
					$elm$core$Platform$Cmd$map,
					A2($elm$core$Basics$composeR, $author$project$Types$MenuMsg, $author$project$Types$OnPageMsg),
					upstream);
				if (upmessage.$ === 1) {
					return _Utils_Tuple2(newModel, newCmd);
				} else {
					switch (upmessage.a.$) {
						case 0:
							var langCode = upmessage.a.a;
							return A2(
								$author$project$I18N$replaceTranslator,
								langCode,
								_Utils_Tuple2(newModel, newCmd));
						case 1:
							var themeName = upmessage.a.a;
							return A2(
								$author$project$Components$UI$Theme$replaceTheme,
								themeName,
								_Utils_Tuple2(newModel, newCmd));
						case 2:
							var scaleName = upmessage.a.a;
							return A2(
								$author$project$Components$UI$Scale$replaceScale,
								scaleName,
								_Utils_Tuple2(newModel, newCmd));
						default:
							var _v5 = upmessage.a;
							return _Utils_Tuple2(
								newModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											newCmd,
											$author$project$Main$saveToken('')
										])));
					}
				}
		}
	});
var $author$project$AppState$updateTimeZone = F2(
	function (zone, appState) {
		return _Utils_update(
			appState,
			{bC: zone});
	});
var $author$project$API$websocketOpen = _Platform_outgoingPort('websocketOpen', $elm$json$Json$Encode$string);
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 11:
				var pageMsg = msg.a;
				return A2($author$project$Main$updatePage, pageMsg, model);
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								j: $author$project$Components$UI$Menu$hideMobMenu(model.j)
							}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.U,
							$elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var url = msg.a;
				var _v2 = A2($elm$url$Url$Parser$parse, $author$project$Page$Route$routeParser, url);
				if (!_v2.$) {
					var page = _v2.a;
					var updateModel = _Utils_update(
						model,
						{ak: page});
					return A2($author$project$Main$computeViewForPage, page, updateModel);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var message = msg.a;
				var res = $author$project$API$parsePayload(message);
				if (res.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					switch (res.a.$) {
						case 1:
							var token = res.a.a;
							return _Utils_Tuple2(
								model,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Main$saveToken(token.eu),
											$author$project$API$websocketOut(
											$author$project$API$authRequest(token.eu))
										])));
						case 2:
							switch (res.a.b.$) {
								case 0:
									var _v4 = res.a;
									var document = _v4.b.a;
									var leaveIfmember = function (sid) {
										return A2($elm$core$List$member, sid, document.ef) ? $elm$core$Platform$Cmd$none : $elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													A2($elm$browser$Browser$Navigation$pushUrl, model.U, '/')
												]));
									};
									var next = function () {
										var _v5 = model.ak;
										switch (_v5.$) {
											case 2:
												return $elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2($elm$browser$Browser$Navigation$pushUrl, model.U, '/')
														]));
											case 1:
												return $elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2($elm$browser$Browser$Navigation$pushUrl, model.U, '/')
														]));
											case 12:
												return $elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2($elm$browser$Browser$Navigation$pushUrl, model.U, '/')
														]));
											case 9:
												var sid = _v5.a;
												return leaveIfmember(sid);
											case 8:
												var sid = _v5.a;
												return leaveIfmember(sid);
											case 6:
												var sid = _v5.a;
												return leaveIfmember(sid);
											default:
												return $elm$core$Platform$Cmd$none;
										}
									}();
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												az: $elm$core$Maybe$Just(document)
											}),
										next);
								case 1:
									var _v6 = res.a;
									var sysId = _v6.a;
									var document = _v6.b.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												ef: A3($elm$core$Dict$insert, sysId, document, model.ef)
											}),
										$elm$core$Platform$Cmd$none);
								case 2:
									var _v7 = res.a;
									var sysId = _v7.a;
									var document = _v7.b.a;
									var _v8 = A2($elm$core$Dict$get, sysId, model.ef);
									if (_v8.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var system = _v8.a;
										var new_system = _Utils_update(
											system,
											{
												dk: $elm$core$Maybe$Just(document)
											});
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{
													ef: A3($elm$core$Dict$insert, sysId, new_system, model.ef)
												}),
											$elm$core$Platform$Cmd$none);
									}
								case 3:
									var _v9 = res.a;
									var sysId = _v9.a;
									var document = _v9.b.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												aK: A3($elm$core$Dict$insert, sysId, document, model.aK)
											}),
										$elm$core$Platform$Cmd$none);
								case 4:
									var _v10 = res.a;
									var sysId = _v10.a;
									var document = _v10.b.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												dV: A3($elm$core$Dict$insert, sysId, document, model.dV)
											}),
										$elm$core$Platform$Cmd$none);
								case 5:
									var _v11 = res.a;
									var sysId = _v11.a;
									var document = _v11.b.a;
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								default:
									var _v12 = res.a;
									var sysId = _v12.a;
									var document = _v12.b.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												bD: A3($elm$core$Dict$insert, sysId, document, model.bD)
											}),
										$elm$core$Platform$Cmd$none);
							}
						case 3:
							var error = res.a.a;
							var _v13 = $author$project$API$Error$errorMessageString(error);
							if (_v13.$ === 1) {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							} else {
								var astext = _v13.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											aH: $elm$core$Maybe$Just(astext)
										}),
									$elm$core$Platform$Cmd$none);
							}
						default:
							var command = res.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 4:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$API$websocketOpen(url));
			case 5:
				if (!msg.a) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aE: 0}),
						$elm$core$Platform$Cmd$none);
				} else {
					var authCmd = function () {
						var _v14 = model.av;
						if (_v14.$ === 1) {
							return $elm$core$Platform$Cmd$none;
						} else {
							var token = _v14.a;
							return $author$project$API$websocketOut(
								$author$project$API$authRequest(token));
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aE: 1}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[authCmd])));
				}
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aH: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var zone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bJ: A2($author$project$AppState$updateTimeZone, zone, model.bJ)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bJ: A2($author$project$AppState$updateNow, time, model.bJ)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aT: !model.aT}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aT: false}),
					$elm$core$Platform$Cmd$none);
			default:
				return A2(
					$author$project$Main$updatePage,
					$author$project$Types$MenuMsg($author$project$Components$UI$Menu$HidePopups),
					model);
		}
	});
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var _v0 = $author$project$Page$System$Logs$init($elm$core$Maybe$Nothing);
		var systemLogsModel = _v0.a;
		var _v1 = $author$project$Page$System$Config$init($elm$core$Maybe$Nothing);
		var systemConfigModel = _v1.a;
		var _v2 = $author$project$Page$Login$init;
		var loginModel = _v2.a;
		var _v3 = $author$project$Page$LinkSys$init;
		var linkSysModel = _v3.a;
		var _v4 = $author$project$Page$System$Info$init;
		var infoModel = _v4.a;
		var _v5 = $author$project$Page$Home$init;
		var homeModel = _v5.a;
		var _v6 = A4($author$project$Page$GlobalMap$init, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
		var globalMapModel = _v6.a;
		var model = {
			az: $elm$core$Maybe$Nothing,
			aB: flags.aB,
			bJ: A3($author$project$AppState$initModel, flags.cc, flags.cV, flags.cH),
			aE: 0,
			aH: $elm$core$Maybe$Nothing,
			af: globalMapModel,
			a4: homeModel,
			bc: infoModel,
			U: key,
			aJ: linkSysModel,
			bd: loginModel,
			aK: $elm$core$Dict$empty,
			j: $author$project$Components$UI$Menu$init,
			ak: $author$project$Page$Route$Home,
			dV: $elm$core$Dict$empty,
			aT: false,
			aW: systemConfigModel,
			as: systemLogsModel,
			ef: $elm$core$Dict$empty,
			av: flags.av,
			bD: $elm$core$Dict$empty,
			et: url
		};
		var _v7 = A2(
			$author$project$Main$update,
			$author$project$Types$UrlChanged(url),
			model);
		var navedModel = _v7.a;
		var navedCmd = _v7.b;
		return _Utils_Tuple2(
			navedModel,
			$elm$core$Platform$Cmd$batch(
				_Utils_ap(
					_List_fromArray(
						[
							navedCmd,
							$author$project$API$websocketOpen(flags.aB)
						]),
					_Utils_ap(
						A2($author$project$AppState$initCmd, $author$project$Types$TimeZoneDetected, $author$project$Types$ReceiveNow),
						_Utils_eq(model.av, $elm$core$Maybe$Nothing) ? _List_fromArray(
							[
								A2($elm$browser$Browser$Navigation$pushUrl, key, '/login')
							]) : _List_Nil))));
	});
var $author$project$Types$WebsocketIn = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$WebsocketOpened = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {cv: processes, cU: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.cv;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.cU);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$API$websocketIn = _Platform_incomingPort('websocketIn', $elm$json$Json$Decode$string);
var $author$project$API$websocketOpened = _Platform_incomingPort('websocketOpened', $elm$json$Json$Decode$bool);
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$API$websocketOpened($author$project$Types$WebsocketOpened),
				$author$project$API$websocketIn($author$project$Types$WebsocketIn),
				A2($elm$time$Time$every, 1000.0, $author$project$Types$ReceiveNow)
			]));
};
var $author$project$Types$BodyClick = {$: 12};
var $author$project$Types$OnCloseModal = {$: 6};
var $author$project$Components$UI$connectionWidwet = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-overlay'),
				A2($elm$html$Html$Attributes$style, 'z-index', '1002'),
				A2($elm$html$Html$Attributes$style, 'display', 'block'),
				A2($elm$html$Html$Attributes$style, 'opacity', '0.5')
			]),
		_List_Nil),
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('connection'),
				A2($elm$html$Html$Attributes$style, 'z-index', '1003')
			]),
		_List_fromArray(
			[
				$author$project$Components$UI$text('Нет соединения с сервером')
			]))
	]);
var $author$project$Components$UI$layout = function (click) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('layout'),
				$elm$html$Html$Events$onClick(click)
			]));
};
var $author$project$Page$Route$HomeBase = 0;
var $author$project$Page$Route$MapBase = 1;
var $author$project$Page$Route$SystemConfigBase = 4;
var $author$project$Page$Route$SystemInfoBase = 2;
var $author$project$Page$Route$SystemLogsBase = 3;
var $author$project$Components$UI$Menu$ToggleDeskMenu = {$: 12};
var $author$project$Components$UI$Menu$ToggleMobMenu = {$: 11};
var $author$project$Components$UI$Menu$fenixStatus = F2(
	function (_v0, msystem) {
		var t = _v0.aX;
		if (msystem.$ === 1) {
			return _List_Nil;
		} else {
			var system = msystem.a;
			var stateText = function () {
				var _v2 = system.dk;
				if (_v2.$ === 1) {
					return 'Данные о состоянии еще не получены';
				} else {
					var dynamic = _v2.a;
					var _v3 = dynamic.cQ;
					if (_v3.$ === 1) {
						return 'идет определение...';
					} else {
						switch (_v3.a.$) {
							case 5:
								var _v4 = _v3.a;
								return 'Феникс выключен.';
							case 7:
								var _v5 = _v3.a;
								return 'Идет определение местоположения...';
							case 0:
								var _v6 = _v3.a;
								return 'Поиск';
							case 4:
								var _v7 = _v3.a;
								return $author$project$API$System$stateAsString($author$project$API$System$Hidden);
							default:
								var state = _v3.a;
								return $author$project$API$System$stateAsString(state);
						}
					}
				}
			}();
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('status-icon wait-status')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('status')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							t('control.' + stateText))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fas icon fa-parking')
						]),
					_List_Nil)
				]);
		}
	});
var $author$project$Components$UI$Menu$ShowAccountPopup = {$: 6};
var $author$project$Components$UI$Menu$ShowLogoutModal = {$: 7};
var $author$project$Components$UI$Menu$menuAccount = F3(
	function (account, _v0, _v1) {
		var t = _v0.aX;
		var accountPopup = _v1.ac;
		var account_text = account.d$;
		return A2(
			$author$project$Components$UI$Menu$activableDropdown,
			accountPopup,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-title'),
							$author$project$Components$UI$Menu$onClickStopPropagation($author$project$Components$UI$Menu$ShowAccountPopup)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accaunt-icon')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accaunt-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(account_text)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dropdown-list')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('menu.Аккаунт'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$ShowLogoutModal)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon-logout logout-image')
												]),
											_List_Nil),
											$elm$html$Html$text(
											t('menu.Выйти'))
										]))
								]))
						]))
				]));
	});
var $author$project$Components$UI$Menu$HideLogoutModal = {$: 8};
var $author$project$Components$UI$Menu$OnLogout = {$: 10};
var $author$project$Components$UI$Menu$modal = F2(
	function (show, _v0) {
		var t = _v0.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					'modal-bg' + (show ? ' show' : ''))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-wr')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-content modal-sm')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-close-sm close modal-close-btn'),
											$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$HideLogoutModal)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title modal-title-sm')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('menu.Выйти?'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-body')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('menu.Вы действительно хотите выйти?'))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-btn-group')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-md btn-secondary modal-close-btn'),
													$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$HideLogoutModal)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('Нет'))
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-md btn-cancel'),
													$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$OnLogout)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('Да'))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Components$UI$Menu$view = F5(
	function (pageBase, account, appState, msystem, model) {
		var t = appState.aX;
		var themePopup = model.z;
		var languagePopup = model.t;
		var sicon = function () {
			if (msystem.$ === 1) {
				return 'car';
			} else {
				var system = msystem.a;
				return system.dy;
			}
		}();
		var popupShow = function (i) {
			if (i) {
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'block')
					]);
			} else {
				return _List_Nil;
			}
		};
		var menuItemWithSubmenu = F4(
			function (url, active, icon, title_) {
				var submenu = F3(
					function (suburl, subtitle, subactive) {
						return A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('submenu-settings-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('active', subactive)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href(suburl)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('menu.' + subtitle))
										]))
								]));
					});
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('menu-settings'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('active', active)
										])),
									$elm$html$Html$Attributes$href(url)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-settings submenu-icon')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('submenu-item-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('menu.' + title_))
										]))
								]))
						]));
			});
		var menuItem = F4(
			function (url, active, icon, title_) {
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('active', active)
										])),
									$elm$html$Html$Attributes$href(url)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(icon + ' submenu-icon')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('submenu-item-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('menu.' + title_))
										]))
								]))
						]));
			});
		var class4mob = $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('menu-hidden-mob', model.C),
					_Utils_Tuple2('menu-opened-mob', !model.C)
				]));
		var class4desk = $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('menu-opened', model.D),
					_Utils_Tuple2('menu-hidden', !model.D)
				]));
		var _v0 = function () {
			if (msystem.$ === 1) {
				return _Utils_Tuple2(false, _List_Nil);
			} else {
				var system = msystem.a;
				return _Utils_Tuple2(
					true,
					_List_fromArray(
						[
							A4(menuItem, '/map/' + system.b6, pageBase === 1, 'icon-map', 'Карта'),
							A4(menuItem, '/system/' + system.b6, pageBase === 2, 'icon-manage', 'Управление'),
							A4(menuItemWithSubmenu, '/system/' + (system.b6 + '/config'), pageBase === 4, 'icon-manage', 'Настройки'),
							A4(menuItem, '/system/' + (system.b6 + '/logs'), pageBase === 3, 'icon-calendar', 'События')
						]));
			}
		}();
		var systemSelected = _v0.a;
		var systemMenu = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('menu-show-btn-mob mobHiddenBtn mobShowBtn'),
						$elm$html$Html$Attributes$id('showMenuMob'),
						$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$ToggleMobMenu)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('menu'),
						class4mob,
						class4desk
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu-header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('logo')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu-show-btn hiddenBtn'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('showBtn', !model.D)
											])),
										$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$ToggleDeskMenu)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu-close-btn hiddenBtn'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('showBtn', model.D)
											])),
										$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$ToggleDeskMenu)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu-close-btn-mob mobShowBtn mobHiddenBtn'),
										$elm$html$Html$Attributes$id('closeMenuMob'),
										$elm$html$Html$Events$onClick($author$project$Components$UI$Menu$ToggleMobMenu)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu-body scroll')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu-items')
									]),
								_List_fromArray(
									[
										A4(menuItem, '/', true, 'list-icon', 'Список Фениксов')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu-options-wr')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('menu-options')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('menu-options-title')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('menu.Системные опции'))
													])),
												A2($author$project$Components$UI$Menu$menuTheme, appState, model),
												A2($author$project$Components$UI$Menu$menuScale, appState, model),
												A2($author$project$Components$UI$Menu$menuLanguage, appState, model)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('menu-options')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('menu-options-title')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														t('menu.Аккаунт'))
													])),
												A3($author$project$Components$UI$Menu$menuAccount, account, appState, model)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('submenu scroll', true),
												_Utils_Tuple2('submenu-active', systemSelected)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon-' + (sicon + ' submenu-type'))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('submenu-header')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('submenu-back'),
														$elm$html$Html$Attributes$href('/')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('arrow')
															]),
														_List_Nil),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('title')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																t('Список Фениксов'))
															]))
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('submenu-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														function () {
															if (msystem.$ === 1) {
																return '';
															} else {
																var system = msystem.a;
																return system.ej;
															}
														}())
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('submenu-status')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('fenix-status')
															]),
														A2($author$project$Components$UI$Menu$fenixStatus, appState, msystem))
													]))
											])),
										A2(
										$elm$html$Html$ul,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('submenu-items')
											]),
										systemMenu)
									])),
								A2($author$project$Components$UI$Menu$modal, model.H, appState)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('menu-close-bg closeMenuBg'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('menu-close-bg-opened', !model.C)
							]))
					]),
				_List_Nil)
			]);
	});
var $author$project$Main$viewMenu = function (model) {
	var _v0 = model.az;
	if (_v0.$ === 1) {
		return _List_Nil;
	} else {
		var account = _v0.a;
		var commonMenu = A2(
			$elm$core$List$map,
			$elm$html$Html$map(
				A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
			A5($author$project$Components$UI$Menu$view, 0, account, model.bJ, $elm$core$Maybe$Nothing, model.j));
		var _v1 = model.ak;
		switch (_v1.$) {
			case 0:
				return commonMenu;
			case 12:
				return commonMenu;
			case 8:
				var sysId = _v1.a;
				var _v2 = A2($elm$core$Dict$get, sysId, model.ef);
				if (_v2.$ === 1) {
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5($author$project$Components$UI$Menu$view, 2, account, model.bJ, $elm$core$Maybe$Nothing, model.j));
				} else {
					var system = _v2.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5(
							$author$project$Components$UI$Menu$view,
							2,
							account,
							model.bJ,
							$elm$core$Maybe$Just(system),
							model.j));
				}
			case 10:
				var sysId = _v1.a;
				var _v3 = A2($elm$core$Dict$get, sysId, model.ef);
				if (_v3.$ === 1) {
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5($author$project$Components$UI$Menu$view, 3, account, model.bJ, $elm$core$Maybe$Nothing, model.j));
				} else {
					var system = _v3.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5(
							$author$project$Components$UI$Menu$view,
							3,
							account,
							model.bJ,
							$elm$core$Maybe$Just(system),
							model.j));
				}
			case 9:
				var sysId = _v1.a;
				var _v4 = A2($elm$core$Dict$get, sysId, model.ef);
				if (_v4.$ === 1) {
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5($author$project$Components$UI$Menu$view, 4, account, model.bJ, $elm$core$Maybe$Nothing, model.j));
				} else {
					var system = _v4.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5(
							$author$project$Components$UI$Menu$view,
							4,
							account,
							model.bJ,
							$elm$core$Maybe$Just(system),
							model.j));
				}
			case 6:
				var sysId = _v1.a;
				var mlat = _v1.b;
				var mlng = _v1.c;
				var mday = _v1.d;
				var _v5 = A2($elm$core$Dict$get, sysId, model.ef);
				if (_v5.$ === 1) {
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5($author$project$Components$UI$Menu$view, 1, account, model.bJ, $elm$core$Maybe$Nothing, model.j));
				} else {
					var system = _v5.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map(
							A2($elm$core$Basics$composeL, $author$project$Types$OnPageMsg, $author$project$Types$MenuMsg)),
						A5(
							$author$project$Components$UI$Menu$view,
							1,
							account,
							model.bJ,
							$elm$core$Maybe$Just(system),
							model.j));
				}
			default:
				return _List_Nil;
		}
	}
};
var $author$project$Page$Login$OnEula = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Login$Register = {$: 4};
var $author$project$Page$Login$registerButton = F2(
	function (_v0, model) {
		var t = _v0.aX;
		return (model.A === '') ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Введите имя пользователя'))
				])) : ((model.r === '') ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Укажите пароль'))
				])) : ((model.al === '') ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Укажите пароль повторно'))
				])) : ((!_Utils_eq(model.r, model.al)) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Пароль указан неверно'))
				])) : ((!model.aI) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Вы должны принять условия'))
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-lg btn-primary login-btn'),
					$elm$html$Html$Events$onClick($author$project$Page$Login$Register)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					t('login.Зарегистрироваться'))
				]))))));
	});
var $author$project$Page$Login$authView = F2(
	function (appState, model) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container container-login')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrapper-bg')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo logo-wr')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$alt('Logo'),
													$elm$html$Html$Attributes$src('images/logo.svg')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('login.Создать аккаунт'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-subtitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											t('login.Чтобы начать работать'))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-inputs')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-st')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
															A2($elm$html$Html$Attributes$attribute, 'required', ''),
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$value(model.A),
															$elm$html$Html$Events$onInput(
															function (_new) {
																return $author$project$Page$Login$Update(
																	_Utils_update(
																		model,
																		{A: _new}));
															})
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-label')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Введите Ваш логин'))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-st password')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
															$elm$html$Html$Attributes$id('password'),
															A2($elm$html$Html$Attributes$attribute, 'required', ''),
															$elm$html$Html$Attributes$type_(
															$author$project$Page$Login$password_type(model.x)),
															$elm$html$Html$Attributes$value(model.r),
															$elm$html$Html$Events$onInput(
															function (_new) {
																return $author$project$Page$Login$Update(
																	_Utils_update(
																		model,
																		{r: _new}));
															})
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-label')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Введите Ваш пароль'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class(
															'password-icon' + $author$project$Page$Login$active_if(model.x)),
															$elm$html$Html$Attributes$id('passwordBtn'),
															$elm$html$Html$Events$onClick($author$project$Page$Login$ShowPassword)
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input-st password')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
															$elm$html$Html$Attributes$id('password'),
															A2($elm$html$Html$Attributes$attribute, 'required', ''),
															$elm$html$Html$Attributes$type_(
															$author$project$Page$Login$password_type(model.x)),
															$elm$html$Html$Attributes$value(model.al),
															$elm$html$Html$Events$onInput(
															function (_new) {
																return $author$project$Page$Login$Update(
																	_Utils_update(
																		model,
																		{al: _new}));
															})
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input-label')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Повторите Ваш пароль'))
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class(
															'password-icon' + $author$project$Page$Login$active_if(model.x)),
															$elm$html$Html$Attributes$id('passwordBtn'),
															$elm$html$Html$Events$onClick($author$project$Page$Login$ShowPassword)
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('checkmark-wrap')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('checkboxContainer')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$name(''),
																	$elm$html$Html$Attributes$type_('checkbox'),
																	$elm$html$Html$Attributes$value(''),
																	$elm$html$Html$Attributes$checked(model.aI),
																	$elm$html$Html$Events$onCheck($author$project$Page$Login$OnEula)
																]),
															_List_Nil),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('checkmark')
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('checkmark-text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Я прочитал и принимаю условия')),
															$elm$html$Html$text(' '),
															A2(
															$elm$html$Html$a,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$href('#')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	t('login.пользовательского соглашения'))
																]))
														]))
												])),
											A2($author$project$Page$Login$registerButton, appState, model),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('accaunt-link')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Уже есть аккаунт?'))
														])),
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('link'),
															$elm$html$Html$Attributes$href('/login')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															t('login.Войти в систему'))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrapper-content-footer')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Page$Login$MenuMsg,
									A2($author$project$Components$UI$Menu$menuLanguage, appState, model.j)),
									A2(
									$elm$html$Html$map,
									$author$project$Page$Login$MenuMsg,
									A2($author$project$Components$UI$Menu$menuTheme, appState, model.j))
								]))
						]))
				]));
	});
var $author$project$Main$authRec = _Utils_update(
	$author$project$Main$loginRec,
	{
		K: $author$project$Types$Page$PT_Nodata($author$project$Page$Login$authView)
	});
var $author$project$Main$noRightsMsg = function (t) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				t('errors.Ошибка! Система не существует или у вас недостаточно прав для просмотра.')),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn'),
						$elm$html$Html$Attributes$href('/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('errors.Вернуться на главную')
					]))
			]));
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Page$GlobalMap$view = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A3($elm$html$Html$node, 'leaflet-map', _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('control')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('На гравную')
						]))
				]))
		]));
var $author$project$Page$NotFound$view = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('404. Page not found.')
		]));
var $author$project$Page$System$Logs$OnToday = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Components$DateTime$toNumMonth = function (month) {
	switch (month) {
		case 0:
			return '01';
		case 1:
			return '02';
		case 2:
			return '03';
		case 3:
			return '04';
		case 4:
			return '05';
		case 5:
			return '06';
		case 6:
			return '07';
		case 7:
			return '08';
		case 8:
			return '09';
		case 9:
			return '10';
		case 10:
			return '11';
		default:
			return '12';
	}
};
var $author$project$Components$DateTime$dateFormat = F2(
	function (timeZone, time) {
		var month = $author$project$Components$DateTime$toNumMonth(
			A2($elm$time$Time$toMonth, timeZone, time));
		var day = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, timeZone, time)));
		return day + ('/' + month);
	});
var $author$project$Components$DateTime$timeFormat = F2(
	function (timeZone, time) {
		var minute = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toMinute, timeZone, time)));
		var hour = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toHour, timeZone, time)));
		return hour + (':' + minute);
	});
var $author$project$Page$System$Logs$userReplace = F3(
	function (userRegex, replacer, string) {
		var _v0 = $elm$regex$Regex$fromString(userRegex);
		if (_v0.$ === 1) {
			return string;
		} else {
			var regex = _v0.a;
			return A3($elm$regex$Regex$replace, regex, replacer, string);
		}
	});
var $author$project$Page$System$Logs$view = F4(
	function (appState, model, system, mlogs) {
		var t = appState.aX;
		var logsBody = function () {
			if (mlogs.$ === 1) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								t('logs.Не загружено. Нажимте кнопку Обновить.'))
							]))
					]);
			} else {
				var logs = mlogs.a;
				var logLine = function (itm) {
					var time = A2(
						$author$project$Components$DateTime$timeFormat,
						appState.bC,
						$author$project$Types$Dt$toPosix(itm.ae));
					var date = A2(
						$author$project$Components$DateTime$dateFormat,
						appState.bC,
						$author$project$Types$Dt$toPosix(itm.ae));
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-date')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(date)
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(time)
											]))
									])),
								$elm$html$Html$text(
								t(
									'logs.' + A3(
										$author$project$Page$System$Logs$userReplace,
										'<[^>]*>',
										function (_v1) {
											return '';
										},
										itm.eg)))
							]));
				};
				return A2($elm$core$List$map, logLine, logs);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrapper-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details-wrapper-bg scroll-wr')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('details-header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('details-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													t('menu.События'))
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('revert-btn'),
													$elm$html$Html$Events$onClick(
													A2($author$project$Page$System$Logs$OnToday, system.b6, 100000000000))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon-revert')
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$ul,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('list action-list'),
											$elm$html$Html$Attributes$id('scroll-wr')
										]),
									logsBody)
								]))
						]))
				]));
	});
var $author$project$Main$view4System = F3(
	function (sysId, model, pageView) {
		var _v0 = A2($elm$core$Dict$get, sysId, model.ef);
		if (_v0.$ === 1) {
			return $author$project$Main$noRightsMsg(model.bJ.aX);
		} else {
			var system = _v0.a;
			return pageView(system);
		}
	});
var $author$project$Main$view4SystemRec = F3(
	function (sysId, model, ir) {
		var _v0 = ir.K;
		switch (_v0.$) {
			case 0:
				var v = _v0.a;
				return A3(
					$author$project$Main$view4System,
					sysId,
					model,
					A2(
						$elm$core$Basics$composeR,
						A2(
							v,
							model.bJ,
							ir.s(model)),
						$elm$html$Html$map(ir.p)));
			case 1:
				var v = _v0.a;
				var _v1 = A2($elm$core$Dict$get, sysId, model.ef);
				if (_v1.$ === 1) {
					return A2(
						$elm$html$Html$map,
						ir.p,
						A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Error')
								])));
				} else {
					var system = _v1.a;
					return A2(
						$elm$html$Html$map,
						ir.p,
						A4(
							v,
							model.bJ,
							ir.s(model),
							system,
							A2($elm$core$Dict$get, sysId, model.dV)));
				}
			case 2:
				var v = _v0.a;
				return A2(
					$elm$html$Html$map,
					ir.p,
					A2(
						v,
						model.bJ,
						ir.s(model)));
			default:
				var v = _v0.a;
				return A2(
					$elm$html$Html$map,
					ir.p,
					A4(
						v,
						model.bJ,
						ir.s(model),
						model.az,
						model.ef));
		}
	});
var $author$project$Page$GlobalMap$CenterChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$GlobalMap$GetAddress = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Map$Types$decodeLatLng = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Page$Map$Types$LatLng,
	A2($elm$json$Json$Decode$field, 'lat', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'lng', $elm$json$Json$Decode$float));
var $author$project$Page$Map$Types$encodeLatLng = function (_v0) {
	var lat = _v0.cd;
	var lng = _v0.cf;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'lat',
				$elm$json$Json$Encode$float(lat)),
				_Utils_Tuple2(
				'lng',
				$elm$json$Json$Encode$float(lng))
			]));
};
var $author$project$Page$Map$Types$encodeMarker = function (_v0) {
	var pos = _v0.dY;
	var title = _v0.ej;
	var icon = _v0.dy;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'pos',
				$author$project$Page$Map$Types$encodeLatLng(pos)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(title)),
				_Utils_Tuple2(
				'icon',
				$elm$json$Json$Encode$string(icon))
			]));
};
var $author$project$Page$GlobalMap$encodeTrack = F2(
	function (sysId, day) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'sysId',
					$elm$json$Json$Encode$string(sysId)),
					_Utils_Tuple2(
					'day',
					$elm$json$Json$Encode$string(day))
				]));
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Components$DateTime$dateTimeFormat = F2(
	function (timeZone, time) {
		return A2($author$project$Components$DateTime$dateFormat, timeZone, time) + (' ' + A2($author$project$Components$DateTime$timeFormat, timeZone, time));
	});
var $author$project$Page$GlobalMap$sysPosition = F4(
	function (appState, sid, maybe_dynamic, maddress) {
		if (maybe_dynamic.$ === 1) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Данные от трекера еще не получены')
				]);
		} else {
			var dynamic = maybe_dynamic.a;
			var _v1 = _Utils_Tuple3(dynamic.dI, dynamic.dJ, dynamic.ae);
			if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
				var latitude = _v1.a.a;
				var longitude = _v1.b.a;
				var dt = _v1.c.a;
				return _List_fromArray(
					[
						$author$project$Components$UI$row_item(
						_List_fromArray(
							[
								$elm$html$Html$text(
								appState.aX('map.Последнее положение определено ') + (A2(
									$author$project$Components$DateTime$dateTimeFormat,
									appState.bC,
									$author$project$Types$Dt$toPosix(dt)) + ' '))
							])),
						function () {
						if (maddress.$ === 1) {
							return $elm$html$Html$text('');
						} else {
							var address = maddress.a;
							return $author$project$Components$UI$row_item(
								_List_fromArray(
									[
										$elm$html$Html$text(address)
									]));
						}
					}()
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$Components$UI$row_item(
						_List_fromArray(
							[
								$elm$html$Html$text('Положение неизвестно')
							]))
					]);
			}
		}
	});
var $author$project$Components$UI$Calendar$DoClose = {$: 1};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Components$DateTime$dateFormatFull = F2(
	function (timeZone, time) {
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, timeZone, time));
		var month = $author$project$Components$DateTime$toNumMonth(
			A2($elm$time$Time$toMonth, timeZone, time));
		var day = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, timeZone, time)));
		return day + ('/' + (month + ('/' + year)));
	});
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Components$UI$Calendar$dayListWidget = F4(
	function (timeZone, hours, sysId, mday) {
		var key = function (_v2) {
			var h = _v2.a;
			var d = _v2.b;
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'selected',
								_Utils_eq(
									A2($elm$core$Maybe$withDefault, '', mday),
									d))
							])),
						$elm$html$Html$Attributes$href(
						A2(
							$elm$url$Url$Builder$absolute,
							_List_fromArray(
								['map', sysId]),
							_List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'day', d)
								]))),
						$elm$html$Html$Attributes$class('calendar_control')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(d)
					]));
		};
		var hour2day = function (h) {
			return A2(
				$author$project$Components$DateTime$dateFormatFull,
				timeZone,
				$elm$time$Time$millisToPosix((h * 3600) * 1000));
		};
		var day_list = A3(
			$elm$core$List$foldl,
			F2(
				function (e, acc) {
					if (!acc.b) {
						return A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								hour2day(e),
								hour2day(e)),
							acc);
					} else {
						var _v1 = acc.a;
						var h = _v1.a;
						var d = _v1.b;
						var rest = acc.b;
						return _Utils_eq(
							d,
							hour2day(e)) ? acc : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								hour2day(e),
								hour2day(e)),
							acc);
					}
				}),
			_List_Nil,
			$elm$core$List$sort(hours));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('calendar_day_list')
				]),
			A2($elm$core$List$map, key, day_list));
	});
var $elm$virtual_dom$VirtualDom$lazy4 = _VirtualDom_lazy4;
var $elm$html$Html$Lazy$lazy4 = $elm$virtual_dom$VirtualDom$lazy4;
var $author$project$Components$UI$Calendar$viewCalendarWidget = F4(
	function (appState, sysId, mday, model) {
		var t = appState.aX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('calendar_opened')
				]),
			_List_fromArray(
				[
					A5($elm$html$Html$Lazy$lazy4, $author$project$Components$UI$Calendar$dayListWidget, appState.bC, model.dw, sysId, mday),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('calendar_control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('custom-btn'),
									$elm$html$Html$Attributes$href(
									A2(
										$elm$url$Url$Builder$absolute,
										_List_fromArray(
											['map', sysId]),
										_List_Nil))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('map.Скрыть трек'))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('custom-btn'),
									$elm$html$Html$Events$onClick($author$project$Components$UI$Calendar$DoClose)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									t('map.Закрыть'))
								]))
						]))
				]));
	});
var $author$project$Components$UI$Calendar$DoOpen = {$: 0};
var $author$project$Components$UI$Calendar$viewPanelWidget = F3(
	function (appState, mday, model) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-md btn-secondary btn-compact'),
					$elm$html$Html$Events$onClick($author$project$Components$UI$Calendar$DoOpen)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('moving')
						])),
					$elm$html$Html$text(' '),
					function () {
					if (mday.$ === 1) {
						return $elm$html$Html$text('Треки');
					} else {
						var day = mday.a;
						return $elm$html$Html$text(day);
					}
				}()
				]));
	});
var $author$project$Components$UI$Calendar$view = F4(
	function (appState, sysId, mday, model) {
		var _v0 = model.cQ;
		if (!_v0) {
			return A3($author$project$Components$UI$Calendar$viewPanelWidget, appState, mday, model);
		} else {
			return A4($author$project$Components$UI$Calendar$viewCalendarWidget, appState, sysId, mday, model);
		}
	});
var $author$project$Page$GlobalMap$viewSystem = F4(
	function (appState, model, system, mday) {
		var markers = function () {
			var _v1 = system.dk;
			if (_v1.$ === 1) {
				return _List_Nil;
			} else {
				var dynamic = _v1.a;
				var _v2 = _Utils_Tuple2(dynamic.dI, dynamic.dJ);
				if ((!_v2.a.$) && (!_v2.b.$)) {
					var latitude = _v2.a.a;
					var longitude = _v2.b.a;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Map$Types$Marker,
							A2($author$project$Page$Map$Types$LatLng, latitude, longitude),
							system.ej,
							system.dy)
						]);
				} else {
					return _List_Nil;
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container-map')
				]),
			_List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'leaflet-map',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$property,
							'center',
							$author$project$Page$Map$Types$encodeLatLng(model.M)),
							A2(
							$elm$html$Html$Attributes$property,
							'markers',
							A2($elm$json$Json$Encode$list, $author$project$Page$Map$Types$encodeMarker, markers)),
							A2(
							$elm$html$Html$Attributes$property,
							'title',
							$elm$json$Json$Encode$string(system.ej)),
							A2(
							$elm$html$Html$Attributes$property,
							'track',
							A2(
								$author$project$Page$GlobalMap$encodeTrack,
								system.b6,
								A2($elm$core$Maybe$withDefault, '', mday))),
							A2(
							$elm$html$Html$Events$on,
							'centerChanged',
							A2(
								$elm$json$Json$Decode$map,
								$author$project$Page$GlobalMap$CenterChanged,
								A2(
									$elm$json$Json$Decode$at,
									_List_fromArray(
										['target', 'center']),
									$author$project$Page$Map$Types$decodeLatLng)))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('map-bottom-control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Page$GlobalMap$CalendarMsg,
							A4($author$project$Components$UI$Calendar$view, appState, system.b6, mday, model.L))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('locations')
						]),
					_List_fromArray(
						[
							function () {
							if (markers.b && (!markers.b.b)) {
								var pos = markers.a.dY;
								return A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('locations-btn open-locations'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$GlobalMap$GetAddress(pos))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon-location')
												]),
											_List_Nil)
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('locations-wr'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('show', model.an)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('locations-notifications')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('image icon-location')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title')
														]),
													A4($author$project$Page$GlobalMap$sysPosition, appState, system.b6, system.dk, model.aA))
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('locations-notifications-close close-locations'),
													$elm$html$Html$Events$onClick($author$project$Page$GlobalMap$HideAddress)
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$Main$viewMap4Sys = F5(
	function (sysId, mlat, mlng, mday, model) {
		var _v0 = A2($elm$core$Dict$get, sysId, model.ef);
		if (_v0.$ === 1) {
			return $author$project$Main$noRightsMsg(model.bJ.aX);
		} else {
			var system = _v0.a;
			return A2(
				$elm$html$Html$map,
				A2($elm$core$Basics$composeR, $author$project$Types$GlobalMapMsg, $author$project$Types$OnPageMsg),
				A4($author$project$Page$GlobalMap$viewSystem, model.bJ, model.af, system, mday));
		}
	});
var $author$project$Main$viewPage = function (model) {
	var _v0 = model.ak;
	switch (_v0.$) {
		case 0:
			return A3($author$project$Main$view4SystemRec, '', model, $author$project$Main$homeRec);
		case 2:
			return A3($author$project$Main$view4SystemRec, '', model, $author$project$Main$loginRec);
		case 1:
			return A3($author$project$Main$view4SystemRec, '', model, $author$project$Main$authRec);
		case 8:
			var sysId = _v0.a;
			return A3($author$project$Main$view4SystemRec, sysId, model, $author$project$Main$systemInfoRec);
		case 9:
			var sysId = _v0.a;
			return A3($author$project$Main$view4SystemRec, sysId, model, $author$project$Main$systemConfigRec);
		case 10:
			var sysId = _v0.a;
			var _v1 = A2($elm$core$Dict$get, sysId, model.ef);
			if (_v1.$ === 1) {
				return $author$project$Main$noRightsMsg(model.bJ.aX);
			} else {
				var system = _v1.a;
				var logs = A2($elm$core$Dict$get, sysId, model.aK);
				return A2(
					$elm$html$Html$map,
					A2($elm$core$Basics$composeR, $author$project$Types$SystemLogsMsg, $author$project$Types$OnPageMsg),
					A4($author$project$Page$System$Logs$view, model.bJ, model.as, system, logs));
			}
		case 5:
			return $author$project$Page$GlobalMap$view;
		case 6:
			var sysId = _v0.a;
			var mlat = _v0.b;
			var mlng = _v0.c;
			var mday = _v0.d;
			return A5($author$project$Main$viewMap4Sys, sysId, mlat, mlng, mday, model);
		case 12:
			return A3($author$project$Main$view4SystemRec, '', model, $author$project$Main$linkSysRec);
		default:
			return $author$project$Page$NotFound$view;
	}
};
var $author$project$Main$view = function (model) {
	var connection = function () {
		var _v2 = model.aE;
		if (_v2 === 1) {
			return _List_Nil;
		} else {
			return $author$project$Components$UI$connectionWidwet;
		}
	}();
	var _v0 = model.bJ;
	var t = _v0.aX;
	var modal = function () {
		var _v1 = model.aH;
		if (_v1.$ === 1) {
			return _List_Nil;
		} else {
			var errorText = _v1.a;
			return _List_fromArray(
				[
					A3(
					$author$project$Components$UI$modal,
					t('Ошибка'),
					_List_fromArray(
						[
							$author$project$Components$UI$ModalText(
							t(errorText))
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Components$UI$cmdButton,
							t('map.Закрыть'),
							$author$project$Types$OnCloseModal)
						])),
					$author$project$Components$UI$modal_overlay($author$project$Types$OnCloseModal)
				]);
		}
	}();
	return {
		db: _List_fromArray(
			[
				A2(
				$author$project$Components$UI$layout,
				$author$project$Types$BodyClick,
				_Utils_ap(
					$author$project$Main$viewMenu(model),
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Main$viewPage(model)
							]),
						_Utils_ap(modal, connection))))
			]),
		ej: 'Fenix App'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{dC: $author$project$Main$init, dR: $author$project$Types$UrlChanged, dS: $author$project$Types$LinkClicked, ea: $author$project$Main$subscriptions, _: $author$project$Main$update, K: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (theme) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (scale) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (language) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (api_url) {
											return $elm$json$Json$Decode$succeed(
												{aB: api_url, cc: language, cH: scale, cV: theme, av: token});
										},
										A2($elm$json$Json$Decode$field, 'api_url', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'language', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'scale', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'theme', $elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$field,
			'token',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
					])))))(0)}});}(this));